
#mfConnectWrapper {
  #mfModalHeader #mfButtonHolder {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    * {
      float: none;
    }

    #mfConnectBack {
      // left-align this item
      margin-right: auto;
    }
  }
}

.mf-connect {
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #4a4a4a;
  font-size: 16px;
  line-height: 1.25;

  .mf-cta__primary {
    outline: 0;
    font-size: 16px;
    line-height: 1.25;
    border-radius: 96px;
    height: 48px;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    background: #235ba8;
    border: 1px solid #235ba8;
    color: #fff;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    -moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);

    &:disabled {
      background: #ddd;
      border: 1px solid #ddd;
      color: #fff;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .mf-icon {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 32px 32px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    margin-right: 8px;
    text-align: center;
    width: 32px;
  }

  .mf-icon__medfusion-twirl {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='32px' height='32px' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M21.8968422,12.1159623 C22.1621052,12.9685939 22.3136843,13.8591202 22.3263158,14.7622781 C22.3326315,15.6970149 22.1936843,16.6380675 21.9094737,17.5285939 C21.3347368,19.353857 20.1726316,20.9643833 18.6189473,22.0822781 C18.0568422,22.4864886 17.4694737,22.8085939 16.8568421,23.0675412 C20.6589474,20.1685939 21.4736843,14.673857 18.6568421,10.7706991 C16.2378948,7.41701491 11.9242105,6.35596228 8.24210527,7.81490965 C8.24210527,7.81490965 7.55368421,8.0801728 7.40842106,8.15596228 C7.41473684,8.1433307 6.10105263,4.94754123 6.10105263,4.94754123 C7.03578948,4.60648859 8.04631579,4.41701491 9.02526316,4.29069912 C11.3115789,3.99385701 13.7052632,4.17701491 15.8336843,5.10543596 C17.3052632,5.74964649 18.631579,6.8233307 19.6673685,8.04859386 C20.6589474,9.22964649 21.4357895,10.625436 21.8968422,12.1159623 Z M21.8021053,9.15999985 C24.7452632,10.9726314 26.7031579,14.2189472 26.6905263,17.9326314 C26.6905263,23.6168419 22.08,28.2273682 16.3957895,28.2273682 C10.7115789,28.2273682 6.10105263,23.6231577 6.10105263,17.9326314 C6.10105263,14.6231577 7.66105263,11.6863156 10.08,9.80421038 C8.58947368,11.3515788 7.66736842,13.4610524 7.66736842,15.785263 C7.66736842,20.5473682 11.5263158,24.4063156 16.2884211,24.4063156 C21.0505263,24.4063156 24.9094737,20.5473682 24.9094737,15.785263 C24.9094737,13.1199998 23.6968421,10.7389472 21.8021053,9.15999985 Z' id='path-1' fill='%234a4a4a' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    background-size: 32px 32px;
    height: 32px;
    width: 32px;

    &.mf-color__inverse {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='32px' height='32px' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M21.8968422,12.1159623 C22.1621052,12.9685939 22.3136843,13.8591202 22.3263158,14.7622781 C22.3326315,15.6970149 22.1936843,16.6380675 21.9094737,17.5285939 C21.3347368,19.353857 20.1726316,20.9643833 18.6189473,22.0822781 C18.0568422,22.4864886 17.4694737,22.8085939 16.8568421,23.0675412 C20.6589474,20.1685939 21.4736843,14.673857 18.6568421,10.7706991 C16.2378948,7.41701491 11.9242105,6.35596228 8.24210527,7.81490965 C8.24210527,7.81490965 7.55368421,8.0801728 7.40842106,8.15596228 C7.41473684,8.1433307 6.10105263,4.94754123 6.10105263,4.94754123 C7.03578948,4.60648859 8.04631579,4.41701491 9.02526316,4.29069912 C11.3115789,3.99385701 13.7052632,4.17701491 15.8336843,5.10543596 C17.3052632,5.74964649 18.631579,6.8233307 19.6673685,8.04859386 C20.6589474,9.22964649 21.4357895,10.625436 21.8968422,12.1159623 Z M21.8021053,9.15999985 C24.7452632,10.9726314 26.7031579,14.2189472 26.6905263,17.9326314 C26.6905263,23.6168419 22.08,28.2273682 16.3957895,28.2273682 C10.7115789,28.2273682 6.10105263,23.6231577 6.10105263,17.9326314 C6.10105263,14.6231577 7.66105263,11.6863156 10.08,9.80421038 C8.58947368,11.3515788 7.66736842,13.4610524 7.66736842,15.785263 C7.66736842,20.5473682 11.5263158,24.4063156 16.2884211,24.4063156 C21.0505263,24.4063156 24.9094737,20.5473682 24.9094737,15.785263 C24.9094737,13.1199998 23.6968421,10.7389472 21.8021053,9.15999985 Z' id='path-1' fill='%23ffffff' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  .mf-connect-btn {
    width: 275px;
    padding-left: 64px;
    text-align: left;
    position: relative;

    .mf-icon {
      position: absolute;
      top: 8px;
      left: 24px;
    }
  }
}
