:root {
  --font-secondary: 'Overpass';
}
.user-index {
  margin-top: 17px !important;
  .table td {
    font-weight: normal;
    width: 200px;
  }
  .btn {
    font-size: 12px;
  }
  .c-pointer {
    cursor: pointer;
  }
  .filter-btn {
    width: 120px;
    height: 30px !important;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 10px;
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(166, 166, 166);
    text-align: left;
    &::after {
      position: absolute;
      left: 85%;
      top: 14px;
    }
  }
  .display-name-container {
    float: left;
  }
  .sort-icn-container {
    display: block;
    position: relative;
    bottom: 4px;
    left: 5px;
    svg {
      display: block;
      cursor: pointer;
      margin-bottom: -9px;
      color: #aaa;
      &.active {
        color: black;
      }
    }
  }
  div.filter-by {
    min-width: 120px !important;
  }
  .responsive-graph-container {
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    .responsive-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 8px;
    }
  }
  .graph-container {
    margin-top: 12px;
    position: relative;
    right: 7px;
    color: #e1e1e1;
  }
  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
    letter-spacing: 0px;
  }
  .user-graph-container {
    margin-top: 20px;
    overflow: hidden;
    .container {
      border: 1px solid #ebebeb;
      border-radius: 5px;
      padding: 20px 10px 15px 25px;
      position: relative;
      h3 {
        letter-spacing: 0px;
        display: inline-block;
      }
      select {
        float: right;
        -webkit-appearance: none;
        padding: 5px 40px 5px 7px;
        margin-right: 12px;
        background: white;
        font-size: 12px;
        cursor: pointer;
      }
      i {
        position: absolute;
        font-size: 8px;
        top: 30px;
        right: 30px;
      }
      &.left {
        float: left;
        width: 49%;
        margin-right: 2%;
      }
      &.right {
        float: right;
        width: 49%;
        .graph-container {
          margin-top: 12px;
          position: relative;
          right: 7px;
          color: #e1e1e1;
        }
      }
    }
  }

  .provider-graph-container {
    clear: both;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 20px 10px 5px 25px;
    margin-top: 12px;
    position: relative;
    margin-top: 15px;
    .responsive-graph-container {
      padding-bottom: 26%;
    }
    .connections-info {
      margin-bottom: 20px;
      .connections {
        display: inline-block;
        .number {
          font-size: 35px;
          color: #64a0e3;
          font-weight: 200;
        }
        .this-week {
          font-size: 14px;
          span {
            color: #ccc;
          }
        }
      }
      .errors {
        display: inline-block;
        margin-left: 25px;
        font-size: 14px;
        .number {
          font-size: 35px;
          color: #d75452;
          font-weight: 200;
        }
      }
    }
  }

  .patients-by-location {
    border: 1px solid #ebebeb;
    border-radius: 5px;
    padding: 20px 10px 5px 25px;
    margin-top: 12px;
    .table-header {
      span {
        display: inline-block;
        margin-right: 85px;
      }
    }
    .location-table {
      margin-top: 23px;
    }
    .table-header {
      background: #f7f7f7;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      font-weight: bold;
      padding: 15px;
    }

    .table-body {
      list-style: none;
      padding: 0;
      li {
        border-bottom: 1px solid #ddd;
        padding: 13px 15px;
      }
      .table-row {
        .toggle-info {
          float: right;
          -webkit-appearance: none;
          border-radius: 5px;
          font-size: 12px;
          color: #918f90;
          width: 25px;
          height: 25px;
          outline: none;
          cursor: pointer;
          position: relative;
          i {
            position: absolute;
            top: 4px;
            right: 6.5px;
          }
        }
        .table-col {
          display: inline-block;
          margin-right: 15px;
          font-size: 14px;
          min-width: 100px;
          &.name {
            font-weight: bold;

            margin-right: 42px;
          }
        }
      }
      .table-row-options {
        margin-top: 25px;

        .state {
          display: inline-block;
          margin-right: 56px;
          .count {
            color: #64a0e3;
            font-weight: bold;
          }
          .name {
            margin-top: 7px;
            font-size: 12px;
            max-width: 160px;
          }
        }
      }
    }
  }

  .info-box {
    width: calc(100% / 3 - 15px);
    display: inline-block;
    padding: 15px 25px;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    margin-left: 20px;

    &:first-of-type {
      margin: 0;
    }

    .info-count {
      color: #4e92df;
      font-size: 35px;
      letter-spacing: 1.5px;
      font-weight: 200;
      margin-bottom: 0px;
    }
    .info-this-week {
      span {
        font-size: 13px;
        margin-right: 10px;
        color: #5fb760;
        &.error {
          color: #e17d7c;
        }
      }
    }
  }
  circle {
    cursor: pointer;
  }
  .recharts-default-tooltip {
    border-radius: 5px;
  }
  .recharts-tooltip-label {
    font-size: 14px;
    color: black;
  }
  .recharts-tooltip-item-list {
    font-size: 12px;
    text-transform: capitalize;
  }

  @media screen and (max-width: 655px) {
    .info-box {
      width: 100%;
      display: block;
      margin-left: 0;
      text-align: center;
      margin-top: 20px;
    }
    .user-graph-container {
      .container {
        float: none !important;
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }
  .success {
    color: #5cb85c;
  }
  .error {
    color: #d9534f;
    opacity: 0.5;
  }

  .disabled:not(.page-item) {
    cursor: default;
    position: relative;
    background: lightgray;
    pointer-events: all !important;
    color: darkgray !important;
    opacity: 1;
    span:not(.tooltiptext):not(.page-link) {
      border-bottom: 1px dotted black;
    }
  }
  .tooltip span,
  .tooltip-down-arrow span {
    position: absolute;
    display: inline-block;
    border-radius: 5px;
    visibility: visible;
    -webkit-box-shadow: #ccc 1px 5px 10px;
    box-shadow: #ccc 1px 5px 10px;
    z-index: -1;
    border: 1px solid #ccc;
    padding: 15px;
    right: 0px;
    bottom: 50px;
  }
  .tooltip:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
    bottom: 42px;
    right: 15px;
    z-index: 2;
    @media all and (max-width: 680px) {
      left: 10.5rem;
    }
  }

  .tooltip .tooltiptext,
  .tooltip-down-arrow .tooltiptext {
    visibility: hidden;
    width: 15rem;
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 5px 5px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    @media all and (max-width: 680px) {
      width: 15rem;
      left: 1.5rem;
    }
  }

  .tooltip-down-arrow .tooltiptext.switch-tooltiptext-position {
    top: 2.5rem;
    height: fit-content !important;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: 10vh;
  }
  .tooltip-down-arrow
    .tooltiptext.table-header-tooltip.switch-tooltiptext-position {
    top: 2rem;
  }
  span.tooltiptext.table-header-tooltip {
    padding: 10px 1px !important;
  }
  span.tooltip-down-arrow
    .tooltiptext.table-header-tooltip.switch-tooltiptext-position {
    padding: 10px 1px !important;
  }
  .tooltip-down-arrow:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
    bottom: 0.8rem;
    right: 20px;
    z-index: 2;
    @media all and (max-width: 680px) {
      left: 11.2rem;
    }
  }
  .table-header.tooltip {
    top: 0px !important;
  }
  .show-tooltip:hover {
    .tooltip span.tooltiptext {
      visibility: visible !important;
    }
    .tooltip-down-arrow {
      visibility: visible !important;
    }
  }
  .disabled:hover .tooltiptext,
  .show-tooltip:hover .tooltiptext,
  .show-tooltip:hover .tooltiptext {
    visibility: visible !important;
    opacity: 1;
  }
  .disabled:hover .tooltip,
  .show-tooltip:hover .tooltip,
  .show-tooltip:hover .tooltip,
  .show-tooltip:hover .tooltip-down-arrow {
    opacity: 1;
    background: transparent;
    color: black;
    padding: 5px 15px;
    border-radius: 5px;
    visibility: visible !important;
  }
  .table-header.tooltip .rotated-tooltip {
    top: 35px !important;
    bottom: unset !important;
    z-index: 0 !important;
    box-shadow: #ccc 1px -1px 10px !important;
  }
  .table-header.tooltip:after {
    top: 1.7rem;
    transform: rotate(180deg);
  }
  .tooltip.shift-right {
    .tooltiptext {
      left: 0px;
      bottom: 2rem;
    }
    .rotated-tooltip {
      top: 0px !important;
      bottom: unset !important;
      z-index: 0 !important;
      box-shadow: #ccc 1px -1px 10px !important;
    }
    &:after {
      bottom: 25px !important;
    }
    &.shift-bottom {
      &:after {
        bottom: 8px !important;
        transform: rotate(180deg);
      }
    }
  }
}

.actions-modal {
  &.disabled {
    color: #ccc !important;
    position: relative;
    &:focus {
      outline: none;
    }
  }
  .tooltip,
  .tooltip-down-arrow {
    position: absolute;
    display: inline-block;
    border-radius: 5px;
    visibility: visible;
    right: 70%;
    top: 40px;
  }

  .disabled:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .disabled:hover .tooltip {
    opacity: 1;
    background: transparent;
    color: black;
    padding: 5px 15px;
    border-radius: 5px;
    visibility: visible;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .modal-content {
        margin-top: 350px !important;
      }
    }
  }

  .modal-content {
    position: relative;
    top: calc(50% - 50px);
    top: -webkit-calc(50% - 50px);
    top: -mozkit-calc(50% - 50px);
    border: 0;
    transform: translateY(-50%);
    margin: 0px auto !important;
    padding: 28px 24px 24px 24px;
    min-height: 0;
    letter-spacing: 1px;
    height: auto;
    max-width: 588px;
    width: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    .disabled {
      cursor: default;
      position: relative;

      pointer-events: all !important;
      color: #ccc !important;
      span:not(.tooltiptext) {
        border-bottom: 1px dotted black;
      }
      &:focus {
        outline: none;
      }
    }
    h2 {
      font-size: 18px;
      font-weight: 200;
      margin-bottom: 25px;
      margin-top: 0px;
      text-shadow: 0 0 black;
      height: auto;
      color: rgba(0, 0, 0, 0.87);
      font-family: var(--font-secondary);
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
    }
    .user-name {
      font-weight: 400;
      margin-right: 5px;
      height: 24px;
      width: 262px;
      color: rgba(0, 0, 0, 0.87);
      font-family: var(--font-secondary);
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 24px;
    }
    .user-type {
      margin-left: -3px;
      letter-spacing: 0.5px;
    }
    @media all and (max-width: 320px) {
      top: 50%;
    }
  }
  .btn-default {
    border: 1px solid #ccc;
    margin-left: 10px;
    &.right {
      margin-left: calc(100% - 340px);
    }
  }
  .close-and-disable {
    .close-btn {
      margin: 0;
      outline: none;
      height: 38px;
      width: 74px;
      border: 1px solid #cccccc;
      border-radius: 4px;
      background-color: #ffffff;
      font-size: 14px;
      line-height: 20px;
      padding-top: 9px;
      padding-left: 14px;
    }
    .modal-primary-button {
      height: 38px;
      width: 105px;
      border: 1px solid #0275d8;
      border-radius: 4px;
      background-color: #0275d8;
      margin-right: 10px;
      span {
        height: 21px;
        width: 75px;
        color: #ffffff;
      }
    }
    .modal-cancel-button {
      height: 38px;
      width: 74px;
      border: 1px solid #cccccc;
      border-radius: 4px;
      background-color: #ffffff;
      span {
        height: 21px;
        width: 44px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    span {
      font-family: var(--font-secondary);
      font-size: 14px;
      line-height: 20px;
    }
    button {
      margin: 0;
      outline: none;
    }
    .disabled {
      cursor: default;
      position: relative;
      border-bottom: 1px dotted black;
      pointer-events: all !important;
      color: #ccc !important;
    }

    .btn-link {
      font-size: 14px;
      padding: 0;
      margin-top: 9px;
      margin-right: -5px;
      float: right;
      color: black;
      text-decoration: none;
      opacity: 1;
    }
  }

  .modal-form {
    h2 {
      margin-bottom: 1px;
      padding-right: 24px;
    }
  }
  .consent-modal-title {
    font-weight: 400;
    margin-right: 5px;
    height: 24px;
    width: 262px;
    color: rgba(0, 0, 0, 0.87);
    font-family: var(--font-secondary);
    font-size: 20px;
    letter-spacing: 0.5px;
    line-height: 24px;
    @media all and (max-width: 380px) {
      width: 80%;
    }
  }
  .consent-modal-form {
    & > div:first-child {
      height: 46px;
    }
    & > div:first-child {
      & > .custom-select > div {
        width: calc(100% + 42px);
      }
    }
    margin-top: 0px !important;
    letter-spacing: 0px;
    font-size: 18px;
    font-family: var(--font-secondary);
    margin-bottom: 0px;
    & > div {
      .form-control {
        letter-spacing: 0px;
        font-size: 18px;
        font-family: var(--font-secondary);
      }
    }
    .css-xp4uvy {
      padding-right: 10px;
    }
  }
  .close-consent {
    position: absolute;
    top: 28px;
    right: 24px;
  }
  .buttons-container {
    a {
      padding: 7px 14px 10px 15px;
    }
    button {
      padding: 8px 14px 10px 15px;
    }
    a,
    button {
      letter-spacing: 0;
      color: black;
      text-decoration: none;
      cursor: pointer;
      background: white;
      display: block;
      -webkit-appearance: none;
      border: 1px solid #ccc;
      width: 100%;
      text-align: left;
      border-radius: 5px;
      margin-bottom: 5px;
      font-size: 18px;
      &.disabled {
        color: #ccc !important;
      }
      @media all and (max-width: 380px) {
        min-height: 71px !important;
      }
    }
    svg {
      margin-top: 2px;
      float: right;
    }
    margin-top: 4.5%;
    margin-bottom: 20px;
  }
}

.user-modal {
  .modal-content {
    position: relative;
    top: calc(50% - 50px);
    transform: translateY(-50%);
    max-width: 590px !important;
    margin: 3px auto;
    padding: 28px 24px 24px 24px;
    letter-spacing: 1px;

    h2 {
      font-size: 19px;
      margin-bottom: 30px;
      margin-top: 0px;
      text-shadow: 0 0 black;
    }
    .buttons-container {
      font-size: 14px;
      margin-bottom: 25px;
      input {
        display: block;
        padding: 8px 15px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #ccc;
      }
    }
    .select-container {
      position: relative;
      margin-bottom: 20px;
      & > label {
        display: block;
      }
      svg {
        position: absolute;
        left: 100px;
        top: 38px;
      }
    }
    select {
      display: inline-block;
      -webkit-appearance: none;
      border: 1px solid #ccc;
      background: white;
      padding: 5px 65px 5px 15px;
    }
    .phi-container {
      input.checkbox {
        display: none;
      }
      label {
        display: inline-block;
        position: relative;
        padding-left: 25px;
        line-height: 20px;
        margin: 5px;
      }
      label:before {
        line-height: 20px;
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        background-color: #ffffff;
        border: 1px solid #666666;
      }
      input[type='checkbox']:checked + label:before {
        content: '\2713';
        color: #666666;
        text-align: center;
        line-height: 16px;
      }
      label:hover {
        cursor: pointer;
      }

      display: inline-block;
      font-size: 12px;
      margin-left: 26px;
      label {
        margin-left: 5px;
      }
    }
  }
  .create-or-cancel {
    .btn-primary {
      font-size: 14px;
    }
    .btn-default {
      border: 1px solid #ccc;
      margin-left: 10px;
    }
  }
}
.tooltip,
.tooltip-down-arrow {
  z-index: 100 !important;
}
.tooltip-down-arrow {
  visibility: hidden;
}
.tooltip span,
.tooltip-down-arrow span {
  position: absolute;
  display: inline-block;
  border-radius: 5px;
  visibility: visible;
  -webkit-box-shadow: #ccc 1px 5px 10px;
  box-shadow: #ccc 1px 5px 10px;
  z-index: -1;
  border: 1px solid #ccc;
  padding: 15px;
  right: 0px;
  bottom: 50px;
}

.tooltip-down-arrow span {
  -webkit-box-shadow: #ccc 2px -1px 20px 0px !important;
  box-shadow: #ccc 2px -1px 20px 0px !important;
}

.tooltip:after,
.tooltip-down-arrow:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  bottom: 42px;
  right: 15px;
  z-index: 2;
}

.tooltip .tooltiptext,
.tooltip-down-arrow .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-weight: normal !important;
}

.show-tooltip {
  position: relative;
}

.p-2.doctor.show-tooltip {
  height: 36px;
}

span.show-tooltip span.font-weight-normal {
  font-weight: normal;
}

.disabled:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.disabled:hover .tooltip {
  opacity: 1;
  background: transparent;
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  visibility: visible;
}
@media all and (max-width: 516px) {
  .filter {
    text-align: left !important;
    width: 250px;
  }
}
@media all and (max-width: 1266px) {
  .user-index {
    .toggle-info {
      margin-left: 0 !important;
      margin-top: 10px;
    }
  }
}
.user-index {
  .toggle-info {
    margin-left: 15px;
    -webkit-appearance: none;
    border-radius: 5px;
    font-size: 12px;
    color: #918f90;
    width: 25px;
    height: 25px;
    outline: none;
    cursor: pointer;
    background: white;
    position: relative;
    border: 1px solid #ccc;
    bottom: 5px;
    padding: 0;
    i {
      width: 11.5px;
      margin: 0 auto;
      float: none;
      display: block;
      position: relative;
      top: 2.5px;
    }
  }
}
.user-index {
  .table-responsive {
    overflow: hidden;
  }
}
.change-password-container {
  width: 100%;
}
