:root {
  --color-primary: #1b3663;
  --color-secondary: #f78920;
  --font-primary: 'Fjalla One';
  --font-secondary: 'Overpass';
}

.providers-container {
  margin: 17px;
}
.provider-list {
  min-height: 465px;
  .title {
    float: left;
    color: var(--color-secondary);
    font-family: var(--font-secondary);
    font-size: 34px;
    font-weight: 300;
    line-height: 40px;
  }

  a {
    color: #4e92df !important;
    font-family: var(--font-secondary);
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  .table {
    margin: 1rem 0 !important;
    border-bottom: 1px solid #ccc;
    &__header {
      th {
        font-weight: bold;
      }
    }
  }
  .link-color {
    color: #4e92df;
  }
  .add-provider {
    text-align: right;
    margin-bottom: 20px;
    &.filter {
      margin-top: 10px;
    }
    float: right;
    button {
      border: none;
      outline: none;
      color: white;
      background: transparent;
      position: relative;
      font-size: 14px;
      cursor: pointer;
      svg {
        position: absolute;
        left: -20px;
        top: 2px;
      }
    }
    i {
      color: #ffffff;
      position: relative;
      right: 35px;
    }
    .table {
      margin: 1rem 0 !important;
      border-bottom: 1px solid #ccc;
      &__header {
        th {
          font-weight: bold;
        }
      }
    }
    .link-color {
      color: #4e92df;
    }
  }
  .records-container-loading {
    margin: 115px auto;
    width: 150px;
    img {
      height: auto;
      min-width: 150px;
    }
  }
}
button.add-provider {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
}
@media all and (max-width: 420px) {
  .add-provider {
    display: block;
    clear: both;
    text-align: left !important;
    margin-left: 20px;
  }
}
.providers-table {
  .providers-td-0 {
    width: 16%;
  }
  .providers-td-1 {
    width: 20%;
  }
  .providers-td-2 {
    width: 20%;
  }
  .providers-td-4 {
    width: 15%;
  }
}
@media screen and (max-width: 680px) {
  .providers-table {
    td div {
      margin-left: 35%;
    }
  }
}
