:root {
  --font-secondary: 'Overpass';
}

.table {
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  &.table-records {
    width: 98%;
  }
  &__header {
    background-color: #f7f7f7;

    th {
      border-bottom: 0;
    }
  }
  thead th {
    border-bottom: 1px solid #dee2e6;
  }
  &-responsive {
    padding: 0 8px;
  }
  &--responsive {
    @media screen and (max-width: 680px) {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
      }

      td {
        display: block;
        text-align: right;
        width: 100% !important;
        &::before {
          content: attr(data-bs-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .ph-item {
      border: 0;
      padding: 0;
      margin: 0;
      div {
        padding: 0;
      }
      .ph-col-8 {
        padding: 0;
      }
      .ph-row {
        margin-bottom: 0;
        padding: 0;
        .ph-col-6 {
          margin-bottom: 0;
        }
      }
    }
  }
  &.no-records {
    border: 0 !important;
    td {
      position: absolute;
      left: 38%;
      border: none;
      color: #ccc;
      font-size: 20px;
    }
  }
}
.disabled {
  pointer-events: none;
  color: lightgray !important;
}
.active:not(.animated) {
  font-weight: bold;
}
.table-responsive .profile-table {
  font-family: var(--font-secondary);
  td,
  th {
    font-weight: 200;
    font-size: 16px;
    height: 39px;
    line-height: 24px;
  }
}
.authorizations-table {
  th {
    border-bottom-width: 2px !important;
    font-family: sans-serif !important;
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
  }
}
