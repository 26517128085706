:root {
  --font-secondary: 'Overpass';
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
a.list-link {
  text-decoration: none;
  color: black;
  height: 100%;
  position: absolute;
  right: 5%;
  top: calc(50% - 7px);
  i.list-arrow {
    bottom: 0;
  }
}
.register-details .table-body .table-row-options .state.range-values {
  margin-bottom: 20px;
  width: auto;
  margin-right: 169px;
  width: 160px;
  height: auto;
  word-break: break-all;
  display: table-cell;
}
.non-unit-container {
  clear: both;
}
.dropdown.show {
  button::after {
    transform: rotate(180deg);
  }
}
.rotated-tooltip {
  top: 30px !important;
  bottom: unset !important;
  z-index: 0 !important;
  box-shadow: #ccc 5px -3px 10px !important;
  &:after {
    left: 55px;
    bottom: 100%;
    top: unset !important;
    transform: rotate(180deg);
  }
}
.dropdown-menu.show {
  width: calc(100% - 0.5rem);
  min-width: unset !important;
}
.sort-by {
  margin-left: 24px;
  button.sort-by-btn {
    &:after {
      left: 135px;
    }
  }
  .dropdown-menu.show {
    width: 67%;
    min-width: unset !important;
    transform: translate3d(48px, 39px, 0px) !important;
  }
}
.d-flex p.description {
  width: auto !important;
  max-width: 740px;
  height: auto !important;
}
.modal-content:not(.help, .re-auth) {
  background-color: #fff;
  border-radius: 5px;
  min-height: 300px;
  margin: 0 auto;
  padding: 30px;
}
.responsive-header {
  .date {
    width: 9.5%;
    font-weight: bold;
  }
  .name {
    width: 33.8% !important;
  }
  .doctor {
    width: 15.8% !important;
  }
  .facility {
    width: 25% !important;
  }
}

.responsive-row-show {
  display: none;
}
.link-styles {
  color: #4a90e2;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.no-records {
  width: 100%;
  text-align: center;
  margin-top: 19px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
}
.no-records-cards {
  color: #ccc;
  font-size: 45px;
  margin: auto;
}
.header-filters {
  .dropdown-toggle:after {
    position: absolute;
    left: 125px;
    top: 22px;
  }
  .dropdown-toggle.filter-by-btn:after {
    position: absolute;
    left: auto;
    right: 15px;
  }
}
.dropdown-menu {
  min-width: 8rem !important;
}
.dropdown-item {
  height: 40px;
  font-size: 12px !important;
  padding: 0 10px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.records-grid {
  display: inline-block !important;
  & > div {
    float: left;
    margin: 0 8px;
  }
}
.no-records svg {
  width: 40px;
  margin: 20px auto;
}
.card-labs_and_test_results-title-container {
  max-width: 65%;
}
.record-title-labs_and_test_results {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mr-records {
  margin-right: auto !important;
}
.facilities-btn {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.input-container {
  position: relative;
  top: -5px;
}
.back-btn {
  position: relative;
  span {
    position: relative;
    top: -5px;
    height: 21px;
    width: 32px;
    color: #ffffff;
    font-family: var(--font-secondary);
    font-size: 14px;
    line-height: 20px;
  }
  svg {
    position: relative;
    top: 2px;
  }
}
.pl-14 {
  padding-left: 18px;
}
.record-filters {
  width: 70%;
  margin-top: 0px;
  padding: 10px 0px;
  margin-right: 7px;
  float: right;
  text-align: right;
  .mr-20 {
    margin-right: 24px;
  }
  .dropdown {
    position: relative;
  }
  i.search-icon {
    font-size: 12px;
    position: absolute;
    left: 4.5%;
    top: -4px;
  }
  .select-icon {
    font-size: 5px;
    position: absolute;
    margin-left: 26%;
    top: 2px;
  }
}
.record-widget-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contacts {
  margin-right: 17px;
}
.listview-icon {
  position: relative;
  left: 8px;
}
.share-with {
  position: relative;
  left: 4px;
  top: 1px;
}
.record-header {
  font-family: var(--font-secondary);
  font-size: 34px;
  font-weight: 300 !important;
  line-height: 40px;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 3px 3px 0 0;
}

.record-title {
  display: inline-block;
  width: auto;
  margin-top: 10px;
  min-width: 220px;
}
.record-filters {
  display: inline-block;
  & > div {
    display: inline-block;
  }
}
.backdrop {
  position: fixed;
  z-index: 1030;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
}

.manage-records {
  .actions-container {
    &.filter {
      width: auto;
    }
    .back {
      float: left;
    }

    .btn {
      float: right;
      box-shadow: none;

      &.share {
        font-size: 13px;
        color: #5194dc;
        font-weight: bold;
        position: relative;

        svg {
          position: absolute;
          right: 110px;
          width: 18px;
          top: 2px;
        }
      }
    }
  }

  .title {
    clear: both;
  }
}
.info-icon {
  position: relative;
  z-index: 2 !important;
}
.register-details {
  .location-table {
    margin-top: 23px;
  }

  .download-container {
    float: right;
    margin-right: 20px;
    color: #4e92df;
    font-size: 14px;
    font-weight: bold;

    label {
      cursor: pointer;
    }

    i {
      margin-right: 5px;
      font-size: 17px;
      top: 3px;
      color: #a6c9ef;
      position: relative;
    }
  }

  .add-notes {
    cursor: pointer;
    color: #4e92df;
    font-size: 14px;
    font-weight: bold;
    margin-top: 14px;

    svg {
      margin-right: 9px;
      width: 15px;
      left: 3px;
      color: #a6c9ef;
      top: 5px;
      position: relative;
    }
  }

  .toggle-info {
    margin-left: 15px;
    -webkit-appearance: none;
    border-radius: 5px;
    font-size: 12px;
    color: #918f90;
    width: 25px;
    height: 25px;
    outline: none;
    cursor: pointer;
    background: white;
    position: relative;
    border: 1px solid #ccc;
    bottom: 5px;
    padding: 0;
    i {
      width: 11.5px;
      margin: 0 auto;
      float: none;
      display: block;
      position: relative;
      top: 2.5px;
    }
  }
  .medication-left {
    width: 35%;
    min-width: 350px;
    display: inline-block;
  }
  .table-body {
    list-style: none;
    padding: 0;

    li {
      border-bottom: 1px solid #ddd;
      padding: 12px 25px;
      font-family: var(--font-secondary);
      font-size: 14px;
      clear: both;
    }

    .table-row {
      .table-col {
        display: inline-block;
        margin-right: 21px;
        font-size: 14px;
        min-width: 100px;
        word-wrap: break-word;

        &.date {
          font-weight: bold;
          font-size: 14px;
          width: 10%;
          min-width: unset;
        }

        &.name {
          width: 34% !important;
        }

        &.doctor {
          width: 16% !important;
        }
        &.facility {
          width: 25% !important;
        }
      }
    }

    .table-row-options {
      margin-top: 25px;

      .state {
        display: block;
        margin-right: 15%;
        position: relative;
        z-index: 1;
        width: 100%;

        .count {
          color: #64a0e3;
          font-weight: bold;
          position: relative;
          span {
            border-bottom: dotted 1px #ccc;
          }
          width: auto;
          text-align: left;

          &.out {
            color: #d75452;
          }
        }

        .name {
          margin-top: 7px;
          color: #959595;
          font-size: 12px;
          text-transform: capitalize;
          max-width: 160px;
        }

        .state-tooltip {
          display: none;
          position: absolute;
          width: fit-content;
          min-width: 125px;
          bottom: 50px;
          left: -25px;
          background: white;
          border: 1px solid #ccc;
          padding: 15px;
          color: black;
          font-size: 14px;
          border-radius: 5px;
          -webkit-box-shadow: #ccc 1px 5px 10px;
          box-shadow: #ccc 1px 5px 10px;
          z-index: -1;
        }
      }
    }

    .no-records {
      text-align: center;
      font-size: 25px;
      color: #ccc;
    }
  }
}

.edit-modal {
  .modal-content {
    border: none;
    padding: 30px 23px 23px 23px;
    max-width: 588px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.2);
    h2 {
      margin-bottom: 24px;
      font-family: var(--font-secondary);
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
    }

    .btn-primary {
      font-weight: 300;
      font-size: 14px;
      padding: 8px 15px;
      font-family: var(--font-secondary);
      letter-spacing: 0.5px;

      width: 68px;
      border: 1px solid var(--color-secondary-light);
      border-radius: 4px;
      background-color: var(--color-secondary);
      color: #ffffff;

      line-height: 20px;
      &:active {
        border: 1px solid var(--color-secondary-light);
        background-color: var(--color-secondary-light);
      }
      &:focus:active {
        box-shadow: 0 0 0 0.2rem var(--color-secondary-light);
      }
    }

    .btn-default {
      font-size: 14px;
      padding: 9px 16px;
      margin-left: 7px;
      color: #212121;
      height: 38px;
      margin-top: 2px;
      width: 75px;
      line-height: 2px;
    }
    .select-records-container {
      color: #373a3c;
      font-family: var(--font-secondary);
      font-size: 16px;
      line-height: 24px;
    }

    .select-records {
      margin-bottom: 7px;
    }

    .checkbox-container {
      padding: 13px 50px 8px 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 24px;
      padding-bottom: 0;
      ul {
        margin-left: 8px;
      }

      input.checkbox {
        display: none;
      }

      label {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        font-size: 16px;
        line-height: 20px;
        margin: 5px;
      }

      input + label:before {
        line-height: 20px;
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 2px;
        background-color: #ffffff;
        border: 1px solid #ccc;
        border-radius: 2px;
      }
      input[type='checkbox']:checked + label:before {
        content: '\2713';
        color: #666666;
        text-align: center;
        line-height: 12px;
      }

      label:hover {
        cursor: pointer;
      }
    }

    .error-msg {
      font-family: var(--font-secondary);
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 26px;
      background: #f2dede;
      color: #a94442;
      padding: 15px;
      border-radius: 5px;
      border: 1px solid #eed5d5;
    }

    .email-container {
      label {
        color: #373a3c;
        font-family: var(--font-secondary);
        font-size: 16px;
        line-height: 24px;
      }
      .email-input {
        border: 1px solid #cccccc;
        border-radius: 4px;
        background-color: #ffffff;
        display: block;
        outline: none;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px 16px;
        font-size: 16px;
        font-family: var(--font-secondary);
        line-height: 24px;
        margin-bottom: 28px;
        &::placeholder {
          height: 25px;
          width: 81px;
          color: #999999;
          font-family: var(--font-secondary);
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  ul.checkbox {
    margin: 0;
    padding: 0;
    margin-left: 20px;
    list-style: none;
  }

  ul.checkbox li input {
    margin-right: 0.25em;
  }

  ul.checkbox li {
    border: 1px transparent solid;
    display: inline-block;
    width: 162px;
    margin-bottom: 8px;
    line-height: 31px;
  }
  ul.checkbox li:nth-child(odd) {
    label:before {
      left: 0px;
    }
  }
  ul.checkbox li:nth-child(even) {
    label:before {
      left: -2px;
    }
  }
  .btn-default {
    border: 1px solid #ccc;
    margin-left: 10px;

    &.right {
      margin-left: calc(100% - 340px);
      width: auto;
    }
  }
}

.range-container {
  padding: 9px 13px;
  font-size: 14px;
  margin-bottom: 17px;

  * {
    display: inline-block;
  }

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: black;
    margin-right: 8px;

    &.blue {
      background-color: #64a0e3;
    }

    &.red {
      background-color: #d75452;
      margin-left: 28px;
    }
  }
}

.table-row-options {
  &.hospitalization {
    .description {
      p {
        padding: 5px 0px;
      }

      h2 {
        font-size: 16px;
        margin-bottom: 0;
        margin-top: 30px;
      }
    }
  }

  .description {
    p {
      padding: 15px 5px;
    }

    clear: both;
  }

  .prescription-details {
    margin-top: 37px;
    .left {
      float: left;
      width: 50%;
    }

    .top,
    .bottom {
      display: table;
      border-collapse: separate;
      border-spacing: 5px;
    }

    .bottom {
      margin-top: 12px;
    }

    .right {
      width: auto;
      border-left: 1px solid #ccc;
      padding-left: 24px;
      padding-bottom: 35px;
      height: 113px;
      display: inline-block;
      margin-bottom: 30px;
    }
    .medication-left {
      .info-tab {
        width: 137px;
      }
    }
    .info-tab {
      display: table-cell;
      padding-right: 40px;
      white-space: pre-line;
      width: auto;
      span {
        text-transform: capitalize;
      }
      label {
        color: #909090;
        display: block;
        font-size: 12px;
        font-weight: 200;
      }
    }
  }
  .normal-case span {
    text-transform: none !important;
  }
  .notes-container {
    .edit-note-icons {
      float: right;

      a {
        color: #918f90;
        padding: 6px 3px;
        cursor: pointer;

        svg {
          width: 15px;
        }
      }
    }

    border: 1px solid #ccc;
    padding: 8px 15px;
    padding-bottom: 15px;
    border-radius: 5px;

    label {
      font-weight: bold;
      margin-top: 10px;
      color: #949494;
      font-size: 14px;
    }

    p {
      color: black;
      margin: 0;
      font-size: 12px;
    }
  }
}

.imaging-list {
  padding: 0;
  margin-left: 9px;
  margin-top: 8px;

  .toggle-info {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  li {
    position: relative;
    list-style: none;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    clear: both;
    padding-bottom: 25px;
    margin-bottom: 18px;

    .image-container {
      width: 140px;
      height: 140px;
      position: relative;
      float: left;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info-container {
      display: inline-block;
      margin-left: 13px;

      .date {
        font-size: 12px;
      }

      h2 {
        font-size: 20px;
        margin-top: 13px;
      }

      label {
        display: block;
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
  }
}

.notes-modal {
  min-width: 600px;
  .modal-content {
    max-width: 600px;
  }
  label {
    display: block;
  }

  textarea {
    width: 530px;
    height: 140px;
    margin-bottom: 20px !important;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px;
    font-size: 13px;
    outline: none;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 25px;

    span {
      font-weight: 200;
    }
  }

  .btn-default {
    margin-left: 15px;
    border: 1px solid #ccc;
  }
}

.c-pointer {
  cursor: pointer;
}

.lighter {
  font-weight: 400 !important;
}
.list-arrow {
  position: relative;
  left: calc(100% - 48px);
  bottom: 24px;
  cursor: pointer;
  width: 15px;
}
@media all and (max-width: 1134px) {
  .sort-by {
    margin-left: 7px;
  }
}
@media (max-width: 515px) {
  .dropdown-menu.show {
    width: 75%;
  }
}
@media (max-width: 540px) {
  .record-filters {
    .input-container {
      input {
        width: 148px !important;
      }
    }
  }
  .sort-by {
    margin-left: 0;
    button {
      width: 100px !important;
    }
  }
  .sort-by button.sort-by-btn:after {
    left: 130px !important;
  }
}
@media all and (max-width: 788px) {
  .record-filters {
    width: 70%;
    text-align: left;
    float: right;
    margin-left: 20px;
  }
}
@media all and (max-width: 700px) {
  .records-title .sort-by button.sort-by-btn:after {
    left: unset !important;
  }
  .records-title .sort-by .dropdown-menu.show {
    top: 0 !important;
    width: 66% !important;
    transform: translate3d(47px, 40px, 0px) !important;
  }
}

@media all and (max-width: 939px) and (min-width: 763px) {
  .dashboard-container {
    .container {
      max-width: none;
    }

    .justify-content-between {
      position: relative;

      a {
        float: right;
      }

      .align-items-start {
        width: 45% !important;
        margin-bottom: 2%;
        margin-right: 2%;
        float: left;

        .p-2.w-100.body {
          height: 208px !important;
        }

        &.responsive {
          position: absolute;
          right: 6%;
          bottom: 280px;
        }
      }
    }
  }
}
@media all and (max-width: 810px) and (min-width: 763px) {
  .dashboard-container {
    .justify-content-between {
      .record-title-inmunizations {
        font-size: 14px !important;
      }
      .select-icon {
        font-size: 5px;
        position: absolute;
        margin-left: -5%;
        top: 8px;
      }
    }
  }
}
@media all and (max-width: 940px) {
  .prescription-details {
    .right {
      display: block !important;
      float: none !important;
      position: unset !important;
      border: 0 !important;
      padding-left: 5px !important;
    }
  }
}
@media all and (max-width: 1040px) {
  .record-filters {
    .mr-20 {
      margin-right: 0 !important;
    }
  }
}
@media all and (max-width: 1280px) and (min-width: 940px) {
  .records-header {
    height: auto !important;
    display: block !important;
  }
  .card-labs_and_test_results-title-container {
    max-width: 100%;
  }
  .viewAll {
    left: 33%;
  }
}
@media all and (max-width: 885px) and (min-width: 768px) {
  .records-header {
    height: auto !important;
    display: block !important;
  }
  .card-labs_and_test_results-title-container {
    max-width: 100%;
  }
  .viewAll {
    float: none !important;
    left: 33%;
  }
}
@media all and (max-width: 430px) {
  .records-header {
    height: auto !important;
    display: block !important;
  }
  .card-labs_and_test_results-title-container {
    max-width: 100%;
  }
  .viewAll {
    float: none !important;
    left: 33%;
  }
  .no-records-cards {
    color: #ccc;
    font-size: 20px;
    margin: auto;
  }
}
@media all and (max-width: 1211px) {
  .record-title {
    float: left;
  }
  .record-header.justify-content-between.align-items-center {
    display: block !important;
    height: auto !important;
    & > .record-filters {
      width: 100% !important;
    }
  }
  .record-filters .doctors-filter-list {
    left: 60% !important;
    top: 45px;
  }
  .record-filters .facilities-filter-list {
    left: 32% !important;
    top: 45px;
  }
  .record-filters .select-icon {
    font-size: 5px;
    position: absolute;
    margin-left: -5%;
    top: 10px;
  }
  .record-filters {
    li {
      width: 148px;
    }
  }
  .mr-records {
    display: block;
    width: 100%;
    height: 60px;
    .record-header {
      float: none;
    }
  }
  .justify-content-between:not(.sticky-top) {
    position: relative;
  }

  .record-header {
    display: block !important;
  }

  .register-details .download-container label {
    margin-top: 10px;
  }

  .responsive-row-hide {
    display: none;
  }

  .responsive-row-show {
    position: relative;
    display: block;

    span {
      font-weight: bold;
    }
  }

  .toggle-info {
    position: absolute;
    float: none;
    top: 0;
    right: 0;
  }

  .table-col-responsive {
    &.buttons {
      * {
        float: none;
        display: inline-block;
      }
    }
  }

  .responsive-header {
    display: none !important;
  }
}
@media all and (max-width: 870px) {
  .records-title {
    display: block !important;
    .mx-3 {
      display: none;
    }
  }
}
@media all and (max-width: 475px) {
  .actions-container {
    display: block !important;
  }
  .share-record {
    margin-left: 5px !important;
    margin-top: 10px;
  }
  .list-arrow {
    position: relative;
    left: 110%;
    bottom: 24px;
    cursor: pointer;
    width: 15px;
  }
}
.dashboard-container {
  p {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    width: 100% !important;
    &.info-container {
      max-height: 115px;
      overflow: auto;
    }
  }
  .align-items-center {
    .mb-0.w-80 {
      width: 100px;
    }
    .p-2.w-100 {
      width: calc(100% - 35px) !important;
      position: relative;
    }
  }
  .list-arrow {
    position: relative;
    left: 100%;
    bottom: 24px;
    cursor: pointer;
    width: 15px;
    font-size: 12px;
  }
}
@media all and (max-width: 768px) {
  .table-row-options .prescription-details .info-tab {
    display: inline-block;
    margin: 5px 0;
  }
}

@media all and (max-width: 762px) {
  .dashboard-container {
    .container {
      max-width: none;
    }

    .justify-content-between {
      position: relative;

      &.d-flex.flex-row.p-1 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .mr-records {
        display: block;
        width: 100%;
      }

      a {
        float: right;
      }

      .align-items-start {
        width: 80% !important;
        margin-left: 8%;
        margin-bottom: 15px;

        &.responsive {
          position: unset;
        }
      }
    }
  }
}

.imaging-placeholder {
  .ph-picture {
    width: 70px;
    height: 70px;
  }

  .picture-desc {
    width: 100%;
    position: absolute;
    left: 81px;
    top: 100px;
  }
}
.listview-container {
  display: table !important;
}
.listview-header {
  display: table-cell !important;
  position: relative;
  i {
    position: absolute;
    top: 35%;
    left: 15px;
  }
}
.unit-values-container {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
  .labs-details-row {
    display: table-row;
    width: 100%;
    margin-bottom: 12px;
  }
}
@media all and (max-width: 520px) {
  .unit-values-container {
    display: block;
    width: 100%;
    .labs-details-row {
      display: block;
      width: 100%;
    }
  }
  .register-details .table-body .table-row-options .state.range-values {
    display: block;
  }
  .loading-logo {
    min-width: 150px;
  }
}
.ph-item.ph-cards.noanimate {
  &::before {
    animation: none;
  }
}
.shr-inner-container {
  .loading-logo {
    min-width: 150px;
  }
}
@media all and (max-width: 340px) {
  .header-filters {
    .dropdown-toggle.filter-by-btn:after {
      position: absolute;
      top: auto;
      right: 60px;
    }
  }
}
