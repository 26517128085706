.patient-list{
    .title{
        float:left;
        color:#F0AD4E;
    }
    .remove{
        text-align:right;
        .removed{
          margin-right:10px;
          color:#999999;
        }
    }
    a{
        color:#4e92df !important;
        font-weight: bold;
        cursor:pointer;
        text-decoration: none;
    }
    .table{
        margin:1rem 0 !important;
        border-bottom: 1px solid #ccc;
        &__header{
            th{
                font-weight: bold;
            }
        }
    }
    .filter{
        text-align: justify;
        float: right;
        .search-icon{
            position: relative;
            right: 220px;
            top: 2px;
            font-size: 12px;
        }
        &__input{
            width: 230px;
            border-radius:5px;
            box-shadow: none;
            border:1px solid #ccc;
            font-size: 12px;
            padding:6px 6px 6px 30px;
        }
        &__select{
            display:inline-block;
            font-size: 12px;
            position: relative;
            margin: 10px 0px 10px 0;
            @media screen and (max-width: 567px) {
              button {
                margin-right: 0 !important;
              }
            }
            select{
                -webkit-appearance: none;
                padding:5px 40px 5px 7px;
                margin-left: 10px;
                background:white;
            }
            i{
                position: absolute;
                font-size: 8px;
                top: 9px;
                right: 15px;
            }
        }
        &__invitations{
            font-size: 13px;
            word-wrap: break-word;
            white-space: nowrap;
        }
    }
}


.invitations-list{
    .title{
        color:#F0AD4E;
    }
    .bold{
        font-weight: bold;
    }
    .ta-right{
        text-align: right;
    }
    a{
        color:#4e92df !important;
        cursor:pointer;
        text-decoration: none;
        &.decline{
            color:black !important;
            margin-right: 10px;
        }
    }
    .decision{
        color: #999999 !important;
        margin-right: 10px;
    }
    .link-color{
        color:#4e92df;
    }
    .back{
        font-weight: normal;
        font-size: 14px;
        display: block;
        margin-bottom: 20px;
        svg{
            width: 20px;
            position: relative;
            top: 8px;
        }
    }
    .table{
        margin:.5rem 0 !important;
        border-bottom: 1px solid #ccc;
        &__header{
            th{
                font-weight: bold;
            }
        }
        .provider{
            width:410px;
        }
    }
    .user-info{
        width:100%;
        margin-bottom:15px;
        font-weight: lighter;
        &__header{
            font-size: 13px;
            color: #aaa;
        }
        &__body{
            font-size: 14px;
        }
    }
    .record-grid{
        a{
            color:white !important;
        }
    }
}

.patients{
    &.ph-item{
        border:0;
        padding:0;
        .ph-col-12{
            padding:0
        }
    }
}
