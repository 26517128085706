:root {
  --color-primary: #1b3663;
  --color-secondary: #f78920;
}

body {
  margin-bottom: 100px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-logo.spin {
  animation: spin 1s infinite linear;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */
.prn-container {
  margin-top: -20px;
  color: white;
  font-weight: bold;
}

.prn-container a {
  max-width: 200px;
}

.prn-container figcaption {
  color: white;
  font-weight: bold;
  max-width: 150px;
}

.prn-container .row h3 {
  font-weight: 300;
}

.prn-container .row p {
  /*font-weight: 200;*/
}

.prn-image {
  width: 150px;
  height: 150px;
}

.btn-monarc {
  background-color: rgb(100, 165, 207);
  border-color: rgb(100, 165, 207);
  color: white;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.bg-monarc-orange {
  background-color: var(--color-secondary) !important;
}

.text-monarc-orange {
  color: var(--color-secondary) !important;
}

.text-monarc-green {
  color: rgb(138, 197, 125) !important;
}

.text-monarc-purple {
  color: #a45e9e !important;
}

.btn-monarc-purple {
  background-color: white;
  border-color: #a45e9e;
  color: #a45e9e;
}

.btn-monarc-purple:hover,
.btn-monarc-purple:focus {
  border-color: white;
  background-color: #a45e9e;
  color: white;
}

.btn-monarc-purple:active,
.btn-monarc-purple:visited,
.btn-monarc-purple:active:focus,
.btn-monarc-purple:active:hover {
  border-color: #a45e9e;
  background-color: #a45e9e;
  color: white;
}

.btn-monarc-orange {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: white;
}

.btn-monarc-orange:hover,
.btn-monarc-orange:focus {
  border-color: #ff8919;
  background-color: #ff8919;
  color: white;
}

.btn-monarc-orange:active,
.btn-monarc-orange:visited,
.btn-monarc-orange:active:focus,
.btn-monarc-orange:active:hover {
  border-color: #ff8919;
  background-color: #ff8919;
  color: white;
}

.borderless td,
.borderless th {
  border: none;
  padding: none;
}

.header-style {
  font-weight: 200;
}

/* Landing Page */
.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.lead {
  font-size: 24px;
}

.lead a {
  font-weight: bold;
  color: white;
}

.jumbotron-join-button-container button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.landing-page-process-container {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.landing-page-paragraph h1 {
  font-weight: 800;
  font-size: 25px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-paragraph h2 {
  font-weight: 800;
  font-size: 20px;
  text-align: left;
  color: var(--color-primary);
}

.landing-page-paragraph h3 {
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  color: var(--color-primary);
}

.landing-page-paragraph hr {
  border-color: white;
}

.landing-page-paragraph ul {
  margin-top: 25px;
  margin-bottom: 25px;
}

.landing-page-paragraph li {
  margin-top: 25px;
  margin-bottom: 25px;
}

.landing-page-paragraph p {
  margin: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-process-container {
  background-color: rgb(100, 165, 207);
  color: white;
  padding-top: 40px;
  padding-bottom: 40px;
}

.landing-page-process-container h2 {
  font-weight: 800;
  color: rgb(54, 74, 79);
  font-size: 25px;
}

.process-list {
  margin-top: 25px;
  margin-bottom: 25px;
}

.process-item .media-body h5 {
  font-weight: 500;
  color: rgb(54, 74, 79);
  font-size: 16px;
  margin-bottom: 0;
}

.process-item .media-body p {
  font-weight: 200;
  color: rgb(54, 74, 79);
  font-size: 15px;
}

.landing-page-support {
  margin-top: 50px;
  margin-bottom: 50px;
}

.landing-page-support h2 {
  font-weight: 800;
  font-size: 25px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-support h3 {
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-support p {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  color: var(--color-primary);
  margin-bottom: 50px;
}

.landing-page-support-item-icon {
  font-size: 50px;
  color: var(--color-primary);
}

/********/

/*** Registration ***/
.registration-container {
  background-color: rgb(100, 165, 207);
  position: absolute;
  z-index: 10;
  /*  height: 100%;
*/
}

#register-form {
  position: relative;
}

#register-form fieldset {
  background: white;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;
  margin-bottom: 25px;

  /*stacking fieldsets above each other*/
  position: relative;
}

.registration-instructions {
  margin-bottom: 25px;
}

.registration-instructions h1,
.registration-instructions h2,
.registration-instructions h3 {
  font-weight: 200;
  margin-bottom: 25px;
  text-align: center;
}

/*#register-form fieldset:not(:first-of-type) {
    display: none;
}*/

#register-form fieldset {
  display: none;
}

#register-form fieldset.active {
  display: block;
}

/*#register-form button {
    width: 100px;
    font-weight: bold;
    color: rgb(114,164,203);
    border-color: rgb(114,164,203);
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}

#register-form button:hover, #register-form button:focus {
    color: white;
}*/

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 23%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 11px;
  z-index: -1;
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/

#progressbar li.active:before,
#progressbar li.active:after {
  background: var(--color-primary);
  color: white;
}

/********/

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}

.bd-callout-info {
  border-left-color: rgb(100, 165, 207);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-secondary);
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link a {
  color: var(--color-secondary);
}

a {
  color: rgb(100, 165, 207);
}

.btn-mbn-blue {
  background-color: rgb(100, 165, 207);
  color: white;
}

.signature-modal-body img {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.signature-modal-body canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.signature-modal-body {
  height: 250px;
}

/* Dashboard CSS */
.sidebar {
  position: fixed;
  padding: 0;
}

.sidebar-sticky {
  background-color: white;
  color: black;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-top: 65px;
}

.menu .sidebar-header {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.75);
}

#sidenav .navbar-light {
  position: fixed;
  z-index: 2000;
  width: 100%;
  background-color: rgb(255, 255, 255) !important;
}

.navbar-toggler {
  background-color: var(--color-secondary) !important;
  float: right;
  margin-left: 15px;
}

.navbar-nav {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.nav-item-container:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.nav-item-container:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.nav-item-container {
  background-color: rgb(255, 255, 255);
  color: var(--color-primary);
}

.nav-item-container.active {
  background-color: var(--color-primary);
}

.menu .nav-item {
  margin: 10px;
  margin-left: 25px;
  font-weight: 800;
  font-size: 14px;
  color: var(--color-primary);
  padding-top: 15px;
  padding-bottom: 15px;
}

.menu a .nav-item {
  color: inherit;
}

.menu .active {
  color: rgb(255, 255, 255) !important;
}

.nav-item-container:hover,
.nav-item-container:hover .nav-item {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
  color: var(--color-secondary) !important;
}

.nav-item-container.active:hover,
.nav-item-container.active:hover .nav-item {
  background-color: var(--color-primary);
  color: var(--color-secondary) !important;
}

.menu a .nav-item:hover {
  color: inherit;
  text-decoration: none !important;
}

.menu a:hover {
  color: rgb(255, 255, 255);
  text-decoration: none !important;
}

.menu .nav-item i {
  color: inherit;
  text-decoration: inherit;
  /* float: right; */
  margin-right: 25px;
}

.menu .nav-link.active {
  color: #007bff;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.menu .dropdown-divider {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dashboard-container {
  background-color: rgb(250, 250, 249);
  overflow: auto;
  padding: 0;
}

.dashboard-item-banner {
  margin-bottom: 20px;
  height: 100%;
  width: 110%;
  padding: 1rem;
  background-color: var(--color-primary);
}

.dashboard-item {
  overflow: hidden;
}

.dashboard-item h2 {
  font-weight: 200;
  padding-right: 6px;
}

.dashboard-item th {
  font-weight: 200;
}

.dashboard-item td {
  font-weight: 200;
  margin-bottom: 50px;
}

.dashboard-item-content-container h1 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container h2 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container h3 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container p {
  font-size: 20px;
  font-weight: 200;
}

.dashboard-item-content-container p img {
  max-width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  border-radius: 10px;
}

.dashboard-item-content-container {
  font-size: 20px;
  font-weight: 200;
}

.inbox-card-container-header {
  background-color: var(--color-primary);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
}

.inbox-card-container-header h2 {
  color: rgb(255, 255, 255);
}

.inbox-items-container {
  max-height: 450px;
  overflow-y: auto;
}

/*.inbox-card-item {
  border: 0;
  border-width: 0;
  border-color: rgba(0,0,0,0);
  border-radius: 10px;
  background-color: rgb(255,255,255);
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
  overflow: hidden;
  border-left-width: 5px;
  border-left-color: var(--color-secondary);
}*/

.inbox-card-item-list {
  cursor: pointer;
  /*border: 0;*/
  border-width: 1;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-left-width: 5px;
  border-left-color: var(--color-secondary);
  border-left-style: solid;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.inbox-card-item-list-read {
  border-left-color: #bdd5ea;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0);
}

.inbox-card-item-list:hover {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
}

.inbox-button {
  font-size: 20px;
  margin-top: -10px;
}

.inbox-button:hover {
  font-size: 20px;
  margin-top: -10px;
  color: red;
}

.inbox-list-title {
  margin: 2.5px;
  color: rgba(0, 0, 0, 0.5);
}

.inbox-item-title {
  font-weight: 800;
}

.inbox-header-badge {
  font-size: 12px;
  vertical-align: text-top !important;
}

.navbar-light .navbar-toggler {
  background-color: white;
}

/*** SHR ****/
.medfusion-instructions {
  border-color: black;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  padding: 15px;
  text-align: center;
  margin: 15px;
}

.medfusion-instructions dt {
  color: rgb(232, 142, 61);
  font-weight: 100;
  font-size: 20px;
  text-align: center;
}

#consent .bg-white {
  box-shadow: none;
  margin: 0 auto;
  padding: 0rem !important;
}

.consent-bullet {
  margin-bottom: 10px;
}

.delete-btn-icon {
  position: relative;
  bottom: 4px;
  left: 1px;
}
/*** Footer ***/

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
  height: 100%;
}

.container-fluid-main {
  padding-bottom: 10rem !important;
}

/**
 * Footer Styles
 */

footer.footer {
  position: relative;
  right: 0;
  top: 0px;
  left: 0;
  background-color: var(--color-primary);
  text-align: center;
  z-index: 1000;
}

.social-container {
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
}

.social-icon-img {
  width: 15px;
}

/* MOBILE INSERT */

.mobile-app-transform nav#navbar.mobile-nav {
  display: none !important;
}

.mobile-app-transform
  .container-fluid.dashboard-container
  .dashboard-container {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.mobile-app-transform
  .container-fluid.dashboard-container
  .providers-container.dashboard-item.shr-container {
  width: 100%;
  margin: 0;
}

.mobile-app-transform .shr-inner-container .dashboard-item-banner {
  display: none;
}

.mobile-app-transform #footer {
  display: none;
}

/* DIPEP-1347 Landing Page styling */

.landing-title {
  font-weight: 400;
  font-size: 24px;
}

.landing-label {
  font-weight: 100;
  font-size: 14px;  
  margin: 0 0 5px 0;
  padding: 0;
}
