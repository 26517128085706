$primary-color: #32323C;
$primary-light-color: #46464F;
$primary-dark-color: #1E1E24;

$accent-color: #F0AD4E;
$accent-light-color: #FAAF6B;
$accent-dark-color: #D58032;

$error-color: #E53935;
$success-color: #40B458;

$neutral-black-color: #000000;
$neutral-black-light-color: rgba(#000000, .6);
$neutral-black-lighten-color: rgba(#000000, .3);

$neutral-white-color: #FFFFFF;
$neutral-white-light-color: rgba(#FFFFFF, .6);
$neutral-white-lighten-color: rgba(#FFFFFF, .3);

$neutral-grey-color: #EBEBEB;
$neutral-grey-light-color: #FAFAFA;
