$primary-color: #1b3663;
$secondary-color: #f78920;
$primary-font: 'Fjalla One';
$secondary-font: 'Overpass';
// Fjalla One
// Overpass
// Helvetica Neue
// sans-serif
// Lato
// Roboto

:root {
  --color-primary: #{$primary-color};
  --color-secondary: #{$secondary-color};
  --color-secondary-light: #{scale-color($secondary-color, $alpha: 40%)};
  --font-primary: #{$primary-font};
  --font-secondary: #{$secondary-font};
  --footer-height: 55px;
  --background-color: #fafaf9;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin-bottom: 100px;
}

#mainContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
  scroll-behavior: smooth;
}
.react-datepicker__aria-live {
  display: none;
}
.App {
  text-align: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
.routeContainer {
  height: 100%;
  width: 100%;
  position: relative;
}
.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
.consent-style {
  font-family: var(--font-secondary);
  font-weight: 400;
  h5 {
    font-weight: bold;
  }
}
@-moz-document url-prefix() {
  span.sidenav-title {
    position: relative;
    top: 1.5px;
  }
}
span.sidenav-title {
  margin-right: 10px;
}
.delete-item-modal {
  .delete-modal {
    .modal-content {
      font-family: var(--font-secondary);
      position: absolute !important;
      margin-left: calc(55% - 340px) !important;
      width: 60% !important;
      max-width: 588px !important;
      padding: 28px 24px 24px 24px !important;
      h2 {
        font-weight: 500;
      }
    }
    div {
      letter-spacing: normal;
      .btn-container {
        button {
          line-height: 1.5;
        }
        .btn-default {
          background: white;
          color: black;
          &:hover {
            color: #000;
            background-color: #ddd;
            border-color: #eee;
          }
        }
        .btn-primary {
          color: white;
        }
      }
    }
  }
}
.center-container {
  padding: 0 !important;
  margin: 0 auto !important;
}
.zero-margin {
  margin: 0 !important;
}
.sub-nav-container {
  height: 71px;
}
.table-responsive {
  word-break: break-word;
  .py-2 {
    ul {
      height: auto !important;
    }
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
  }
}
@media all and (max-width: 576px) {
  .inbox-button {
    margin-left: -14px;
  }
  .signer-info {
    width: 100% !important;
    float: none !important;
  }
  img.consent-signature {
    width: 50%;
  }
  .sign-badge-container img {
    margin: auto !important;
  }
  .consent-container-pdf > div {
    padding: 15px;
  }
}

@media all and (max-width: 516px) {
  div.dashboard-container .dashboard-item-banner .filter {
    float: none !important;
    &__select {
      button {
        width: 175px;
      }
    }
    .btn-create-user {
      display: none;
    }
    &.user-index-filter {
      margin-left: 0 !important;
      margin: 0 auto !important;
      width: 95%;
    }
    &:not(.actions-container) {
      margin-left: 20px;
    }
    &.actions-container {
      margin-left: 5px;
    }
  }
}
@media all and (max-width: 577px) {
  div.filter.user-index-filter {
    padding-left: 6% !important;
  }
}
@media all and (max-width: 440px) {
  .delete-item-modal {
    .delete-modal {
      .modal-content {
        width: 50% !important;
        margin-left: 0;
      }
    }
  }
  .button-responsive-positioning {
    position: relative;
    top: 25px;
    right: 145px;
  }
}
.inbox-card-item-list {
  button {
    background-color: transparent;
  }
}
.delete-button {
  button {
    background-color: transparent;
  }
  button:focus {
    box-shadow: none;
  }
}
.delete-item-modal .modal-content {
  height: auto !important;
  padding-bottom: 30px !important;

  .btn-container {
    margin-top: 20px;
  }
  h2 {
    margin-bottom: 15px !important;
  }
}
.App-title {
  font-size: 1.5em;
}
.sidebar-item-children-wrapper {
  * {
    border-radius: 0 !important;
  }
}
.sidebar-sticky {
  max-width: 95.5% !important;
}
.App-intro {
  font-size: large;
}
.btn-login {
  max-height: 48px;
  i {
    margin: 0 10px;
  }
}
.pagination-items {
  .page-link {
    font-size: 14px;
    color: #4e92df;
  }
  li.disabled {
    pointer-events: none !important;
  }
  li:hover {
    background: #eee;
  }
  li:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}
#profileContainer {
  .table {
    width: calc(100% - 10px);
    font-size: 16px;
  }
}
.sub-nav {
  padding: 8px 0 0 0 !important;
  line-height: 1;
}
.capitalize {
  text-transform: capitalize !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */

.btn-monarc {
  background-color: rgb(100, 165, 207);
  border-color: rgb(100, 165, 207);
  color: white;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
.ph-item.profile {
  border: none !important;
  padding: 0 !important;
  position: relative;
  right: 100%;
  margin: 0 !important;
  div {
    margin: 0;
  }
}
.bg-monarc-orange {
  background-color: var(--color-secondary-light) !important;
}

.text-monarc-orange {
  color: var(--color-secondary) !important;
}

.text-monarc-green {
  color: rgb(138, 197, 125) !important;
}

.text-monarc-purple {
  color: #a45e9e !important;
}

.btn-monarc-purple {
  background-color: white;
  border-color: #a45e9e;
  color: #a45e9e;
}

.btn-monarc-purple:hover,
.btn-monarc-purple:focus {
  border-color: white;
  background-color: #a45e9e;
  color: white;
}

.btn-monarc-purple:active,
.btn-monarc-purple:visited,
.btn-monarc-purple:active:focus,
.btn-monarc-purple:active:hover {
  border-color: #a45e9e;
  background-color: #a45e9e;
  color: white;
}

.btn-monarc-orange:hover,
.btn-monarc-orange:focus {
  border-color: var(--color-secondary-light);
  background-color: var(--color-secondary-light);
  color: white;
}

.btn-monarc-orange:active,
.btn-monarc-orange:visited,
.btn-monarc-orange:active:focus,
.btn-monarc-orange:active:hover {
  border-color: var(--color-secondary-light);
  background-color: var(--color-secondary-light);
  color: white;
}

.signer-info {
  width: calc(100% - 300px);
  float: right;
}
.sign-badge-container {
  margin-top: 50px;
}
.borderless td,
.borderless th {
  border: none;
  padding: none;
}

/* Landing Page */
.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
footer.footer {
  min-height: var(--footer-height);
  padding: 5px;
  bottom: 0px;
  top: auto;
}
.jumbotron.with-link {
  height: auto;
  padding: 145px 0 !important;

  p {
    margin: 0 !important;
    color: #ffffff;
    font-family: var(--font-secondary);
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 45px !important;
  }
  h1 {
    margin-bottom: 25px;
  }
}
.jumbotron-join-button-container button.join-now {
  padding: 20px 45px;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  height: 64px;
  font-family: var(--font-primary);
  min-width: 200px;
  width: auto;
}
.jumbotron-join-button-container {
  display: block;
}
.landing-page-process-container {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.landing-page-paragraph h1 {
  font-weight: 800;
  font-size: 25px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-paragraph h2 {
  font-weight: 800;
  font-size: 20px;
  text-align: left;
  color: var(--color-primary);
}

.landing-page-paragraph h3 {
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  color: var(--color-primary);
}

.landing-page-paragraph hr {
  border-color: white;
}

.landing-page-paragraph p {
  margin: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-process-container {
  background-color: rgb(100, 165, 207);
  color: white;
  padding-top: 40px;
  padding-bottom: 40px;
}

.landing-page-process-container h2 {
  font-weight: 800;
  color: rgb(54, 74, 79);
  font-size: 25px;
}

.process-list {
  margin-top: 25px;
  margin-bottom: 25px;
}

.process-item .media-body h5 {
  font-weight: 500;
  color: rgb(54, 74, 79);
  font-size: 16px;
  margin-bottom: 0;
}

.process-item .media-body p {
  font-weight: 200;
  color: rgb(54, 74, 79);
  font-size: 15px;
}

.landing-page-support {
  margin-top: 50px;
  margin-bottom: 0px;

  & .schedule-call {
    display: inline-block;
    text-decoration: none;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    margin-top: 1rem;
    border: 2px solid var(--color-secondary);
    border-radius: 50px;
    background-color: var(--color-secondary);
    color: #ffffff;
    font-family: var(--font-primary);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.28px;
    line-height: 24px;
  }
}

.landing-page-support h2 {
  font-weight: normal;
  font-size: 25px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-support h3 {
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-support p {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  color: var(--color-primary);
  margin-bottom: 50px;
}

.landing-page-support-item-icon {
  font-size: 50px;
  color: var(--color-primary);
}

/********/

/*** Registration ***/

.policies-page {
  background: var(--background-color);
  height: 100%;

  .container {
    background: white;
    width: 480px;
    min-height: 30vh;
    max-width: calc(100% - 20px) !important;
    margin-inline: auto;
    margin-block: 24px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    padding-block: 24px;
  }
  h2 {
    color: var(--color-secondary);
    font-family: var(--font-secondary);
    font-size: 34px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
  }
  h5 {
    font-family: var(--font-primary);
    text-align: center;
    padding-block: 24px;
  }
  .policy-input {
    input[type='checkbox']:checked {
      accent-color: var(--color-primary);
    }
  }
  .btn.btn-primary {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    margin-top: 24px;
  }
  .policy-modal {
    .modal-content {
      min-width: 640px;
      height: 100%;
      .modal-header {
        justify-content: center;
        .modal-title {
          color: var(--color-secondary);
        }
      }
      .modal-footer {
        .btn.btn-primary {
          margin: 0;
          padding-inline: 0;
          width: 38px;
        }
      }
    }
  }
}

.register-v2 {
  background: var(--background-color);

  * {
    outline: none;
  }

  .custom-select.pull-up > div:nth-child(3) {
    margin-top: -5px !important;
  }
  .custom-select:not(.locale-picker-select) {
    div {
      border-color: #ced4da;
      box-shadow: unset;
      justify-content: center;
    }
    div > .css-xp4uvy {
      padding-left: 48.25%;
    }
  }
  .container {
    background: white;
    width: 960px;
    min-height: 590px;
    height: 100%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-bottom: 100px;
    position: relative;
    overflow: hidden;
    * {
      text-align: center;
    }
    h2 {
      color: var(--color-secondary);
      font-family: var(--font-secondary);
      font-size: 34px;
      font-weight: 300;
      line-height: 40px;
      text-align: center;
      padding-top: 48px;
    }
    h3 {
      color: #4a4a4a;
      font-family: var(--font-secondary);
      font-size: 18px;
      line-height: 22px;
      font-weight: normal;
      text-align: center;
      margin-bottom: 16px;
      margin-top: 15px;
    }
    .options-container {
      min-height: 187px;
    }
    .option-card {
      box-sizing: border-box;
      height: 174px;
      width: 234px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      background-color: #ffffff;
      box-shadow: inset 0 1px 0 0 #ececec;
      display: inline-block;
      font-family: var(--font-secondary);
      color: #9b9b9b;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      text-align: center;
      position: relative;
      top: -5px;
      cursor: pointer;
      &:not(:first-child):not(:last-child) {
        margin: 0 12px;
      }
      padding-top: 25px;
      &.active {
        color: var(--color-secondary);
        height: 180px;
        width: 240px;
        box-sizing: border-box;
        border: 4px solid var(--color-secondary);
        border-radius: 5px;
        background-color: #ffffff;
        box-shadow: inset 0 1px 0 0 #ececec;
      }
      .options-label {
        padding: 0 35px;
        position: absolute;
        top: 80px;
        left: -7px;
        width: 245px;
      }
    }
    .step2 {
      .form-input-container {
        margin-top: 35px !important;
      }

      .qualification-message {
        margin-top: 48px;
        font-size: 24px;
        color: var(--color-primary);
      }
    }
    .loading-options {
      font-size: 50px;
      margin-top: 45px;
    }
  }
  .form-input-container {
    clear: both;
    margin-top: 25px;
    padding: 0 91px;
    label {
      font-family: var(--font-secondary);
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 6px;
    }
    input {
      height: 45px;
      font-family: var(--font-secondary);
      font-size: 18px;
      line-height: 22px;
      color: #999999;
    }
    .custom-select {
      height: 58px;
    }
  }
  .footer {
    position: absolute;
    height: 86px;
    background: white;
    margin-top: 2px;
    right: 0;
    .buttons-container {
      display: inline-block;
      width: 100%;
      > * {
        display: inline-block;
      }
      button {
        outline: none;
        &.disabled {
          background: #eee !important;
          border: none !important;
          color: #ccc !important;
        }
      }
      .btn-back {
        float: left;
        &:hover {
          background-color: #eee;
        }
      }
      .btn-next {
        float: right;
      }
      .btn-next-container {
        width: 19%;
        float: right;
        margin-right: 25px;
        margin-top: 25px;
        button {
          height: 38px;
          width: 111px;
          border: 1px solid var(--color-secondary);
          border-radius: 4px;
          background-color: var(--color-secondary);
          color: #ffffff;
          font-weight: normal;
          font-family: var(--font-secondary);
          font-size: 16px;
          line-height: 18px;
          cursor: pointer;
          &:hover {
            background-color: #f9a04b;
          }
        }
      }
      .btn-back-container {
        width: 20%;
        float: left;
        margin-left: 25px;
        margin-top: 25px;
        button {
          height: 38px;
          width: 111px;
          border: 1px solid #cccccc;
          border-radius: 4px;
          background-color: #ffffff;
          color: #373a3c;
          font-family: var(--font-secondary);
          font-size: 16px;
          font-weight: normal;
          line-height: 18px;
          cursor: pointer;
        }
      }
      .steps-container {
        list-style: none;
        width: 42%;
        padding: 0;
        margin-top: 30px;
        li {
          display: inline-block;
          margin-right: 8px;
          div {
            width: 8px;
            height: 8px;
            border-radius: 20px;
            background: var(--color-secondary);
            opacity: 0.3;
          }
          &.active {
            div {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.success {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      word-break: break-word;
      background-color: #ffffff;
      padding: 0;
    }
    h2 {
      padding: 16px;
    }
  }
}
@media all and (max-width: 485px) {
  div.register-v2 {
    padding-bottom: 25px !important;
  }
}
@media all and (max-height: 780px) and (max-width: 767px) {
  div.register-v2 {
    padding-bottom: calc(100% + 210px);
  }
}

@media all and (max-width: 961px) {
  .policies-page {
    .policy-modal {
      &.backdrop {
        padding: 20px;
      }
      .modal-content {
        min-width: 275px;
        padding: 12px 8px 8px 8px;
        .modal-header {
          padding: 0.5rem;
          .modal-title {
            padding-block: 12px;
          }
        }
        .modal-body {
          ul {
            padding-inline-start: 20px;
          }
        }
        .modal-footer {
          padding: 0.5rem;
        }
      }
    }
  }

  div.register-v2 {
    .register-nav {
      display: block;
      text-align: center;
      a {
        margin: 0;
      }
    }
    div.container {
      background: white;
      width: 70%;
      padding: 50px 10%;
      padding-bottom: 15%;
      min-height: 658px;
      height: auto;
      margin-bottom: 25px;
      .form-input-container {
        padding: 0;
      }
      div.option-card {
        width: 100%;
        height: 100%;
        margin: 0;
        &:not(:first-child):not(:last-child) {
          margin: 10px 0;
        }
        p.options-label {
          width: 100%;
          position: relative;
          top: 0;
        }
      }
    }
    .footer {
      div.buttons-container {
        .steps-container {
          padding: 0;
          width: 25%;
        }
      }
    }
  }
}

.registration-container {
  background-color: rgb(100, 165, 207);
  position: absolute;
  z-index: 10;
  /*  height: 100%;
*/
}

#register-form {
  position: relative;
}

#register-form fieldset {
  background: white;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;
  margin-bottom: 25px;

  /*stacking fieldsets above each other*/
  position: relative;
}

.registration-instructions {
  margin-bottom: 25px;
}

.registration-instructions h1,
.registration-instructions h2,
.registration-instructions h3 {
  font-weight: 200;
  margin-bottom: 25px;
  text-align: center;
}

/*#register-form fieldset:not(:first-of-type) {
    display: none;
}*/

#register-form fieldset {
  display: none;
}

#register-form fieldset.active {
  display: block;
}

/*#register-form button {
    width: 100px;
    font-weight: bold;
    color: rgb(114,164,203);
    border-color: rgb(114,164,203);
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}
#register-form button:hover, #register-form button:focus {
    color: white;
}*/

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 23%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 11px;
  z-index: -1;
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/

#progressbar li.active:before,
#progressbar li.active:after {
  background: var(--color-primary);
  color: white;
}

/********/

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}

.bd-callout-info {
  border-left-color: rgb(100, 165, 207);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-secondary);
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link a {
  color: var(--color-secondary);
}

a {
  color: rgb(100, 165, 207);
}

.btn-mbn-blue {
  background-color: rgb(100, 165, 207);
  color: white;
}

.signature-modal-body img {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.signature-modal-body canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.signature-modal-body {
  height: 250px;
}

.nav-item span {
  cursor: pointer;
}
.menu:not(.sidebar) .active span {
  color: rgb(99, 165, 207) !important;
}
.menu .nav-item a div span {
  text-transform: unset !important;
}
.menu .sidebar-header {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.75);
}
.nav-item {
  .row {
    .col-9 {
      font-family: sans-serif !important;
    }
  }
}
/* .menu .nav-item {
  margin: 0;
  height: auto;
  width: 100%;
  padding: 0;
} */

.menu .nav-item a div span {
  text-transform: uppercase;
  font-family: var(--font-secondary);
  cursor: pointer;
  font-size: 13px;
  line-height: 19px;
  margin: 13px 0 13px 16px;
}
.nav-item .row div {
  font-weight: bold !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.dashboard-container > div {
  width: calc(100% - 2rem);
}

.menu .nav-item a {
  color: #818a91;
}

.menu .nav-item .active {
  color: rgba(255, 255, 255, 1);
}

.menu .nav-item:hover {
  color: rgb(255, 255, 255);
  text-decoration: none !important;
}

.menu .nav-item a:hover {
  color: rgb(255, 255, 255);
  text-decoration: none !important;
}

.menu .nav-item a div i {
  color: inherit;
  text-decoration: inherit;
  float: right;
  margin: 13px 16px 13px 0;
}

.menu .nav-item .sidebar-item-children-wrapper {
  padding-left: 16px;
}

.menu .nav-link .active {
  color: #007bff;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.menu:not(.sidebar) .nav-item {
  padding-top: 8px;
}

.menu .dropdown-divider {
  height: 1px;
  width: 100%;
  background-color: #46464f;
  margin: 0;
  border: 0;
}

.dashboard-container {
  background-color: rgb(250, 250, 249);
  overflow: auto;
  padding: 0;
  p.info-container {
    max-height: 100px;
    overflow: auto !important;
  }
  p.col-10 {
    margin-top: 55px;
  }
  p.mf-connect {
    min-height: 60px;
    .mf-connect-btn:hover {
      cursor: pointer;
    }
  }
  .import-records {
    width: 100%;
    margin: 1rem auto !important;
  }
  h1:not(.shr-title, .import-records) {
    float: left;
    color: var(--color-secondary);
    font-weight: 300;
  }
}
.dashboard-container {
  > .dashboard-item {
    border-radius: 4px;
  }
  .dashboard-item-banner {
    width: 100%;
    height: auto !important;
    .filter {
      float: right !important;
      &__select {
        color: white;
      }
    }
    h2 {
      color: white;
      width: auto;
      display: inline-block;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}

@media screen and (max-width: 680px) {
  .resources-header {
    .resource-filters {
      margin-inline: 8px !important;
    }
  }
}

@media screen and (max-width: 491px) {
  .resources-header {
    .resource-filters {
      .filter-by {
        label {
          width: fit-content !important;
        }
        button {
          width: 120px !important;
        }
      }
      .input-container {
        input {
          width: 100px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 365px) {
  .resources-header {
    .resource-filters {
      .filter-by {
        label {
          display: none !important;
        }
      }
    }
  }
}

.dashboard-item h2 {
  font-weight: 200;
}

.dashboard-item th {
  font-weight: normal;
}

.dashboard-item td {
  font-weight: normal;
  //margin-bottom: 50px;
}

.dashboard-item-content-container h1 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container h2 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container h3 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container p {
  font-size: 20px;
  font-weight: 200;
}

.dashboard-item-content-container p img {
  max-width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  border-radius: 10px;
}

.dashboard-item-content-container {
  font-size: 20px;
  font-weight: 200;
}
.dashboard-container {
  .container {
    max-width: unset !important;
  }
}
.navbar-light .navbar-toggler {
  background-color: white;
}
div.custom-select.is-invalid,
div.custom-select.is-valid {
  border-bottom-width: 2px;
  border-top-width: 2px;
  border-left-width: 2px;
  border-right-width: 4px;
  height: 61px !important;
}
.screening-questions {
  div.custom-select.is-invalid,
  div.custom-select.is-valid {
    height: 40px !important;
  }
  .form-input-container {
    padding-block-start: 24px;
  }
  .btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    &:active {
      border-color: var(--color-primary-light) !important;
      background-color: var(--color-primary-light) !important;
    }
    &:focus,
    &:focus:active {
      box-shadow: 0 0 0 0.2rem var(--color-primary-light) !important;
    }
  }
}
/*** SHR ****/
.medfusion-instructions {
  border-color: black;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  padding: 15px;
  text-align: center;
  margin: 15px;
}

.medfusion-instructions dt {
  color: rgb(232, 142, 61);
  font-weight: 100;
  font-size: 20px;
  text-align: center;
}

.consent-bullet {
  margin-bottom: 10px;
}

.medfusion-modal {
  color: #64a5cf;
}

.medfusion-modal:hover {
  color: #0056b3;
  text-decoration: underline;
}
/*** Authorization/Consent ***/
.consent-container {
  width: 686px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 20px;
  margin: 0 auto;
  line-height: 32px;
}
.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.consent-table {
  margin: 0 auto;
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-family: var(--font-secondary);
  line-height: 32px;
  td:first-child {
    font-weight: bold;
  }

  tr > td:first-child {
    width: 25%;
    vertical-align: middle;
    padding-right: 5%;
  }
  tr > td:nth-child(2) {
    width: 60%;
    vertical-align: middle;
  }
}
@media all and (max-width: 790px) {
  .consent-table {
    font-size: 16px;
  }
}

.consent-image {
  width: 50%;
  display: block;
  margin: auto;
}

.consent-image-description {
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.consent-header {
  padding-top: 1%;
  padding-bottom: 1%;
}

#consent-footer {
  position: relative;
  width: 100%;
  bottom: 10px;
  text-align: center;
  font-size: 10px;
}
/*** Footer ***/
html {
  height: 100%;
  box-sizing: border-box;
  background: var(--background-color);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
}
.sub-nav.nav-item {
  margin-left: 35px !important;
  font-size: 13px;
}
.dashboard-item.invitations-list {
  .user-info {
    width: auto !important;
    margin-top: 30px;
    th {
      padding-right: 22px;
    }
  }
}

/**
 * Footer Styles
 */
nav#navbar.mobile-nav {
  display: none;
}
#sidenav {
  display: block;
}
.dashboard-container {
  > .row {
    width: 100%;
    margin: 0;
  }
}
@media all and (min-width: 961px) {
  .mobile-sidebar {
    display: none;
  }
}
.sub-nav.nav-item {
  width: 200px;
  padding: 15px 0 !important;
  height: 51px;
  line-height: 1.5;
}
@media all and (max-width: 1060px) and (min-width: 890px) {
  .dashboard-container .user-index .dashboard-item-banner .filter {
    float: none !important;
    margin-top: 20px;
  }
}
@media all and (max-width: 810px) {
  .dashboard-container .user-index .dashboard-item-banner .filter {
    float: none !important;
    margin-top: 20px;
  }
}
.landing-login {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  padding-inline-start: 8px;
}
@media all and (max-width: 960px) {
  .sub-nav.nav-item {
    width: 200px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .container {
    max-width: unset !important;
  }
  #navbar:not(.dashboard-nav) {
    .menu-icon-container {
      visibility: hidden;
    }
  }

  #sidenav {
    display: none;
  }

  .dashboard-container {
    min-height: calc(100vh - 185px);
    width: 100%;
    display: block !important;
    max-width: unset;
  }
  .dashboard-container > .row {
    display: block !important;
    > span {
      display: block;
      width: 406px;
      margin-left: 15px;
    }
  }
  .desktop-nav {
    display: none;
  }
  .landing-login {
    a {
      font-size: 16px;
    }
  }
  .landing-container {
    width: 66% !important;
  }
  .btn-nav.signup {
    margin-right: 32px !important;
  }

  .mobile-nav {
    display: flex !important;
    height: 62px;
    padding: 16px;
    i,
    svg {
      cursor: pointer !important;
    }
    .navbar-brand {
      height: 100%;
      position: relative;
      left: 1px;
      padding: 0;
      img {
        height: 31.12px;
        width: 108.76px;
        margin: 0 auto;
      }
    }
    .navbar-container {
      width: 33%;
      display: inline-block;
      position: relative;
    }
    .logo-container:not(.landing-logo) {
      text-align: center;
    }
    .btn-container {
      display: flex;
      position: relative;
      right: 6px;
      .home-icon {
        border-right: 1px solid #ccc;
        margin-right: 16px;
        padding-right: 16px;
        width: 41px;
      }
    }
  }
  .mobile-sidebar {
    width: 235px;
    height: 90%;
    max-width: unset;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0 !important;
    z-index: 1020;
    .active * {
      color: white !important;
    }
    > ul {
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
      height: 100%;
      background: white;
      position: fixed;
      min-width: 235px;
      overflow-x: hidden;
      overflow-y: auto;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        height: 48px;
        padding: 16px;
      }
      *:not(i) {
        border-radius: 0 !important;
        display: block !important;
        max-width: unset;
        color: var(--color-primary);
        font-family: var(--font-secondary);
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        text-decoration: none;
      }
      a,
      li {
        height: 48px !important;
        width: 100%;
      }
    }
    .row {
      display: flex;
    }
    .col-1 {
      float: left;
      width: auto;
    }
  }
  .col-9 {
    .badge-container {
      height: 15px;
      width: fit-content;
      color: #ffffff;
      font-family: var(--font-secondary);
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      position: relative;
      left: 80%;
      bottom: 20px;
      border-radius: 5px !important;
      .badge-pill {
        color: white;
        padding-right: 0.68em;
      }
      span {
        border-radius: 15px !important;
      }
    }
  }
  .sign-in-icn {
    svg {
      position: relative;
      top: 5px;
      right: 7px;
    }

    text-decoration: none !important;
    color: #80868b !important;
  }
  .login-form #navbar:not(.dashboard-nav) .logo-container {
    bottom: 0px !important;
  }
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-form {
  background-color: var(--color-secondary);
}
@media all and (max-width: 370px) {
  .logo-container {
    right: 15px;
  }
}
.sidebar-appear,
.sidebar-enter {
  left: -20%;
  opacity: 0;
}

.sidebar-appear.sidebar-appear-active,
.sidebar-enter.sidebar-enter-active {
  left: 0%;
  opacity: 1;
  transition: all 0.2s ease-in;
}
.sidebar-exit {
  left: 0;
  opacity: 1;
}

.sidebar-exit .sidebar-exit-active {
  left: -20%;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.background-enter {
  transform: translate(100%);
}
.background-enter.background-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}
.background-leave {
  transform: translate(0%);
  opacity: 0;
}
.background-leave.background-leave-active {
  transform: translate(-100%);
  position: absolute;
  top: 90px;
  opacity: 0.2;
  transition: transform 300ms ease-in-out;
}
.background-r-enter {
  transform: translate(-100%);
}
.background-r-enter.background-r-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}
.background-r-leave {
  transform: translate(0%);
  opacity: 0;
}
.background-r-leave.background-r-leave-active {
  transform: translate(100%);
  position: absolute;
  top: 90px;
  opacity: 0.2;
  transition: transform 300ms ease-in-out;
}
.custom-select {
  > div {
    position: relative;
    bottom: 7px;
    right: 13px;
    width: calc(100% + 44px);
  }
  > div:nth-child(3) {
    position: relative !important;
    max-height: 155px !important;
    bottom: 120px !important;
    z-index: 1001;
    margin-top: -30px;
    border: 1px solid #ccc;
    box-shadow: 0 3px 4px 0 rgba(27, 54, 99, 0.14),
      0 3px 3px -2px rgba(27, 54, 99, 0.12), 0 1px 8px 0 rgba(27, 54, 99, 0.2);
    & > div {
      max-height: 140px;
    }
  }
  &.locale-picker-select {
    width: 150px;

    .custom-select__control {
      color: var(--color-primary);
      border-width: 0px;

      &.custom-select__control--menu-is-open {
        .custom-select__indicator {
          transform: rotateX(180deg);
        }
      }
    }

    .custom-select__single-value {
      color: var(--color-primary);
      padding-inline-start: 24px;
    }

    .custom-select__indicator-separator {
      width: 0;
    }

    .custom-select__option {
      i.fas {
        padding-inline-start: 8px;
      }
      &.custom-select__option--is-selected {
        color: var(--white);
        background-color: var(--color-secondary);
      }
    }
  }
}

@media all and (max-width: 960px) {
  .custom-select {
    &.locale-picker-select {
      width: 89px;
    }
  }
}

.pull-right {
  float: right;
}
#helpButton {
  height: 64px;
  width: 64px;

  .fa-times {
    font-size: 40px;
  }
}
@media all and (max-width: 760px) {
  #helpButton {
    bottom: 35px !important;
    #HelpButton {
      width: 60px !important;
      height: 60px !important;
    }
  }
  .register-v2 .footer .buttons-container .btn-back-container {
    margin-left: 20px !important;
  }
  .container-fluid div.register-v2 div.container {
    width: 90% !important;
    padding-bottom: 20%;
  }
  .register-v2 {
    ::-webkit-input-placeholder {
      /* WebKit browsers */
      font-size: 12px;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-size: 13px;
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-size: 13px;
      opacity: 1;
    }
    :-ms-input-placeholder {
      font-size: 13px;
      /* Internet Explorer 10+ */
    }
  }
  .form-input-container div.mobile-placeholder {
    display: block;
    font-size: 14px;
    text-align: left;
  }
}
.mobile-placeholder {
  display: none;
}
.mf-cta__primary:focus,
.mf-cta__primary--optional:focus,
.mf-cta__secondary:focus,
.mf-btn:focus {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.page-404 {
  background: var(--background-color);
  height: calc(100vh - 150px);
  padding-top: 40px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  .container {
    background: white;
    height: 500px;
  }
  h2 {
    padding-top: 20px;
    font-weight: 200;
    margin-bottom: 15px;
  }
  .links-404 {
    button {
      color: #4a90e2;
      appearance: none;
      background: white;
      border: none;
      outline: none;
      padding: 0;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      &.back-to-home {
        margin-right: 20px;
      }
    }
  }
}
#helpModal {
  width: 350px !important;
  background-color: #ffffff !important;
  border-radius: none;
  .help {
    .panel {
      display: flex;
      flex-flow: column;
      height: 100%;
      width: 350px;
      background-color: #ffffff;
      border-radius: 20px !important;
    }
  }
  .modal-content {
    border-radius: 30px;
    border: none;
    background: transparent;
  }
  .panel-profile-img {
    max-width: 80px;
    margin-top: -57px;
    margin-bottom: 11px;
    border: 3px solid rgb(255, 255, 255);
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
    vertical-align: middle;
    border: none;
    box-shadow: none;
  }
  .panel-top {
    p {
      opacity: 0.54;
      color: var(--color-primary) !important;
      font-family: var(--font-secondary);
      font-size: 12px !important;
      line-height: 16px;
      text-align: center;
      width: 288px;
    }
  }
  .panel-body {
    padding: 0 24px !important;
    .have-questions {
      color: var(--color-primary);
      letter-spacing: 0.3px;
      margin-top: 20px;
      font-family: var(--font-secondary);
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      text-align: center;
    }
    hr {
      width: 252px;
      margin: 19px auto 28px auto;
    }
    .contact-team {
      color: var(--color-primary);
      font-family: var(--font-secondary);
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
    a {
      text-decoration: underline;
      font-weight: normal;
      font-size: 15px;
      color: #4fa7d3;
    }
    i {
      color: var(--color-secondary) !important;
      font-size: 24px;
      &.phone-container {
        position: relative;
        left: 20px;
      }
      &.mail-container {
        position: relative;
        left: 10px;
      }
    }
    .schedule-call {
      display: block;
      padding: 12px 62px;
      margin-top: 16px;
      text-decoration: none !important;
      width: 291px;
      font-weight: 100;
      border: 2px solid var(--color-secondary);
      border-radius: 50px;
      background-color: var(--color-secondary);
      color: #ffffff;
      font-family: var(--font-primary);
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1.28px;
      line-height: 24px;
      text-align: center;
      position: relative;
      bottom: 7px;
    }
    .col-9 {
      margin-bottom: 20px;
      a {
        color: #4fa7d3;
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 100 !important;
        line-height: 24px;
      }
    }
    .row.justify-content-center {
      width: 312px;
      position: relative;
      text-align: center;
      right: 20px;
      padding: 0 20px;
    }
  }
}
.ml-15 {
  margin-left: 15px;
}
.ml-0 {
  margin-left: 0;
}
.container.p-3 {
  margin-left: 0 !important;
}
.mt-4.col-md-9.dashboard-container.py-4 {
  .container {
    margin-left: 0;
  }
}
.consent-container-pdf {
  margin-left: 15px;
  margin-top: 9px !important;
  .navbar {
    padding-left: 0;
    button {
      margin-left: 0 !important;
    }
  }
}
@media all and (max-width: 420px) {
  #register-form {
    fieldset {
      button {
        cursor: pointer;
        display: block;
        float: none !important;
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
  .container-fluid div.register-v2 div.container {
    padding-bottom: 25%;
  }
}
.mobile-sidebar {
  -webkit-animation: fadein 500ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 500ms; /* Firefox < 16 */
  -ms-animation: fadein 500ms; /* Internet Explorer */
  -o-animation: fadein 500ms; /* Opera < 12.1 */
  animation: fadein 500ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body.modal-open {
  overflow-y: auto;
  padding: 0 !important;
}
.user-index {
  overflow: visible;
}

.btn-nav {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;

  text-align: center;
  &.signup {
    margin-right: 48px;
  }
  &.login {
    margin-inline: 12px;
  }
  &:hover {
    text-decoration: none;
  }
}
.jumbotron {
  margin-bottom: 0 !important;
  h1 {
    color: #ffffff;
    font-family: var(--font-primary);
    font-size: 60px;
    line-height: 80px;
    margin-bottom: 37px;
  }
  p {
    color: #ffffff;
    font-family: var(--font-secondary);
    font-size: 24px;
    line-height: 32px;
  }
  margin-bottom: 0;
  .title-container {
    padding: 0;
    div {
      max-width: 450px;
      padding-top: 82px;
      text-align: left;
      margin-left: 90px;
    }
  }
}

.list-group.landing::-webkit-scrollbar {
  width: 0.5em;
}

.list-group.landing::-webkit-scrollbar-track {
  border-radius: 5px;
}

.list-group.landing::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
  border-radius: 5px;
}

.list-group.landing {
  margin-left: 80px;
  text-align: left;
  padding-inline: 12px;

  overflow-wrap: normal;
  max-height: 550px;
  overflow-y: scroll;

  a {
    color: var(--color-primary);
    font-family: var(--font-secondary);
    font-size: 20px;
    padding: 12px 24px;
    line-height: 30px;

    &:hover {
      background-color: var(--color-secondary);
      color: #ffffff;
      svg {
        fill: white;
      }
    }
    svg {
      position: absolute;
      right: 17px;
      top: 30%;
    }
  }
}
.help-modal-container {
  position: relative;
  #helpModal {
    box-shadow: 0 3px 4px 0 rgba(27, 54, 99, 0.14),
      0 3px 3px -2px rgba(27, 54, 99, 0.12), 0 1px 8px 0 rgba(27, 54, 99, 0.2);
    border-radius: 20px !important;
  }
  .arrow {
    width: 100px;
    height: 25px;
    position: absolute;
    bottom: -24px;
    left: 82%;
    transform: translateX(-50%);
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: white;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: 0;
      left: 50%;
      box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.6);
    }
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .benefits-info {
      letter-spacing: -1px !important;
    }
  }
}
@-moz-document url-prefix() {
  .benefits-info {
    letter-spacing: -0.5px !important;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .benefits-info {
    letter-spacing: normal !important;
  }

  .faq-section .card-header a h5 {
    letter-spacing: -1px;
  }
}

@media all and (max-width: 767px) {
  .btn-back-container {
    margin-left: 5px !important;
  }
  div.register-v2 .footer div.buttons-container .steps-container {
    width: 19% !important;
  }
  .btn-nav.login {
    margin-right: 0px;
    margin-left: 4px;
  }
  div.landing-page-support {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
    margin-top: 56px !important;
  }
  .join-now {
    margin-left: 0 !important;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    div#helpModal {
      bottom: unset;
      top: 0;
    }
  }
  section.jumbotron.with-link {
    padding-top: 108px !important;
    padding-bottom: 108px !important;
    .join-now {
      margin: auto !important;
    }
    div.container {
      padding-right: 45px;
      padding-left: 45px;
    }
  }

  .help-modal-container {
    position: absolute;
    width: 100%;
    div#helpModal {
      .modal-content {
        height: 100%;
        border-radius: 0;
        border: 0;
        width: 100% !important;
      }
      i {
        color: var(--color-secondary) !important;
        font-size: 24px;
        &.phone-container {
          left: 0px;
        }
        &.mail-container {
          left: 0px;
          font-size: 22px !important;
        }
      }
      .panel-body .row.justify-content-center {
        width: auto;
      }
      .justify-content-center {
        padding: 0;
        .col-9 {
          padding-left: 0;
          position: relative;
          right: 0px;
        }
      }

      .panel-body {
        margin-top: 30px;
        i {
          font-size: 20px;
          position: relative;
          right: 7px;
          top: 2px;
        }
      }
      .col-9 a {
        font-weight: 200 !important;
        font-family: var(--font-secondary);
        font-size: 20px;
        line-height: 20px;
      }
      .contact-team {
        margin-bottom: 42px;
        margin-top: 32px;
        font-size: 20px;
        padding: 0 32px;
        line-height: 26px;
      }
      hr {
        margin-top: 25px;
        width: 98%;
      }
      button.close {
        position: relative;
        top: 22px;
        right: 25px;
        font-weight: lighter;
      }
      .panel-profile-img {
        max-width: 78px !important;
        margin-top: 18px !important;
        margin-bottom: 5px !important;
        border: none !important;
        border-radius: 100% !important;
        box-shadow: none !important;
        vertical-align: middle !important;
        padding: 0px 5px !important;
        margin-left: 17px !important;
      }
      .schedule-call {
        margin: auto !important;
        margin-top: 17px !important;
        width: 93%;
        left: 0px;
        bottom: 0;
      }
      .panel-top p {
        max-width: unset;
        width: calc(100% + 20px);
        margin-left: 0px;
        margin-top: 7px;
      }

      .help .panel {
        border-radius: 0 !important;
        margin: auto !important;
        width: 100% !important;
        height: 100% !important;
        border: 0 !important;
        a {
          text-decoration: underline;
        }
      }
      position: fixed;
      bottom: 0 !important;
      right: 0 !important;
      z-index: 10040;
      width: 100% !important;
      display: block;
      padding-right: 15px !important;
      padding: 0 !important;
      margin: 0 !important;
      border-radius: 0 !important;
      height: 100vh !important;
    }
    button.close {
      display: block;
    }
  }
  .list-group.landing {
    margin: 0 auto !important;
    width: 98%;
    max-width: 350px;
    a {
      padding: 10px 15px;
      font-size: 17px;
    }
  }
  .item-title {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .landing-page-separator {
    width: calc(100% - 50px) !important;
  }
  div.faq-section {
    max-width: calc(100% - 50px) !important;
    .benefits-container {
      margin: 0 auto !important;
      padding: 0 !important;
    }
  }
  .process-container {
    div.item-container {
      margin-bottom: 56px;
      padding: 0 24pt;
    }
    .join-now {
      width: 100% !important;
    }
  }
  p.item-description {
    margin-bottom: 0;
  }
  div.row.first {
    margin-bottom: 0 !important;
  }
  .title-container {
    padding: 0;
    div {
      width: 71.5%;
      margin: auto !important;
    }
  }
  .list-container {
    padding: 0 8px !important;
  }
  .list-group a svg {
    position: absolute;
    top: 30% !important;
  }
  .jumbotron {
    padding-top: 62px !important;
    min-height: unset !important;
    padding-left: 12px;
    padding-bottom: 70px;
    .col-md-6 {
      padding-left: 8px;
    }
    .title-container > div {
      padding-top: 0 !important;
      width: 80%;
    }
    h1 {
      font-size: 49px !important;
      line-height: 63px !important;
      margin-bottom: 20px !important;
      margin-right: 8px !important;
      margin-top: 5px;
      text-align: center;
    }
    p {
      font-size: 24px !important;
      letter-spacing: 0.9px;
      line-height: 35px !important;
      padding: 2px;
      margin-bottom: 40px;
    }
  }
  .faq-section {
    h2 {
      &.benefits-title {
        color: var(--color-primary);
        font-family: var(--font-primary);
        font-size: 36px;
        line-height: 60px;
        width: 100%;
        margin-bottom: 56px;
        text-align: center;
        margin-top: 5px;
      }
    }
    .card-header {
      padding-top: 24px !important;
      padding-bottom: 6px !important;
      a h5 {
        font-size: 16.2px !important;
        line-height: 22px !important;
        max-width: 90%;
        padding: 0.5rem;
      }
    }
    .join-now {
      margin-top: 25px;
      width: 100% !important;
    }
    svg {
      position: relative;
      top: -15px !important;
      left: 10% !important;
    }
  }
  .benefits-section {
    padding: 56px 24px !important;
    padding-bottom: 52px !important;
    .benefits-title {
      font-size: 35px;
      line-height: 1.2 !important;
      width: 100%;
      text-align: center;
      margin-bottom: 32px;
    }
    .benefits-info {
      &.info-desktop {
        display: none;
      }
      &.info-mobile {
        display: block;
      }
      text-align: center;
      width: 100%;
      font-size: 20px !important;
      height: auto;
      padding: 20px 12px 32px 12px;
      padding-bottom: 15px;
      margin: auto;
      line-height: 30px;
      letter-spacing: 0.7px;
      margin-bottom: 32px;
    }
    .benefits-container {
      ul {
        margin-bottom: 32px !important;
      }
      li {
        font-size: 20px;
        margin-bottom: 15px !important;
        padding-right: 22px;
        line-height: 1.5;
      }
    }
    .join-now {
      margin-top: 25px;
      width: 100% !important;
    }
  }
  .navbar-brand {
    img {
      width: 189px;
      height: 56px;
    }
  }
  .landing-page-separator {
    margin-bottom: 45px !important;
  }
  .process-title {
    margin-left: 23px !important;
    margin-top: 3px !important;
    font-size: 36px !important;
    margin-bottom: 52px !important;
  }
  div.process-container .col-12 .row.second {
    padding: 0 !important;
  }
}

.benefits-section {
  padding: 120px 97.5px;
}
.benefits-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  width: 125px;
  margin-bottom: 64px;
}
.benefits-info {
  &.info-mobile {
    display: none;
  }
  height: 176px;
  width: 222px;
  border-radius: 8px;
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  padding: 20px 24px 32px 24px;
  line-height: 32px;
}
.join-now {
  &:hover {
    background-color: var(--color-secondary-light);
    border-color: var(--color-secondary-light);
  }
}

.benefits-container {
  ul {
    padding-left: 34px;
    padding-top: 5px;
    margin-bottom: 64px;
    li {
      color: var(--color-primary);
      font-family: var(--font-secondary);
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }
  img {
    width: 100%;
  }

  .benefits-container {
    margin-left: 14px !important;
  }
  .landing-page-support {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
    margin-top: 56px !important;
    h2 {
      font-size: 35px !important;
      font-weight: normal;
    }
    h3 {
      font-size: 21px !important;
      padding: 0 8px !important;
      margin-bottom: 20px !important;
    }
    h4 {
      font-size: 20px !important;
    }
    height: 56px;
    padding: 16px 0;
    width: 183px;
    border: 2px solid var(--color-secondary);
    border-radius: 50px;
    color: white;
    text-transform: uppercase;
    text-decoration: none !important;
    background-color: var(--color-secondary);
    font-family: var(--font-primary);
    font-size: 20px;
    letter-spacing: 1.6px;
    line-height: 24px;
    text-align: center;
    display: block;
    margin: auto;
    margin-left: 34px;
  }
}

.landing-page-separator {
  height: 1px;
  width: 57.67%;
  background-color: rgba(27, 54, 99, 0.12);
  margin-bottom: 120px;
  border: none;
}
.process-container {
  .process-title {
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 80px;
    text-align: center;
  }
  .item-container {
    .item-body {
      .item-title {
        color: var(--color-primary);
        max-width: 304px;
        font-family: var(--font-secondary);
        font-size: 24px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        text-transform: capitalize;
        margin: auto;
      }
      .item-description {
        color: var(--color-primary);
        font-family: var(--font-secondary);
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        max-width: 304px;
        margin: auto;
      }
    }
  }
  .row.first {
    margin-bottom: 45px;
    padding: 0 60px;
  }
  .row.second {
    padding: 0 20%;
    padding-bottom: 147px !important;
  }
}
//END RESPONSIVENSS LANDING PAGE
.benefits-section {
  padding: 97px 90px;
}
.benefits-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  width: 125px;
  margin-bottom: 64px;
}
.benefits-container {
  ul {
    padding-left: 34px;
    padding-top: 5px;
    margin-bottom: 64px;
    li {
      color: var(--color-primary);
      font-family: var(--font-secondary);
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }
  .join-now {
    &:hover {
      background-color: var(--color-secondary-light);
      border-color: var(--color-secondary-light);
    }
    height: 56px;
    padding: 16px 0;
    width: 183px;
    border: 2px solid var(--color-secondary);
    border-radius: 50px;
    color: white;
    text-transform: uppercase;
    text-decoration: none !important;
    background-color: var(--color-secondary);
    font-family: var(--font-primary);
    font-size: 20px;
    letter-spacing: 1.6px;
    line-height: 24px;
    text-align: center;
    display: block;
  }
}

.landing-page-separator {
  height: 1px;
  width: 57.67%;
  background-color: rgba(27, 54, 99, 0.12);
  margin-bottom: 120px;
}
.process-container {
  .process-title {
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 80px;
    text-align: center;
  }
  .item-container {
    .item-body {
      .item-title {
        color: var(--color-primary);
        font-family: var(--font-secondary);
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        text-align: center;
        margin-bottom: 15px;
        text-transform: capitalize;
      }
      .item-description {
        color: var(--color-primary);
        font-family: var(--font-secondary);
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
  .row.first {
    margin-bottom: 64px;
  }
  .row.second {
    padding: 0 16.25%;
  }
}
.faq-section {
  max-width: 57.67% !important;
  .benefits-container {
    padding-right: 0px;
    padding-left: 100px;
  }
  .accordion {
    margin-top: -25px;
    margin-bottom: 64px;
  }
  .card-header {
    padding-right: 0;
    a,
    h5 {
      color: var(--color-primary);
      font-family: var(--font-secondary);
      font-size: 24px;
      font-weight: bold;
      letter-spacing: normal;
      line-height: 32px;
      text-decoration: none !important;
      padding: 0.5rem;
    }
    .collapsed {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    svg {
      float: right;
      margin-top: 5px;
    }
  }
  .card {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(27, 54, 99, 0.24);
    padding-bottom: 1px;
  }
  .card-body {
    padding-left: 0;
    padding-top: 13px;
    h3 {
      color: var(--color-primary);
      font-family: var(--font-secondary);
      font-size: 16px;
      margin-bottom: 15px;
      line-height: 24px;
    }
    ul {
      li {
        margin-bottom: -5px;
      }
    }
    a {
      color: #4fa7d3;
      font-family: var(--font-secondary);
      font-weight: lighter;
      text-decoration: underline;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .card-header {
    background: white;
    border: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 0;
    letter-spacing: 0.4px;
  }
}
.landing-page-support {
  padding-top: 88px !important;
  padding-bottom: 56px !important;
  margin-top: 120px !important;
  h2 {
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 31px;
  }
  h3 {
    color: var(--color-primary);
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
    margin-bottom: 48px;
  }
  h4 a {
    color: var(--color-primary);
    font-family: var(--font-secondary);
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
}
//END RESPONSIVENSS LANDING PAGE
.benefits-section {
  padding: 97px 90px;
}
.benefits-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  width: 125px;
  margin-bottom: 64px;
}
.benefits-container {
  ul {
    padding-left: 34px;
    padding-top: 5px;
    margin-bottom: 64px;
    li {
      color: var(--color-primary);
      font-family: var(--font-secondary);
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
    }
  }
}
.join-now-container {
  margin: 64px 0;
}
button.join-now {
  cursor: pointer;
  height: 56px;
  text-decoration: none !important;
  padding: 16px;
  padding-inline: 32px;
  margin: auto;
  border: 2px solid var(--color-secondary);
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  text-decoration: none !important;
  background-color: var(--color-secondary);
  font-family: var(--font-primary);
  font-size: 20px;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-align: center;
  display: block;
}
.landing-page-separator {
  height: 1px;
  width: 57.67%;
  background-color: rgba(27, 54, 99, 0.12);
  margin-bottom: 120px;
}
.process-container {
  .process-title {
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 80px;
    text-align: center;
  }
  .item-container {
    .item-body {
      .item-title {
        color: var(--color-primary);
        font-family: var(--font-secondary);
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        text-align: center;
        margin-bottom: 15px;
        text-transform: capitalize;
      }
      .item-description {
        color: var(--color-primary);
        font-family: var(--font-secondary);
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
    .item-icon-container {
      height: 96px;
      width: 96px;
      margin: auto;
      border-radius: 100%;
      color: var(--color-secondary);
      text-align: center;
      margin-bottom: 30px;
      svg {
        font-size: 24px;
        margin-top: 24px;
      }
    }
  }
  .row.first {
    margin-bottom: 64px;
  }
  .row.second {
    padding: 0 21%;
  }
  .item-container {
    padding: 0 13px;
  }
}
.faq-section {
  .accordion {
    margin-top: -25px;
    margin-bottom: 64px;
  }
  .card-header {
    padding-right: 0;
    a,
    h5 {
      color: var(--color-primary);
      font-family: var(--font-secondary);
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      text-decoration: none !important;
    }
    .collapsed {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    svg {
      float: right;
      margin-top: 5px;
    }
  }
  .card {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(27, 54, 99, 0.24);
  }
  .card-body {
    padding-left: 0;
    padding-top: 13px;
    h3 {
      color: var(--color-primary);
      font-family: var(--font-secondary);
      font-size: 16px;
      margin-bottom: 15px;
      line-height: 24px;
    }
    ul {
      li {
        margin-bottom: -5px;
      }
    }
    a {
      color: #4fa7d3;
      font-family: var(--font-secondary);
      font-weight: lighter;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .card-header {
    background: white;
    border: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 0;
    letter-spacing: 0.4px;
  }
}
.landing-page-support {
  padding-top: 88px;
  padding-bottom: 80px;
  h2 {
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 31px !important;
    font-weight: normal;
  }
  h3 {
    color: var(--color-primary);
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
    margin-bottom: 48px;
  }
  h4 {
    color: var(--color-primary);
    font-family: var(--font-secondary);
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

  animation-delay: 500ms;
}

.policy-content {
  overflow-y: auto;
  height: calc(100vh - 249px);
}

.modal-content {
  margin: 0 auto;
  width: 588px;
}
@media all and (max-width: 768px) {
  .modal-content {
    width: 100%;
  }
}
.signature-modal-body canvas {
  height: 200px;
}
.profile-change-password {
  clear: both;
}
#medfusion-connect-modal {
  button.close {
    position: absolute;
    right: 0px;
    top: -2px;
    display: block !important;
    outline: none;
    z-index: 1000;
  }
  #createConnectionContent {
    padding-top: 0;
    overflow-y: auto !important;
  }
  #mfButtonHolder {
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #ccc;
  }
  .mf-modal .modal-content .modal-header .header-bottom-border {
    width: 100%;
  }
  .mf-modal .modal-content .modal-header .close {
    margin-top: -15px;
  }
  #mfConnectBack {
    span {
      position: relative;
      bottom: 1px;
    }
  }
}
.auth-sidebar-container {
  ul {
    float: left;
    width: 272px;
    list-style: none;
    padding: 0;
    border-right: 1px solid #eee;
    overflow: auto;
    margin: 0;
    height: 803px;

    li {
      padding: 24px;
      color: #4a4a4a;
      font-family: var(--font-secondary);
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      border-bottom: 1px solid #eee;
      &:hover,
      &.active {
        background-color: rgba(245, 136, 32, 0.1);
        font-family: var(--font-secondary);
        font-size: 16px;
        cursor: pointer;
        font-weight: bold;
        line-height: 24px;
        color: var(--color-secondary);
      }
      &.active {
        border-right: 4px solid var(--color-secondary);
      }
    }
  }
}
@media all and (max-width: 615px) {
  #medfusion-connect-modal {
    width: 100%;
    .modal-content {
      width: 100%;
      padding: 30px 0 !important;
    }
    @supports (-webkit-overflow-scrolling: touch) {
      button.close {
        right: 35px;
        top: 30px;
      }
    }
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    #medfusion-connect-modal #mfButtonHolder button.close {
      right: 35px;
      top: 30px;
    }
  }
}
.timespan {
  display: block;
}
.date-dropdown > .show {
  max-height: 210px !important;
  overflow: auto !important;
  width: 95% !important;
}

.shr-container {
  min-height: 500px !important;
  .records-container-loading {
    position: relative;
    left: calc(50% - 90px);
    margin: auto 0;
    width: 150px;
    picture img {
      width: 100%;
      height: 100%;
    }
  }
  .shr-inner-container {
    min-height: 500px !important;
    button.dropdown-item {
      outline: none;
      cursor: pointer;
    }
  }
  .search-icon {
    position: absolute;
    top: 5px;
    left: 10px;
  }
  .input-container {
    top: 0;
  }
}
.record-grid {
  .records-container-loading {
    position: relative;
    left: calc(50% - 90px);
    margin-top: 20px;
  }
}
.records-title .sort-by {
  & > div {
    display: inline-block;
  }
  button {
    position: relative;
  }
  button.sort-by-btn:after {
    position: absolute;
    right: 9px;
    top: 15px;
    bottom: 12px;
    left: unset;
  }
  .title-dropdown {
    left: 41% !important;
  }
  .dropdown-menu.show {
    width: 108px;
    top: -10px !important;
  }
  label {
    bottom: 0px !important;
  }
}
.records-title {
  .sort-by {
    & > div.show {
      position: relative;
    }
    .dropdown-menu.show {
      left: 0 !important;
      top: -10px !important;
    }
  }
}
@media all and (max-width: 700px) {
  .shr-inner-container {
    .dashboard-item-banner .actions-container.filter {
      float: none !important;
      width: 60%;
    }
    .sort-by-btn {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .resources-list {
    .container {
      div {
        display: block;
        &.col-4 {
          width: 100%;
          text-align: center;
          max-width: unset;
          margin-top: 10px;
          img {
            float: none !important;
          }
        }
        &.col-7 {
          width: 100%;
          margin-top: 20px;
          max-width: unset;
        }
        &.col-1 {
          display: none;
        }
      }
    }
  }
}
.resources-list {
  .container {
    div {
      &.col-4 {
        text-align: center;
      }
    }
  }
}
.profile-container {
  width: 100%;
  table td {
    word-break: break-all;
  }
}
picture.loading-logo-container {
  text-align: center;
  img {
    width: 250px;
    height: auto;
    max-width: unset;
    top: -100px;
  }
}

.consent-signature {
  width: auto;
  height: calc(100% - 56px);
  max-width: 50vw;
}

@media all and (max-width: 615px) {
  .signer-info {
    float: none !important;
    margin: 20px auto;
    width: calc(100% - 50px);
  }
  .sign-badge-container > img {
    margin-left: 40px !important;
  }
}
.auth-content {
  width: 100%;
  display: inline-block;
  padding: 31px 48px;
  padding-bottom: 0;
  max-height: 720px;
  overflow: auto;
  color: rgba(0, 0, 0, 0.87);
  background: white;
  font-family: var(--font-secondary);
  font-size: 20px;
  line-height: 35px;
  div {
    max-width: 686px;
    margin: 0 auto;
  }
  h5 {
    color: #0d192d;
    font-family: var(--font-secondary);
    font-size: 34px;
    font-weight: bold;
    line-height: 40px;
    &.underline {
      font-size: 25px;
    }
  }
  button {
    margin: 24px 0;
  }
  button.btn.sign-authorization {
    height: 56px;
    width: 238px;
    border: 2px solid var(--color-secondary);
    border-radius: 28px;
    background-color: var(--color-secondary);

    color: #ffffff;
    font-family: var(--font-secondary);
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.6px;
    line-height: 24px;
    text-align: center;
    &:hover {
      background-color: var(--color-secondary-light);
    }
    &.click-to-sign {
      width: 294px;
    }
  }
  button.btn.download-pdf {
    height: 60px;
    width: auto;
    padding: 16px 24px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #ffffff;
    color: #000000;
    font-family: var(--font-secondary);
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    max-width: 100%;
    white-space: normal;
    height: auto;
    min-height: 60px;
    &:hover {
      background: #eee;
    }
  }
  .signature-container {
    height: 251px;
    width: 686px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #ffffff;
    .timestamp-container {
      color: rgba(0, 0, 0, 0.87);
      font-family: var(--font-secondary);
      font-size: 13px;
      line-height: 20px;
      height: 56px;
      padding: 16px 18px;
      border-top: 1px solid #e4e4e4;
    }
  }
}
.row.justify-content-center {
  clear: both;
}
.auth-panel-container {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background: white;
}
.auth-footer-container {
  clear: both;
  border-top: 1px solid #eee;
  padding: 24px 0 24px 183px;
  .bottom {
    .left {
      float: left;
      color: var(--color-secondary);
      font-family: var(--font-secondary);
      font-size: 16px;
      line-height: 24px;
      margin-right: 16px;
      span {
        position: relative;
        bottom: 5px;
      }
      i {
        position: relative;

        margin-right: 16px;
        font-size: 24px;
      }
    }
    .right {
      & > div {
        position: relative;
        left: 10px;
        top: 0px;
        display: inline-block;
        color: #9b9b9b;
        font-family: var(--font-secondary);
        font-size: 18px;
        line-height: 23px;
        font-weight: normal;

        &.contact-us {
          margin-left: -10px;
          color: rgba(0, 0, 0, 0.87);
          font-family: var(--font-secondary);
          font-size: 16px;
          line-height: 24px;
        }
        &.or {
          margin: 0 8px;
          color: rgba(0, 0, 0, 0.87);
          font-family: var(--font-secondary);
          font-size: 16px;
          line-height: 24px;
        }
        &.demo {
          a {
            color: var(--color-secondary) !important;
            font-family: var(--font-secondary);
            font-size: 16px;
            line-height: 24px;
            text-decoration: none;
          }
        }
      }
    }
  }
}
.header-banner {
  height: 335px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0.21);
  padding-inline: 48px;
  margin-bottom: 0;
  background-size: 100%;
  margin-top: 17px;
  .layer {
    background-color: rgba(0, 0, 0, 0.21);
    position: absolute;
    top: 0;
    z-index: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  h2 {
    max-width: 716px;
    color: #ffffff;
    font-family: var(--font-secondary);
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
    position: relative;
    top: 127px;
    letter-spacing: 0.8px;
  }
  svg {
    position: relative;
    top: 135px;
    color: white;
  }
}
.auth-nav-container {
  height: 70px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  position: relative;
  button {
    appearance: none;
    border: 0;
    height: 24px;
    padding: 0;
    color: var(--color-secondary);
    font-family: var(--font-secondary);
    font-size: 14px;
    text-transform: uppercase;
    margin: 24px 0;
    font-weight: bold;
    letter-spacing: 0.33px;
    line-height: 14px;
    text-align: center;
    outline: none;
    cursor: pointer;
    svg {
      margin: 0 24px;
    }
    span {
      background: white;
      &.back-btn-text {
        float: right;
        padding: 5px 0;

        span {
          position: relative;
          bottom: 7px;
        }
      }
      &.next-btn-text {
        float: left;
        padding: 5px 0;
        span {
          position: relative;
          bottom: 7px;
        }
      }
    }
    &.hidden {
      visibility: hidden;
    }
    &.visible {
      visibility: visible;
    }
    &.dropdown-toggle {
      height: 40px;
      max-width: fit-content;
      border: 1px solid #cccccc;
      border-radius: 20px;
      background-color: #ffffff;
      color: var(--color-secondary);
      font-family: var(--font-secondary);
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      text-transform: none;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 8px 30px 8px 16px;
      position: relative;
      text-align: left;
      margin-top: 16px;
      margin-bottom: 14px;
      &::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.2em solid;
        border-right: 0.2em solid transparent;
        border-bottom: 0;
        border-left: 0.2em solid transparent;
        position: absolute;
        right: 16px;
        color: #373a3c;
        width: -2px;
        top: 19px;
      }
    }
  }
  .dropdown-menu.show {
    height: 216px;
    max-width: fit-content;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 16px;
    overflow: auto;
    .dropdown-item {
      height: 24px;
      width: 100%;
      color: rgba(0, 0, 0, 0.87);
      font-family: var(--font-secondary);
      font-size: 16px !important;
      text-align: left;
      font-weight: bold;
      line-height: 24px;
      max-width: unset;
      margin: 8px 0 !important;
      text-transform: none;
      &.active {
        background: transparent;
        color: var(--color-secondary);
      }
      &:active {
        color: var(--color-secondary);
        background: transparent;
      }
      &.disabled {
        color: #bdd5ea !important;
      }
    }
  }
}
.auth-panel-desktop {
  display: block;
}
.auth-panel-mobile {
  display: none;
}
@media all and (min-width: 760px) and (max-width: 1050px) {
  .header-banner {
    background-size: cover !important;
    background-repeat: repeat-x;
  }
}
@media all and (max-width: 790px) {
  .auth-content div {
    max-height: unset;
  }
  .auth-footer-container .bottom .left {
    margin-bottom: 16px !important;
  }
  .auth-footer-container .bottom .right > div.or {
    display: inline-block;
    width: unset;
    position: relative;
    margin: 0px 5px !important;
  }
  .auth-footer-container .bottom .right > div.demo {
    display: inline-block;
    width: unset;
    position: relative;
  }
  .auth-footer-container {
    padding: 24px 57px;
    text-align: center;
    clear: both;
    border-top: 1px solid #e4e4e4;
    margin-top: 1px;
    font-size: 16px;
    div {
      float: none !important;
      margin: 0 !important;
    }
  }
  .consent-container {
    width: 100%;
  }
  .auth-content .signature-container {
    width: 100%;
  }
  .header-banner {
    padding-left: 23px;
    height: 281px;
    background-size: cover !important;
    background-position: 50% 65%;
    h2 {
      width: 316px;
      color: #ffffff;
      color: #ffffff;
      font-family: var(--font-secondary);
      font-size: 34px;
      line-height: 40px;
      top: 40px;
      font-weight: normal;
      margin-bottom: 24px;
    }
    svg {
      top: 289px;
      color: white;
      position: relative;
    }
  }
  .auth-panel-desktop {
    display: none;
  }
  .auth-panel-mobile {
    display: block;
    button.dropdown-toggle {
      &.mobile {
        color: var(--color-secondary);
        font-size: 16px;
        font-weight: bold;
        font-family: var(--font-secondary);
        font-weight: bold;
        line-height: 24px;
        float: left;
        width: 100%;
        background-color: #ffffff;
        padding: 16px 50px 16px 16px;
        border-bottom: 1px solid #e4e4e4;
        border-radius: none !important;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
      }
      &::after {
        position: absolute;
        right: 31px;
        bottom: 50%;
        color: #5f697a;
      }
    }
    div.dropdown-menu.filter-by.show {
      width: calc(100% - 30px) !important;
      margin-top: 0px;
      border-radius: 0;
      width: 96%;
    }
    button.btn.sign-authorization {
      font-size: 16px;
    }
    #auth-dropdown {
      max-width: 100% !important;
    }
  }

  .auth-content {
    width: 100%;
    padding: 33px;
    h5 {
      color: #0d192d;
      font-family: var(--font-secondary);
      font-size: 20px;
      font-weight: bold;
      line-height: 40px;
      margin-bottom: 22px;
      padding: 0 !important;
    }
    p,
    li,
    a {
      font-family: var(--font-secondary);
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 24px;
    }
  }
  .auth-nav-container {
    border-bottom: none;
    border-top: 1px solid #e4e4e4;
    background: white;
    position: relative;
    button {
      background: white;
      padding-top: 24px;
      padding-bottom: 47px;
      margin: 0;
      padding-top: 24px;
      height: 70px;
      padding-bottom: 24px;
      &.auth-back-btn {
        padding-right: 10px;
        width: 33%;
      }
      &.auth-next-btn {
        padding-left: 10px;
        border-left: 1px solid #e4e4e4;
        width: 33%;
      }
      span {
        bottom: 5px;
        &.back-btn-text {
          position: relative;
          width: 75px;
          span {
            position: relative;
            bottom: 7px;
          }
        }
        &.next-btn-text {
          position: relative;
          width: 75px;
          span {
            position: relative;
            bottom: 7px;
          }
        }
      }
      svg {
        margin-inline: 4px;
      }
    }
  }
  .sign-badge-container {
    margin-left: 0px !important;
  }
}
@media all and (min-width: 768px) and (max-width: 790px) {
  .sign-badge-container {
    margin-left: 70px !important;
  }
}
@media all and (max-width: 890px) {
  .sign-badge-container {
    margin-left: 100px;
    img {
      margin-left: 0 !important;
    }
    .signer-info {
      float: none;
      margin-left: 10px;
      width: 95%;
    }
    .timespan {
      width: 100%;
    }
  }
}
.authorizations-container-loading {
  position: relative;
  min-height: 500px !important;
  left: calc(50% - 90px);
  margin: auto 0;
  img.loading-logo {
    min-width: 200px;
    height: 170px;
    margin-top: 60px;
  }
}
.sign-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
  &:before {
    content: '';
    border: dotted 1px var(--color-primary);
    border-radius: 30px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }
  img {
    height: 100px;
    max-width: 100%;
  }
  div {
    position: absolute;
    left: 8%;
    font-size: 12px;
    font-weight: bolder;
    background: white;
    padding: 0px 10px;
  }
  .signed-by {
    top: -9px;
  }
  .consent-id {
    bottom: -9px;
  }
}
.step-divider {
  border-bottom: rgba(0, 0, 0, 0.05);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 50px;
  margin-bottom: 50px;
}
label[for='dxDate'] {
  display: block;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}
.show-invalid-feedback + .invalid-feedback {
  display: block;
}

.sticky-footer-section {
  min-height: 199px;
}

.sticky-footer {
  background-color: #fefefe;
  position: absolute;
  bottom: var(--footer-height);
  left: 0;
  right: 15px;
  overflow-y: hidden;
  box-shadow: 0 15px 10px 20px rgb(0 0 0 / 10%);
  &.collapsed {
    height: 190px;
  }
  &.expanded {
    height: calc(100vh - 117px);
    overflow-y: scroll;
  }
  .expand-button {
    position: absolute;
    top: 15px;
    right: 15px;
    outline: inherit;
    border: none;
    background: none;
    font-weight: bold;
    font-size: small;
    color: var(--color-primary);
    &:hover {
      font-weight: bolder;
      font-size: medium;
      cursor: pointer;
    }
  }
}

@media all and (min-width: 960px) {
  .sticky-footer {
    &.expanded {
      height: calc(100vh - 141px);
    }
  }
}

.locales-modal {
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  .modal-content {
    width: fit-content;
    height: fit-content;
    min-height: 300px;
    padding: 40px !important;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }

  .modal-title {
    font-weight: 400;
    font-size: 34px;
    line-height: 32px;
    text-align: center;
    color: var(--color-primary);
    padding-block: 8px;
    white-space: break-spaces;
  }

  .locales-list {
    display: flex;
    flex-direction: column;
    padding-block: 8px;
    gap: 8px;
  }

  .btn.locale-item {
    background-color: var(--color-secondary);
    color: var(--white);
    padding: 12px;
    border-radius: 24px;
  }
}
