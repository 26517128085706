.resources-header {
  font-size: 34px;
  font-weight: 300 !important;
  line-height: 40px;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 3px 3px 0 0;
  .record-title {
    min-width: fit-content;
  }
  .resource-filters {
    margin-right: 30px;
    width: fit-content;
    .dropdown-item {
      max-width: 100%;
    }
    .filter-category {
      color: black;
      display: inline-block;
      width: 118px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  span {
    color: white;
  }
}
.resources {
  &.featured {
    .media-body {
      padding-top: 20px;
    }
  }
  .media-body {
    padding-left: 15px;
  }
  .media-img {
    width: 180px;
    img {
      display: block;
      margin: 0 auto;
      max-width: 180px;
      padding: 10px;
      width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }
  h3 {
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }
  h4 {
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    padding-top: 8.8px;
  }
  span:first-of-type {
    width: fit-content;
    height: 100%;
    color: #f2f4f7;
    font-family: Roboto;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    background-color: #1b3763;
    border-radius: 15px;
    padding: 3px 10px;
    text-align: center;
    margin-right: 12.5px;
    display: inline-block;
  }
  span {
    height: 20px;
    width: 170px;
    color: #577399;
    font-family: Roboto;
    font-size: 13px;
    line-height: 20px;
  }
}

.resources.featured {
  .media-img {
    height: 219px;
    width: 333px;
  }
}

.carousel-item {
  a {
    padding-bottom: 40px;
  }
}
.carousel-indicators {
  justify-content: flex-start;
  li {
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-secondary-light);
  }
  .active {
    background-color: var(--color-secondary);
  }
}
@media (max-width: 767px) {
  .resources {
    .media-img {
      width: 100px;
      height: 100px;
      line-height: 97px;
    }
  }
}
