@import '../base/colors';

.card{
    background-color: #FFFFFF;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1), 0 2px 4px 0 rgba(0,0,0,0.05);

   &__title{
    color: #F0AD4E;
    font-family: "Helvetica Neue";
    font-size: 34px;
    font-weight: 300;
    line-height: 40px;
    margin: 0;
  }
   &__header{
    border-bottom: 1px solid #F1F1F1;
    margin-bottom: 48px;
    padding-bottom: 16px;
  }
   &__footer{
    border-top: 1px solid #F1F1F1;
    margin-top: 48px;
    padding: 47px 52px;
  }
}
