:root {
  --font-secondary: 'Overpass';
}

@import '../base/colors';
.onboarding {
  .dashboard-container {
    background-color: #fafaf9 !important;
    padding: 48px 16px 168px !important;
  }
  &__steps {
    margin: 0;
    padding: 0;
  }
  &__step {
    display: flex;
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .step {
    &__img {
      background-color: $neutral-white-color;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14),
        0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      height: 178px;
      flex: 0 0 316px;
      margin-right: 48px;
    }
    &__name {
      color: #f0ad4e;
      font-family: var(--font-secondary);
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      margin: 0 0 16px;
    }
    &__description {
      color: rgba(0, 0, 0, 0.87);
      font-family: var(--font-secondary);
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    .btn {
      align-items: center;
      display: flex;
      height: 56px;
      justify-content: center;
      outline: none;
      padding: 0;
    }
    .btn-get-started {
      color: rgba(0, 0, 0, 0.87);
      font-family: var(--font-secondary);
      font-size: 20px;
      font-weight: 500;
      margin-right: 19px;
    }
    .btn-search {
      background-color: #f0ad4e;
      border: 1px solid #eea236;
      border-radius: 5px;
      color: $neutral-white-color;
      font-family: var(--font-secondary);
      font-size: 18px;
      width: 272px;
    }
  }
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  left: 44%;
  top: 50%;
  div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: #f69343;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    &:nth-child(1) {
      left: 6px;
      animation-delay: -0.24s;
    }
    &:nth-child(2) {
      left: 26px;
      animation-delay: -0.12s;
    }
    &:nth-child(3) {
      left: 45px;
      animation-delay: 0;
    }
  }
}
.dashboard-container {
  min-height: calc(100vh - 185px);
}
@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}
@media all and (max-width: 762px) {
  .onboarding {
    &__step {
      display: block;
    }
    &__actions {
      display: block;
    }
    .step {
      &__img {
        margin-right: 0;
        margin-bottom: 25px;
      }
      &__info {
        text-align: center;
      }
    }
    .card__footer {
      padding: 0;
    }
    #mfConnectBtn {
      width: 100%;
    }
  }
}
