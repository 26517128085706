:root {
  --font-secondary: 'Overpass';
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-secondary);

}
