@charset "UTF-8";
:root {
  --color-primary: #1b3663;
  --color-secondary: #f78920;
  --color-secondary-light: #f78920;
  --font-primary: Fjalla One;
  --font-secondary: Overpass;
  --footer-height: 55px;
  --background-color: #fafaf9;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  margin-bottom: 100px;
}

#mainContainer {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
  scroll-behavior: smooth;
}

.react-datepicker__aria-live {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.routeContainer {
  height: 100%;
  width: 100%;
  position: relative;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.consent-style {
  font-family: var(--font-secondary);
  font-weight: 400;
}
.consent-style h5 {
  font-weight: bold;
}

@-moz-document url-prefix() {
  span.sidenav-title {
    position: relative;
    top: 1.5px;
  }
}
span.sidenav-title {
  margin-right: 10px;
}

.delete-item-modal .delete-modal .modal-content {
  font-family: var(--font-secondary);
  position: absolute !important;
  margin-left: calc(55% - 340px) !important;
  width: 60% !important;
  max-width: 588px !important;
  padding: 28px 24px 24px 24px !important;
}
.delete-item-modal .delete-modal .modal-content h2 {
  font-weight: 500;
}
.delete-item-modal .delete-modal div {
  letter-spacing: normal;
}
.delete-item-modal .delete-modal div .btn-container button {
  line-height: 1.5;
}
.delete-item-modal .delete-modal div .btn-container .btn-default {
  background: white;
  color: black;
}
.delete-item-modal .delete-modal div .btn-container .btn-default:hover {
  color: #000;
  background-color: #ddd;
  border-color: #eee;
}
.delete-item-modal .delete-modal div .btn-container .btn-primary {
  color: white;
}

.center-container {
  padding: 0 !important;
  margin: 0 auto !important;
}

.zero-margin {
  margin: 0 !important;
}

.sub-nav-container {
  height: 71px;
}

.table-responsive {
  word-break: break-word;
}
.table-responsive .py-2 ul {
  height: auto !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
  }
}
@media all and (max-width: 576px) {
  .inbox-button {
    margin-left: -14px;
  }
  .signer-info {
    width: 100% !important;
    float: none !important;
  }
  img.consent-signature {
    width: 50%;
  }
  .sign-badge-container img {
    margin: auto !important;
  }
  .consent-container-pdf > div {
    padding: 15px;
  }
}
@media all and (max-width: 516px) {
  div.dashboard-container .dashboard-item-banner .filter {
    float: none !important;
  }
  div.dashboard-container .dashboard-item-banner .filter__select button {
    width: 175px;
  }
  div.dashboard-container .dashboard-item-banner .filter .btn-create-user {
    display: none;
  }
  div.dashboard-container .dashboard-item-banner .filter.user-index-filter {
    margin-left: 0 !important;
    margin: 0 auto !important;
    width: 95%;
  }
  div.dashboard-container .dashboard-item-banner .filter:not(.actions-container) {
    margin-left: 20px;
  }
  div.dashboard-container .dashboard-item-banner .filter.actions-container {
    margin-left: 5px;
  }
}
@media all and (max-width: 577px) {
  div.filter.user-index-filter {
    padding-left: 6% !important;
  }
}
@media all and (max-width: 440px) {
  .delete-item-modal .delete-modal .modal-content {
    width: 50% !important;
    margin-left: 0;
  }
  .button-responsive-positioning {
    position: relative;
    top: 25px;
    right: 145px;
  }
}
.inbox-card-item-list button {
  background-color: transparent;
}

.delete-button button {
  background-color: transparent;
}
.delete-button button:focus {
  box-shadow: none;
}

.delete-item-modal .modal-content {
  height: auto !important;
  padding-bottom: 30px !important;
}
.delete-item-modal .modal-content .btn-container {
  margin-top: 20px;
}
.delete-item-modal .modal-content h2 {
  margin-bottom: 15px !important;
}

.App-title {
  font-size: 1.5em;
}

.sidebar-item-children-wrapper * {
  border-radius: 0 !important;
}

.sidebar-sticky {
  max-width: 95.5% !important;
}

.App-intro {
  font-size: large;
}

.btn-login {
  max-height: 48px;
}
.btn-login i {
  margin: 0 10px;
}

.pagination-items .page-link {
  font-size: 14px;
  color: #4e92df;
}
.pagination-items li.disabled {
  pointer-events: none !important;
}
.pagination-items li:hover {
  background: #eee;
}
.pagination-items li:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

#profileContainer .table {
  width: calc(100% - 10px);
  font-size: 16px;
}

.sub-nav {
  padding: 8px 0 0 0 !important;
  line-height: 1;
}

.capitalize {
  text-transform: capitalize !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Custom CSS */
.btn-monarc {
  background-color: rgb(100, 165, 207);
  border-color: rgb(100, 165, 207);
  color: white;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.ph-item.profile {
  border: none !important;
  padding: 0 !important;
  position: relative;
  right: 100%;
  margin: 0 !important;
}
.ph-item.profile div {
  margin: 0;
}

.bg-monarc-orange {
  background-color: var(--color-secondary-light) !important;
}

.text-monarc-orange {
  color: var(--color-secondary) !important;
}

.text-monarc-green {
  color: rgb(138, 197, 125) !important;
}

.text-monarc-purple {
  color: #a45e9e !important;
}

.btn-monarc-purple {
  background-color: white;
  border-color: #a45e9e;
  color: #a45e9e;
}

.btn-monarc-purple:hover,
.btn-monarc-purple:focus {
  border-color: white;
  background-color: #a45e9e;
  color: white;
}

.btn-monarc-purple:active,
.btn-monarc-purple:visited,
.btn-monarc-purple:active:focus,
.btn-monarc-purple:active:hover {
  border-color: #a45e9e;
  background-color: #a45e9e;
  color: white;
}

.btn-monarc-orange:hover,
.btn-monarc-orange:focus {
  border-color: var(--color-secondary-light);
  background-color: var(--color-secondary-light);
  color: white;
}

.btn-monarc-orange:active,
.btn-monarc-orange:visited,
.btn-monarc-orange:active:focus,
.btn-monarc-orange:active:hover {
  border-color: var(--color-secondary-light);
  background-color: var(--color-secondary-light);
  color: white;
}

.signer-info {
  width: calc(100% - 300px);
  float: right;
}

.sign-badge-container {
  margin-top: 50px;
}

.borderless td,
.borderless th {
  border: none;
  padding: none;
}

/* Landing Page */
.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

footer.footer {
  min-height: var(--footer-height);
  padding: 5px;
  bottom: 0px;
  top: auto;
}

.jumbotron.with-link {
  height: auto;
  padding: 145px 0 !important;
}
.jumbotron.with-link p {
  margin: 0 !important;
  color: #ffffff;
  font-family: var(--font-secondary);
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 45px !important;
}
.jumbotron.with-link h1 {
  margin-bottom: 25px;
}

.jumbotron-join-button-container button.join-now {
  padding: 20px 45px;
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  height: 64px;
  font-family: var(--font-primary);
  min-width: 200px;
  width: auto;
}

.jumbotron-join-button-container {
  display: block;
}

.landing-page-process-container {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.landing-page-paragraph h1 {
  font-weight: 800;
  font-size: 25px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-paragraph h2 {
  font-weight: 800;
  font-size: 20px;
  text-align: left;
  color: var(--color-primary);
}

.landing-page-paragraph h3 {
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  color: var(--color-primary);
}

.landing-page-paragraph hr {
  border-color: white;
}

.landing-page-paragraph p {
  margin: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-process-container {
  background-color: rgb(100, 165, 207);
  color: white;
  padding-top: 40px;
  padding-bottom: 40px;
}

.landing-page-process-container h2 {
  font-weight: 800;
  color: rgb(54, 74, 79);
  font-size: 25px;
}

.process-list {
  margin-top: 25px;
  margin-bottom: 25px;
}

.process-item .media-body h5 {
  font-weight: 500;
  color: rgb(54, 74, 79);
  font-size: 16px;
  margin-bottom: 0;
}

.process-item .media-body p {
  font-weight: 200;
  color: rgb(54, 74, 79);
  font-size: 15px;
}

.landing-page-support {
  margin-top: 50px;
  margin-bottom: 0px;
}
.landing-page-support .schedule-call {
  display: inline-block;
  text-decoration: none;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  margin-top: 1rem;
  border: 2px solid var(--color-secondary);
  border-radius: 50px;
  background-color: var(--color-secondary);
  color: #ffffff;
  font-family: var(--font-primary);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.28px;
  line-height: 24px;
}

.landing-page-support h2 {
  font-weight: normal;
  font-size: 25px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-support h3 {
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--color-primary);
}

.landing-page-support p {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  color: var(--color-primary);
  margin-bottom: 50px;
}

.landing-page-support-item-icon {
  font-size: 50px;
  color: var(--color-primary);
}

/********/
/*** Registration ***/
.policies-page {
  background: var(--background-color);
  height: 100%;
}
.policies-page .container {
  background: white;
  width: 480px;
  min-height: 30vh;
  max-width: calc(100% - 20px) !important;
  margin-inline: auto;
  margin-block: 24px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding-block: 24px;
}
.policies-page h2 {
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
}
.policies-page h5 {
  font-family: var(--font-primary);
  text-align: center;
  padding-block: 24px;
}
.policies-page .policy-input input[type=checkbox]:checked {
  accent-color: var(--color-primary);
}
.policies-page .btn.btn-primary {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  margin-top: 24px;
}
.policies-page .policy-modal .modal-content {
  min-width: 640px;
  height: 100%;
}
.policies-page .policy-modal .modal-content .modal-header {
  justify-content: center;
}
.policies-page .policy-modal .modal-content .modal-header .modal-title {
  color: var(--color-secondary);
}
.policies-page .policy-modal .modal-content .modal-footer .btn.btn-primary {
  margin: 0;
  padding-inline: 0;
  width: 38px;
}

.register-v2 {
  background: var(--background-color);
}
.register-v2 * {
  outline: none;
}
.register-v2 .custom-select.pull-up > div:nth-child(3) {
  margin-top: -5px !important;
}
.register-v2 .custom-select:not(.locale-picker-select) div {
  border-color: #ced4da;
  box-shadow: unset;
  justify-content: center;
}
.register-v2 .custom-select:not(.locale-picker-select) div > .css-xp4uvy {
  padding-left: 48.25%;
}
.register-v2 .container {
  background: white;
  width: 960px;
  min-height: 590px;
  height: 100%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}
.register-v2 .container * {
  text-align: center;
}
.register-v2 .container h2 {
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
  padding-top: 48px;
}
.register-v2 .container h3 {
  color: #4a4a4a;
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 15px;
}
.register-v2 .container .options-container {
  min-height: 187px;
}
.register-v2 .container .option-card {
  box-sizing: border-box;
  height: 174px;
  width: 234px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 0 0 #ececec;
  display: inline-block;
  font-family: var(--font-secondary);
  color: #9b9b9b;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  position: relative;
  top: -5px;
  cursor: pointer;
  padding-top: 25px;
}
.register-v2 .container .option-card:not(:first-child):not(:last-child) {
  margin: 0 12px;
}
.register-v2 .container .option-card.active {
  color: var(--color-secondary);
  height: 180px;
  width: 240px;
  box-sizing: border-box;
  border: 4px solid var(--color-secondary);
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: inset 0 1px 0 0 #ececec;
}
.register-v2 .container .option-card .options-label {
  padding: 0 35px;
  position: absolute;
  top: 80px;
  left: -7px;
  width: 245px;
}
.register-v2 .container .step2 .form-input-container {
  margin-top: 35px !important;
}
.register-v2 .container .step2 .qualification-message {
  margin-top: 48px;
  font-size: 24px;
  color: var(--color-primary);
}
.register-v2 .container .loading-options {
  font-size: 50px;
  margin-top: 45px;
}
.register-v2 .form-input-container {
  clear: both;
  margin-top: 25px;
  padding: 0 91px;
}
.register-v2 .form-input-container label {
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 6px;
}
.register-v2 .form-input-container input {
  height: 45px;
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 22px;
  color: #999999;
}
.register-v2 .form-input-container .custom-select {
  height: 58px;
}
.register-v2 .footer {
  position: absolute;
  height: 86px;
  background: white;
  margin-top: 2px;
  right: 0;
}
.register-v2 .footer .buttons-container {
  display: inline-block;
  width: 100%;
}
.register-v2 .footer .buttons-container > * {
  display: inline-block;
}
.register-v2 .footer .buttons-container button {
  outline: none;
}
.register-v2 .footer .buttons-container button.disabled {
  background: #eee !important;
  border: none !important;
  color: #ccc !important;
}
.register-v2 .footer .buttons-container .btn-back {
  float: left;
}
.register-v2 .footer .buttons-container .btn-back:hover {
  background-color: #eee;
}
.register-v2 .footer .buttons-container .btn-next {
  float: right;
}
.register-v2 .footer .buttons-container .btn-next-container {
  width: 19%;
  float: right;
  margin-right: 25px;
  margin-top: 25px;
}
.register-v2 .footer .buttons-container .btn-next-container button {
  height: 38px;
  width: 111px;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  background-color: var(--color-secondary);
  color: #ffffff;
  font-weight: normal;
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}
.register-v2 .footer .buttons-container .btn-next-container button:hover {
  background-color: #f9a04b;
}
.register-v2 .footer .buttons-container .btn-back-container {
  width: 20%;
  float: left;
  margin-left: 25px;
  margin-top: 25px;
}
.register-v2 .footer .buttons-container .btn-back-container button {
  height: 38px;
  width: 111px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  color: #373a3c;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  cursor: pointer;
}
.register-v2 .footer .buttons-container .steps-container {
  list-style: none;
  width: 42%;
  padding: 0;
  margin-top: 30px;
}
.register-v2 .footer .buttons-container .steps-container li {
  display: inline-block;
  margin-right: 8px;
}
.register-v2 .footer .buttons-container .steps-container li div {
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background: var(--color-secondary);
  opacity: 0.3;
}
.register-v2 .footer .buttons-container .steps-container li.active div {
  opacity: 1;
}
.register-v2.success .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  background-color: #ffffff;
  padding: 0;
}
.register-v2.success h2 {
  padding: 16px;
}

@media all and (max-width: 485px) {
  div.register-v2 {
    padding-bottom: 25px !important;
  }
}
@media all and (max-height: 780px) and (max-width: 767px) {
  div.register-v2 {
    padding-bottom: calc(100% + 210px);
  }
}
@media all and (max-width: 961px) {
  .policies-page .policy-modal.backdrop {
    padding: 20px;
  }
  .policies-page .policy-modal .modal-content {
    min-width: 275px;
    padding: 12px 8px 8px 8px;
  }
  .policies-page .policy-modal .modal-content .modal-header {
    padding: 0.5rem;
  }
  .policies-page .policy-modal .modal-content .modal-header .modal-title {
    padding-block: 12px;
  }
  .policies-page .policy-modal .modal-content .modal-body ul {
    padding-inline-start: 20px;
  }
  .policies-page .policy-modal .modal-content .modal-footer {
    padding: 0.5rem;
  }
  div.register-v2 .register-nav {
    display: block;
    text-align: center;
  }
  div.register-v2 .register-nav a {
    margin: 0;
  }
  div.register-v2 div.container {
    background: white;
    width: 70%;
    padding: 50px 10%;
    padding-bottom: 15%;
    min-height: 658px;
    height: auto;
    margin-bottom: 25px;
  }
  div.register-v2 div.container .form-input-container {
    padding: 0;
  }
  div.register-v2 div.container div.option-card {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  div.register-v2 div.container div.option-card:not(:first-child):not(:last-child) {
    margin: 10px 0;
  }
  div.register-v2 div.container div.option-card p.options-label {
    width: 100%;
    position: relative;
    top: 0;
  }
  div.register-v2 .footer div.buttons-container .steps-container {
    padding: 0;
    width: 25%;
  }
}
.registration-container {
  background-color: rgb(100, 165, 207);
  position: absolute;
  z-index: 10;
  /*  height: 100%;
  */
}

#register-form {
  position: relative;
}

#register-form fieldset {
  background: white;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;
  margin-bottom: 25px;
  /*stacking fieldsets above each other*/
  position: relative;
}

.registration-instructions {
  margin-bottom: 25px;
}

.registration-instructions h1,
.registration-instructions h2,
.registration-instructions h3 {
  font-weight: 200;
  margin-bottom: 25px;
  text-align: center;
}

/*#register-form fieldset:not(:first-of-type) {
    display: none;
}*/
#register-form fieldset {
  display: none;
}

#register-form fieldset.active {
  display: block;
}

/*#register-form button {
    width: 100px;
    font-weight: bold;
    color: rgb(114,164,203);
    border-color: rgb(114,164,203);
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}
#register-form button:hover, #register-form button:focus {
    color: white;
}*/
/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 23%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 11px;
  z-index: -1;
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: var(--color-primary);
  color: white;
}

/********/
.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}

.bd-callout-info {
  border-left-color: rgb(100, 165, 207);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--color-secondary);
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link a {
  color: var(--color-secondary);
}

a {
  color: rgb(100, 165, 207);
}

.btn-mbn-blue {
  background-color: rgb(100, 165, 207);
  color: white;
}

.signature-modal-body img {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.signature-modal-body canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.signature-modal-body {
  height: 250px;
}

.nav-item span {
  cursor: pointer;
}

.menu:not(.sidebar) .active span {
  color: rgb(99, 165, 207) !important;
}

.menu .nav-item a div span {
  text-transform: unset !important;
}

.menu .sidebar-header {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.75);
}

.nav-item .row .col-9 {
  font-family: sans-serif !important;
}

/* .menu .nav-item {
  margin: 0;
  height: auto;
  width: 100%;
  padding: 0;
} */
.menu .nav-item a div span {
  text-transform: uppercase;
  font-family: var(--font-secondary);
  cursor: pointer;
  font-size: 13px;
  line-height: 19px;
  margin: 13px 0 13px 16px;
}

.nav-item .row div {
  font-weight: bold !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.dashboard-container > div {
  width: calc(100% - 2rem);
}

.menu .nav-item a {
  color: #818a91;
}

.menu .nav-item .active {
  color: rgb(255, 255, 255);
}

.menu .nav-item:hover {
  color: rgb(255, 255, 255);
  text-decoration: none !important;
}

.menu .nav-item a:hover {
  color: rgb(255, 255, 255);
  text-decoration: none !important;
}

.menu .nav-item a div i {
  color: inherit;
  text-decoration: inherit;
  float: right;
  margin: 13px 16px 13px 0;
}

.menu .nav-item .sidebar-item-children-wrapper {
  padding-left: 16px;
}

.menu .nav-link .active {
  color: #007bff;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.menu:not(.sidebar) .nav-item {
  padding-top: 8px;
}

.menu .dropdown-divider {
  height: 1px;
  width: 100%;
  background-color: #46464f;
  margin: 0;
  border: 0;
}

.dashboard-container {
  background-color: rgb(250, 250, 249);
  overflow: auto;
  padding: 0;
}
.dashboard-container p.info-container {
  max-height: 100px;
  overflow: auto !important;
}
.dashboard-container p.col-10 {
  margin-top: 55px;
}
.dashboard-container p.mf-connect {
  min-height: 60px;
}
.dashboard-container p.mf-connect .mf-connect-btn:hover {
  cursor: pointer;
}
.dashboard-container .import-records {
  width: 100%;
  margin: 1rem auto !important;
}
.dashboard-container h1:not(.shr-title, .import-records) {
  float: left;
  color: var(--color-secondary);
  font-weight: 300;
}

.dashboard-container > .dashboard-item {
  border-radius: 4px;
}
.dashboard-container .dashboard-item-banner {
  width: 100%;
  height: auto !important;
}
.dashboard-container .dashboard-item-banner .filter {
  float: right !important;
}
.dashboard-container .dashboard-item-banner .filter__select {
  color: white;
}
.dashboard-container .dashboard-item-banner h2 {
  color: white;
  width: auto;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 5px;
}

@media screen and (max-width: 680px) {
  .resources-header .resource-filters {
    margin-inline: 8px !important;
  }
}
@media screen and (max-width: 491px) {
  .resources-header .resource-filters .filter-by label {
    width: fit-content !important;
  }
  .resources-header .resource-filters .filter-by button {
    width: 120px !important;
  }
  .resources-header .resource-filters .input-container input {
    width: 100px !important;
  }
}
@media screen and (max-width: 365px) {
  .resources-header .resource-filters .filter-by label {
    display: none !important;
  }
}
.dashboard-item h2 {
  font-weight: 200;
}

.dashboard-item th {
  font-weight: normal;
}

.dashboard-item td {
  font-weight: normal;
}

.dashboard-item-content-container h1 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container h2 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container h3 {
  text-align: center;
  font-weight: 100;
}

.dashboard-item-content-container p {
  font-size: 20px;
  font-weight: 200;
}

.dashboard-item-content-container p img {
  max-width: 80%;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block !important;
  border-radius: 10px;
}

.dashboard-item-content-container {
  font-size: 20px;
  font-weight: 200;
}

.dashboard-container .container {
  max-width: unset !important;
}

.navbar-light .navbar-toggler {
  background-color: white;
}

div.custom-select.is-invalid,
div.custom-select.is-valid {
  border-bottom-width: 2px;
  border-top-width: 2px;
  border-left-width: 2px;
  border-right-width: 4px;
  height: 61px !important;
}

.screening-questions div.custom-select.is-invalid,
.screening-questions div.custom-select.is-valid {
  height: 40px !important;
}
.screening-questions .form-input-container {
  padding-block-start: 24px;
}
.screening-questions .btn-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.screening-questions .btn-primary:active {
  border-color: var(--color-primary-light) !important;
  background-color: var(--color-primary-light) !important;
}
.screening-questions .btn-primary:focus, .screening-questions .btn-primary:focus:active {
  box-shadow: 0 0 0 0.2rem var(--color-primary-light) !important;
}

/*** SHR ****/
.medfusion-instructions {
  border-color: black;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  padding: 15px;
  text-align: center;
  margin: 15px;
}

.medfusion-instructions dt {
  color: rgb(232, 142, 61);
  font-weight: 100;
  font-size: 20px;
  text-align: center;
}

.consent-bullet {
  margin-bottom: 10px;
}

.medfusion-modal {
  color: #64a5cf;
}

.medfusion-modal:hover {
  color: #0056b3;
  text-decoration: underline;
}

/*** Authorization/Consent ***/
.consent-container {
  width: 686px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 20px;
  margin: 0 auto;
  line-height: 32px;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.consent-table {
  margin: 0 auto;
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-family: var(--font-secondary);
  line-height: 32px;
}
.consent-table td:first-child {
  font-weight: bold;
}
.consent-table tr > td:first-child {
  width: 25%;
  vertical-align: middle;
  padding-right: 5%;
}
.consent-table tr > td:nth-child(2) {
  width: 60%;
  vertical-align: middle;
}

@media all and (max-width: 790px) {
  .consent-table {
    font-size: 16px;
  }
}
.consent-image {
  width: 50%;
  display: block;
  margin: auto;
}

.consent-image-description {
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.consent-header {
  padding-top: 1%;
  padding-bottom: 1%;
}

#consent-footer {
  position: relative;
  width: 100%;
  bottom: 10px;
  text-align: center;
  font-size: 10px;
}

/*** Footer ***/
html {
  height: 100%;
  box-sizing: border-box;
  background: var(--background-color);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
}

.sub-nav.nav-item {
  margin-left: 35px !important;
  font-size: 13px;
}

.dashboard-item.invitations-list .user-info {
  width: auto !important;
  margin-top: 30px;
}
.dashboard-item.invitations-list .user-info th {
  padding-right: 22px;
}

/**
 * Footer Styles
 */
nav#navbar.mobile-nav {
  display: none;
}

#sidenav {
  display: block;
}

.dashboard-container > .row {
  width: 100%;
  margin: 0;
}

@media all and (min-width: 961px) {
  .mobile-sidebar {
    display: none;
  }
}
.sub-nav.nav-item {
  width: 200px;
  padding: 15px 0 !important;
  height: 51px;
  line-height: 1.5;
}

@media all and (max-width: 1060px) and (min-width: 890px) {
  .dashboard-container .user-index .dashboard-item-banner .filter {
    float: none !important;
    margin-top: 20px;
  }
}
@media all and (max-width: 810px) {
  .dashboard-container .user-index .dashboard-item-banner .filter {
    float: none !important;
    margin-top: 20px;
  }
}
.landing-login {
  display: flex;
  white-space: nowrap;
  width: fit-content;
  padding-inline-start: 8px;
}

@media all and (max-width: 960px) {
  .sub-nav.nav-item {
    width: 200px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .container {
    max-width: unset !important;
  }
  #navbar:not(.dashboard-nav) .menu-icon-container {
    visibility: hidden;
  }
  #sidenav {
    display: none;
  }
  .dashboard-container {
    min-height: calc(100vh - 185px);
    width: 100%;
    display: block !important;
    max-width: unset;
  }
  .dashboard-container > .row {
    display: block !important;
  }
  .dashboard-container > .row > span {
    display: block;
    width: 406px;
    margin-left: 15px;
  }
  .desktop-nav {
    display: none;
  }
  .landing-login a {
    font-size: 16px;
  }
  .landing-container {
    width: 66% !important;
  }
  .btn-nav.signup {
    margin-right: 32px !important;
  }
  .mobile-nav {
    display: flex !important;
    height: 62px;
    padding: 16px;
  }
  .mobile-nav i,
  .mobile-nav svg {
    cursor: pointer !important;
  }
  .mobile-nav .navbar-brand {
    height: 100%;
    position: relative;
    left: 1px;
    padding: 0;
  }
  .mobile-nav .navbar-brand img {
    height: 31.12px;
    width: 108.76px;
    margin: 0 auto;
  }
  .mobile-nav .navbar-container {
    width: 33%;
    display: inline-block;
    position: relative;
  }
  .mobile-nav .logo-container:not(.landing-logo) {
    text-align: center;
  }
  .mobile-nav .btn-container {
    display: flex;
    position: relative;
    right: 6px;
  }
  .mobile-nav .btn-container .home-icon {
    border-right: 1px solid #ccc;
    margin-right: 16px;
    padding-right: 16px;
    width: 41px;
  }
  .mobile-sidebar {
    width: 235px;
    height: 90%;
    max-width: unset;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0 !important;
    z-index: 1020;
  }
  .mobile-sidebar .active * {
    color: white !important;
  }
  .mobile-sidebar > ul {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    height: 100%;
    background: white;
    position: fixed;
    min-width: 235px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .mobile-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .mobile-sidebar ul li {
    display: inline-block;
    height: 48px;
    padding: 16px;
  }
  .mobile-sidebar ul *:not(i) {
    border-radius: 0 !important;
    display: block !important;
    max-width: unset;
    color: var(--color-primary);
    font-family: var(--font-secondary);
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-decoration: none;
  }
  .mobile-sidebar ul a,
  .mobile-sidebar ul li {
    height: 48px !important;
    width: 100%;
  }
  .mobile-sidebar .row {
    display: flex;
  }
  .mobile-sidebar .col-1 {
    float: left;
    width: auto;
  }
  .col-9 .badge-container {
    height: 15px;
    width: fit-content;
    color: #ffffff;
    font-family: var(--font-secondary);
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    text-align: center;
    position: relative;
    left: 80%;
    bottom: 20px;
    border-radius: 5px !important;
  }
  .col-9 .badge-container .badge-pill {
    color: white;
    padding-right: 0.68em;
  }
  .col-9 .badge-container span {
    border-radius: 15px !important;
  }
  .sign-in-icn {
    text-decoration: none !important;
    color: #80868b !important;
  }
  .sign-in-icn svg {
    position: relative;
    top: 5px;
    right: 7px;
  }
  .login-form #navbar:not(.dashboard-nav) .logo-container {
    bottom: 0px !important;
  }
}
#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-form {
  background-color: var(--color-secondary);
}

@media all and (max-width: 370px) {
  .logo-container {
    right: 15px;
  }
}
.sidebar-appear,
.sidebar-enter {
  left: -20%;
  opacity: 0;
}

.sidebar-appear.sidebar-appear-active,
.sidebar-enter.sidebar-enter-active {
  left: 0%;
  opacity: 1;
  transition: all 0.2s ease-in;
}

.sidebar-exit {
  left: 0;
  opacity: 1;
}

.sidebar-exit .sidebar-exit-active {
  left: -20%;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.background-enter {
  transform: translate(100%);
}

.background-enter.background-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}

.background-leave {
  transform: translate(0%);
  opacity: 0;
}

.background-leave.background-leave-active {
  transform: translate(-100%);
  position: absolute;
  top: 90px;
  opacity: 0.2;
  transition: transform 300ms ease-in-out;
}

.background-r-enter {
  transform: translate(-100%);
}

.background-r-enter.background-r-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}

.background-r-leave {
  transform: translate(0%);
  opacity: 0;
}

.background-r-leave.background-r-leave-active {
  transform: translate(100%);
  position: absolute;
  top: 90px;
  opacity: 0.2;
  transition: transform 300ms ease-in-out;
}

.custom-select > div {
  position: relative;
  bottom: 7px;
  right: 13px;
  width: calc(100% + 44px);
}
.custom-select > div:nth-child(3) {
  position: relative !important;
  max-height: 155px !important;
  bottom: 120px !important;
  z-index: 1001;
  margin-top: -30px;
  border: 1px solid #ccc;
  box-shadow: 0 3px 4px 0 rgba(27, 54, 99, 0.14), 0 3px 3px -2px rgba(27, 54, 99, 0.12), 0 1px 8px 0 rgba(27, 54, 99, 0.2);
}
.custom-select > div:nth-child(3) > div {
  max-height: 140px;
}
.custom-select.locale-picker-select {
  width: 150px;
}
.custom-select.locale-picker-select .custom-select__control {
  color: var(--color-primary);
  border-width: 0px;
}
.custom-select.locale-picker-select .custom-select__control.custom-select__control--menu-is-open .custom-select__indicator {
  transform: rotateX(180deg);
}
.custom-select.locale-picker-select .custom-select__single-value {
  color: var(--color-primary);
  padding-inline-start: 24px;
}
.custom-select.locale-picker-select .custom-select__indicator-separator {
  width: 0;
}
.custom-select.locale-picker-select .custom-select__option i.fas {
  padding-inline-start: 8px;
}
.custom-select.locale-picker-select .custom-select__option.custom-select__option--is-selected {
  color: var(--white);
  background-color: var(--color-secondary);
}

@media all and (max-width: 960px) {
  .custom-select.locale-picker-select {
    width: 89px;
  }
}
.pull-right {
  float: right;
}

#helpButton {
  height: 64px;
  width: 64px;
}
#helpButton .fa-times {
  font-size: 40px;
}

@media all and (max-width: 760px) {
  #helpButton {
    bottom: 35px !important;
  }
  #helpButton #HelpButton {
    width: 60px !important;
    height: 60px !important;
  }
  .register-v2 .footer .buttons-container .btn-back-container {
    margin-left: 20px !important;
  }
  .container-fluid div.register-v2 div.container {
    width: 90% !important;
    padding-bottom: 20%;
  }
  .register-v2 ::-webkit-input-placeholder {
    /* WebKit browsers */
    font-size: 12px;
  }
  .register-v2 :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: 13px;
    opacity: 1;
  }
  .register-v2 ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: 13px;
    opacity: 1;
  }
  .register-v2 :-ms-input-placeholder {
    font-size: 13px;
    /* Internet Explorer 10+ */
  }
  .form-input-container div.mobile-placeholder {
    display: block;
    font-size: 14px;
    text-align: left;
  }
}
.mobile-placeholder {
  display: none;
}

.mf-cta__primary:focus,
.mf-cta__primary--optional:focus,
.mf-cta__secondary:focus,
.mf-btn:focus {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.page-404 {
  background: var(--background-color);
  height: calc(100vh - 150px);
  padding-top: 40px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.page-404 .container {
  background: white;
  height: 500px;
}
.page-404 h2 {
  padding-top: 20px;
  font-weight: 200;
  margin-bottom: 15px;
}
.page-404 .links-404 button {
  color: #4a90e2;
  appearance: none;
  background: white;
  border: none;
  outline: none;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.page-404 .links-404 button.back-to-home {
  margin-right: 20px;
}

#helpModal {
  width: 350px !important;
  background-color: #ffffff !important;
  border-radius: none;
}
#helpModal .help .panel {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 350px;
  background-color: #ffffff;
  border-radius: 20px !important;
}
#helpModal .modal-content {
  border-radius: 30px;
  border: none;
  background: transparent;
}
#helpModal .panel-profile-img {
  max-width: 80px;
  margin-top: -57px;
  margin-bottom: 11px;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
  vertical-align: middle;
  border: none;
  box-shadow: none;
}
#helpModal .panel-top p {
  opacity: 0.54;
  color: var(--color-primary) !important;
  font-family: var(--font-secondary);
  font-size: 12px !important;
  line-height: 16px;
  text-align: center;
  width: 288px;
}
#helpModal .panel-body {
  padding: 0 24px !important;
}
#helpModal .panel-body .have-questions {
  color: var(--color-primary);
  letter-spacing: 0.3px;
  margin-top: 20px;
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
}
#helpModal .panel-body hr {
  width: 252px;
  margin: 19px auto 28px auto;
}
#helpModal .panel-body .contact-team {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
}
#helpModal .panel-body a {
  text-decoration: underline;
  font-weight: normal;
  font-size: 15px;
  color: #4fa7d3;
}
#helpModal .panel-body i {
  color: var(--color-secondary) !important;
  font-size: 24px;
}
#helpModal .panel-body i.phone-container {
  position: relative;
  left: 20px;
}
#helpModal .panel-body i.mail-container {
  position: relative;
  left: 10px;
}
#helpModal .panel-body .schedule-call {
  display: block;
  padding: 12px 62px;
  margin-top: 16px;
  text-decoration: none !important;
  width: 291px;
  font-weight: 100;
  border: 2px solid var(--color-secondary);
  border-radius: 50px;
  background-color: var(--color-secondary);
  color: #ffffff;
  font-family: var(--font-primary);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.28px;
  line-height: 24px;
  text-align: center;
  position: relative;
  bottom: 7px;
}
#helpModal .panel-body .col-9 {
  margin-bottom: 20px;
}
#helpModal .panel-body .col-9 a {
  color: #4fa7d3;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 100 !important;
  line-height: 24px;
}
#helpModal .panel-body .row.justify-content-center {
  width: 312px;
  position: relative;
  text-align: center;
  right: 20px;
  padding: 0 20px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-0 {
  margin-left: 0;
}

.container.p-3 {
  margin-left: 0 !important;
}

.mt-4.col-md-9.dashboard-container.py-4 .container {
  margin-left: 0;
}

.consent-container-pdf {
  margin-left: 15px;
  margin-top: 9px !important;
}
.consent-container-pdf .navbar {
  padding-left: 0;
}
.consent-container-pdf .navbar button {
  margin-left: 0 !important;
}

@media all and (max-width: 420px) {
  #register-form fieldset button {
    cursor: pointer;
    display: block;
    float: none !important;
    margin-bottom: 10px;
    width: 100%;
  }
  .container-fluid div.register-v2 div.container {
    padding-bottom: 25%;
  }
}
.mobile-sidebar {
  -webkit-animation: fadein 500ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 500ms; /* Firefox < 16 */
  -ms-animation: fadein 500ms; /* Internet Explorer */
  -o-animation: fadein 500ms; /* Opera < 12.1 */
  animation: fadein 500ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body.modal-open {
  overflow-y: auto;
  padding: 0 !important;
}

.user-index {
  overflow: visible;
}

.btn-nav {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  text-align: center;
}
.btn-nav.signup {
  margin-right: 48px;
}
.btn-nav.login {
  margin-inline: 12px;
}
.btn-nav:hover {
  text-decoration: none;
}

.jumbotron {
  margin-bottom: 0 !important;
  margin-bottom: 0;
}
.jumbotron h1 {
  color: #ffffff;
  font-family: var(--font-primary);
  font-size: 60px;
  line-height: 80px;
  margin-bottom: 37px;
}
.jumbotron p {
  color: #ffffff;
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 32px;
}
.jumbotron .title-container {
  padding: 0;
}
.jumbotron .title-container div {
  max-width: 450px;
  padding-top: 82px;
  text-align: left;
  margin-left: 90px;
}

.list-group.landing::-webkit-scrollbar {
  width: 0.5em;
}

.list-group.landing::-webkit-scrollbar-track {
  border-radius: 5px;
}

.list-group.landing::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
  border-radius: 5px;
}

.list-group.landing {
  margin-left: 80px;
  text-align: left;
  padding-inline: 12px;
  overflow-wrap: normal;
  max-height: 550px;
  overflow-y: scroll;
}
.list-group.landing a {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 20px;
  padding: 12px 24px;
  line-height: 30px;
}
.list-group.landing a:hover {
  background-color: var(--color-secondary);
  color: #ffffff;
}
.list-group.landing a:hover svg {
  fill: white;
}
.list-group.landing a svg {
  position: absolute;
  right: 17px;
  top: 30%;
}

.help-modal-container {
  position: relative;
}
.help-modal-container #helpModal {
  box-shadow: 0 3px 4px 0 rgba(27, 54, 99, 0.14), 0 3px 3px -2px rgba(27, 54, 99, 0.12), 0 1px 8px 0 rgba(27, 54, 99, 0.2);
  border-radius: 20px !important;
}
.help-modal-container .arrow {
  width: 100px;
  height: 25px;
  position: absolute;
  bottom: -24px;
  left: 82%;
  transform: translateX(-50%);
  overflow: hidden;
}
.help-modal-container .arrow::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 50%;
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.6);
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .benefits-info {
      letter-spacing: -1px !important;
    }
  }
}
@-moz-document url-prefix() {
  .benefits-info {
    letter-spacing: -0.5px !important;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  .benefits-info {
    letter-spacing: normal !important;
  }
  .faq-section .card-header a h5 {
    letter-spacing: -1px;
  }
}
@media all and (max-width: 767px) {
  .btn-back-container {
    margin-left: 5px !important;
  }
  div.register-v2 .footer div.buttons-container .steps-container {
    width: 19% !important;
  }
  .btn-nav.login {
    margin-right: 0px;
    margin-left: 4px;
  }
  div.landing-page-support {
    padding-top: 56px !important;
    padding-bottom: 56px !important;
    margin-top: 56px !important;
  }
  .join-now {
    margin-left: 0 !important;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    div#helpModal {
      bottom: unset;
      top: 0;
    }
  }
  section.jumbotron.with-link {
    padding-top: 108px !important;
    padding-bottom: 108px !important;
  }
  section.jumbotron.with-link .join-now {
    margin: auto !important;
  }
  section.jumbotron.with-link div.container {
    padding-right: 45px;
    padding-left: 45px;
  }
  .help-modal-container {
    position: absolute;
    width: 100%;
  }
  .help-modal-container div#helpModal {
    position: fixed;
    bottom: 0 !important;
    right: 0 !important;
    z-index: 10040;
    width: 100% !important;
    display: block;
    padding-right: 15px !important;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
    height: 100vh !important;
  }
  .help-modal-container div#helpModal .modal-content {
    height: 100%;
    border-radius: 0;
    border: 0;
    width: 100% !important;
  }
  .help-modal-container div#helpModal i {
    color: var(--color-secondary) !important;
    font-size: 24px;
  }
  .help-modal-container div#helpModal i.phone-container {
    left: 0px;
  }
  .help-modal-container div#helpModal i.mail-container {
    left: 0px;
    font-size: 22px !important;
  }
  .help-modal-container div#helpModal .panel-body .row.justify-content-center {
    width: auto;
  }
  .help-modal-container div#helpModal .justify-content-center {
    padding: 0;
  }
  .help-modal-container div#helpModal .justify-content-center .col-9 {
    padding-left: 0;
    position: relative;
    right: 0px;
  }
  .help-modal-container div#helpModal .panel-body {
    margin-top: 30px;
  }
  .help-modal-container div#helpModal .panel-body i {
    font-size: 20px;
    position: relative;
    right: 7px;
    top: 2px;
  }
  .help-modal-container div#helpModal .col-9 a {
    font-weight: 200 !important;
    font-family: var(--font-secondary);
    font-size: 20px;
    line-height: 20px;
  }
  .help-modal-container div#helpModal .contact-team {
    margin-bottom: 42px;
    margin-top: 32px;
    font-size: 20px;
    padding: 0 32px;
    line-height: 26px;
  }
  .help-modal-container div#helpModal hr {
    margin-top: 25px;
    width: 98%;
  }
  .help-modal-container div#helpModal button.close {
    position: relative;
    top: 22px;
    right: 25px;
    font-weight: lighter;
  }
  .help-modal-container div#helpModal .panel-profile-img {
    max-width: 78px !important;
    margin-top: 18px !important;
    margin-bottom: 5px !important;
    border: none !important;
    border-radius: 100% !important;
    box-shadow: none !important;
    vertical-align: middle !important;
    padding: 0px 5px !important;
    margin-left: 17px !important;
  }
  .help-modal-container div#helpModal .schedule-call {
    margin: auto !important;
    margin-top: 17px !important;
    width: 93%;
    left: 0px;
    bottom: 0;
  }
  .help-modal-container div#helpModal .panel-top p {
    max-width: unset;
    width: calc(100% + 20px);
    margin-left: 0px;
    margin-top: 7px;
  }
  .help-modal-container div#helpModal .help .panel {
    border-radius: 0 !important;
    margin: auto !important;
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
  }
  .help-modal-container div#helpModal .help .panel a {
    text-decoration: underline;
  }
  .help-modal-container button.close {
    display: block;
  }
  .list-group.landing {
    margin: 0 auto !important;
    width: 98%;
    max-width: 350px;
  }
  .list-group.landing a {
    padding: 10px 15px;
    font-size: 17px;
  }
  .item-title {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .landing-page-separator {
    width: calc(100% - 50px) !important;
  }
  div.faq-section {
    max-width: calc(100% - 50px) !important;
  }
  div.faq-section .benefits-container {
    margin: 0 auto !important;
    padding: 0 !important;
  }
  .process-container div.item-container {
    margin-bottom: 56px;
    padding: 0 24pt;
  }
  .process-container .join-now {
    width: 100% !important;
  }
  p.item-description {
    margin-bottom: 0;
  }
  div.row.first {
    margin-bottom: 0 !important;
  }
  .title-container {
    padding: 0;
  }
  .title-container div {
    width: 71.5%;
    margin: auto !important;
  }
  .list-container {
    padding: 0 8px !important;
  }
  .list-group a svg {
    position: absolute;
    top: 30% !important;
  }
  .jumbotron {
    padding-top: 62px !important;
    min-height: unset !important;
    padding-left: 12px;
    padding-bottom: 70px;
  }
  .jumbotron .col-md-6 {
    padding-left: 8px;
  }
  .jumbotron .title-container > div {
    padding-top: 0 !important;
    width: 80%;
  }
  .jumbotron h1 {
    font-size: 49px !important;
    line-height: 63px !important;
    margin-bottom: 20px !important;
    margin-right: 8px !important;
    margin-top: 5px;
    text-align: center;
  }
  .jumbotron p {
    font-size: 24px !important;
    letter-spacing: 0.9px;
    line-height: 35px !important;
    padding: 2px;
    margin-bottom: 40px;
  }
  .faq-section h2.benefits-title {
    color: var(--color-primary);
    font-family: var(--font-primary);
    font-size: 36px;
    line-height: 60px;
    width: 100%;
    margin-bottom: 56px;
    text-align: center;
    margin-top: 5px;
  }
  .faq-section .card-header {
    padding-top: 24px !important;
    padding-bottom: 6px !important;
  }
  .faq-section .card-header a h5 {
    font-size: 16.2px !important;
    line-height: 22px !important;
    max-width: 90%;
    padding: 0.5rem;
  }
  .faq-section .join-now {
    margin-top: 25px;
    width: 100% !important;
  }
  .faq-section svg {
    position: relative;
    top: -15px !important;
    left: 10% !important;
  }
  .benefits-section {
    padding: 56px 24px !important;
    padding-bottom: 52px !important;
  }
  .benefits-section .benefits-title {
    font-size: 35px;
    line-height: 1.2 !important;
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
  }
  .benefits-section .benefits-info {
    text-align: center;
    width: 100%;
    font-size: 20px !important;
    height: auto;
    padding: 20px 12px 32px 12px;
    padding-bottom: 15px;
    margin: auto;
    line-height: 30px;
    letter-spacing: 0.7px;
    margin-bottom: 32px;
  }
  .benefits-section .benefits-info.info-desktop {
    display: none;
  }
  .benefits-section .benefits-info.info-mobile {
    display: block;
  }
  .benefits-section .benefits-container ul {
    margin-bottom: 32px !important;
  }
  .benefits-section .benefits-container li {
    font-size: 20px;
    margin-bottom: 15px !important;
    padding-right: 22px;
    line-height: 1.5;
  }
  .benefits-section .join-now {
    margin-top: 25px;
    width: 100% !important;
  }
  .navbar-brand img {
    width: 189px;
    height: 56px;
  }
  .landing-page-separator {
    margin-bottom: 45px !important;
  }
  .process-title {
    margin-left: 23px !important;
    margin-top: 3px !important;
    font-size: 36px !important;
    margin-bottom: 52px !important;
  }
  div.process-container .col-12 .row.second {
    padding: 0 !important;
  }
}
.benefits-section {
  padding: 120px 97.5px;
}

.benefits-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  width: 125px;
  margin-bottom: 64px;
}

.benefits-info {
  height: 176px;
  width: 222px;
  border-radius: 8px;
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  padding: 20px 24px 32px 24px;
  line-height: 32px;
}
.benefits-info.info-mobile {
  display: none;
}

.join-now:hover {
  background-color: var(--color-secondary-light);
  border-color: var(--color-secondary-light);
}

.benefits-container ul {
  padding-left: 34px;
  padding-top: 5px;
  margin-bottom: 64px;
}
.benefits-container ul li {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 40px;
}
.benefits-container img {
  width: 100%;
}
.benefits-container .benefits-container {
  margin-left: 14px !important;
}
.benefits-container .landing-page-support {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
  margin-top: 56px !important;
  height: 56px;
  padding: 16px 0;
  width: 183px;
  border: 2px solid var(--color-secondary);
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  text-decoration: none !important;
  background-color: var(--color-secondary);
  font-family: var(--font-primary);
  font-size: 20px;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-align: center;
  display: block;
  margin: auto;
  margin-left: 34px;
}
.benefits-container .landing-page-support h2 {
  font-size: 35px !important;
  font-weight: normal;
}
.benefits-container .landing-page-support h3 {
  font-size: 21px !important;
  padding: 0 8px !important;
  margin-bottom: 20px !important;
}
.benefits-container .landing-page-support h4 {
  font-size: 20px !important;
}

.landing-page-separator {
  height: 1px;
  width: 57.67%;
  background-color: rgba(27, 54, 99, 0.12);
  margin-bottom: 120px;
  border: none;
}

.process-container .process-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 80px;
  text-align: center;
}
.process-container .item-container .item-body .item-title {
  color: var(--color-primary);
  max-width: 304px;
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  text-transform: capitalize;
  margin: auto;
}
.process-container .item-container .item-body .item-description {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  max-width: 304px;
  margin: auto;
}
.process-container .row.first {
  margin-bottom: 45px;
  padding: 0 60px;
}
.process-container .row.second {
  padding: 0 20%;
  padding-bottom: 147px !important;
}

.benefits-section {
  padding: 97px 90px;
}

.benefits-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  width: 125px;
  margin-bottom: 64px;
}

.benefits-container ul {
  padding-left: 34px;
  padding-top: 5px;
  margin-bottom: 64px;
}
.benefits-container ul li {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 40px;
}
.benefits-container .join-now {
  height: 56px;
  padding: 16px 0;
  width: 183px;
  border: 2px solid var(--color-secondary);
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  text-decoration: none !important;
  background-color: var(--color-secondary);
  font-family: var(--font-primary);
  font-size: 20px;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-align: center;
  display: block;
}
.benefits-container .join-now:hover {
  background-color: var(--color-secondary-light);
  border-color: var(--color-secondary-light);
}

.landing-page-separator {
  height: 1px;
  width: 57.67%;
  background-color: rgba(27, 54, 99, 0.12);
  margin-bottom: 120px;
}

.process-container .process-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 80px;
  text-align: center;
}
.process-container .item-container .item-body .item-title {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.process-container .item-container .item-body .item-description {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.process-container .row.first {
  margin-bottom: 64px;
}
.process-container .row.second {
  padding: 0 16.25%;
}

.faq-section {
  max-width: 57.67% !important;
}
.faq-section .benefits-container {
  padding-right: 0px;
  padding-left: 100px;
}
.faq-section .accordion {
  margin-top: -25px;
  margin-bottom: 64px;
}
.faq-section .card-header {
  padding-right: 0;
}
.faq-section .card-header a,
.faq-section .card-header h5 {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 32px;
  text-decoration: none !important;
  padding: 0.5rem;
}
.faq-section .card-header .collapsed svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.faq-section .card-header svg {
  float: right;
  margin-top: 5px;
}
.faq-section .card {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(27, 54, 99, 0.24);
  padding-bottom: 1px;
}
.faq-section .card-body {
  padding-left: 0;
  padding-top: 13px;
}
.faq-section .card-body h3 {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 24px;
}
.faq-section .card-body ul li {
  margin-bottom: -5px;
}
.faq-section .card-body a {
  color: #4fa7d3;
  font-family: var(--font-secondary);
  font-weight: lighter;
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
}
.faq-section .card-header {
  background: white;
  border: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 0;
  letter-spacing: 0.4px;
}

.landing-page-support {
  padding-top: 88px !important;
  padding-bottom: 56px !important;
  margin-top: 120px !important;
}
.landing-page-support h2 {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 31px;
}
.landing-page-support h3 {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  margin-bottom: 48px;
}
.landing-page-support h4 a {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.benefits-section {
  padding: 97px 90px;
}

.benefits-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  width: 125px;
  margin-bottom: 64px;
}

.benefits-container ul {
  padding-left: 34px;
  padding-top: 5px;
  margin-bottom: 64px;
}
.benefits-container ul li {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 40px;
}

.join-now-container {
  margin: 64px 0;
}

button.join-now {
  cursor: pointer;
  height: 56px;
  text-decoration: none !important;
  padding: 16px;
  padding-inline: 32px;
  margin: auto;
  border: 2px solid var(--color-secondary);
  border-radius: 50px;
  color: white;
  text-transform: uppercase;
  text-decoration: none !important;
  background-color: var(--color-secondary);
  font-family: var(--font-primary);
  font-size: 20px;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-align: center;
  display: block;
}

.landing-page-separator {
  height: 1px;
  width: 57.67%;
  background-color: rgba(27, 54, 99, 0.12);
  margin-bottom: 120px;
}

.process-container .process-title {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 80px;
  text-align: center;
}
.process-container .item-container .item-body .item-title {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.process-container .item-container .item-body .item-description {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.process-container .item-container .item-icon-container {
  height: 96px;
  width: 96px;
  margin: auto;
  border-radius: 100%;
  color: var(--color-secondary);
  text-align: center;
  margin-bottom: 30px;
}
.process-container .item-container .item-icon-container svg {
  font-size: 24px;
  margin-top: 24px;
}
.process-container .row.first {
  margin-bottom: 64px;
}
.process-container .row.second {
  padding: 0 21%;
}
.process-container .item-container {
  padding: 0 13px;
}

.faq-section .accordion {
  margin-top: -25px;
  margin-bottom: 64px;
}
.faq-section .card-header {
  padding-right: 0;
}
.faq-section .card-header a,
.faq-section .card-header h5 {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-decoration: none !important;
}
.faq-section .card-header .collapsed svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.faq-section .card-header svg {
  float: right;
  margin-top: 5px;
}
.faq-section .card {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(27, 54, 99, 0.24);
}
.faq-section .card-body {
  padding-left: 0;
  padding-top: 13px;
}
.faq-section .card-body h3 {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 24px;
}
.faq-section .card-body ul li {
  margin-bottom: -5px;
}
.faq-section .card-body a {
  color: #4fa7d3;
  font-family: var(--font-secondary);
  font-weight: lighter;
  font-size: 16px;
  line-height: 24px;
}
.faq-section .card-header {
  background: white;
  border: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 0;
  letter-spacing: 0.4px;
}

.landing-page-support {
  padding-top: 88px;
  padding-bottom: 80px;
}
.landing-page-support h2 {
  color: var(--color-primary);
  font-family: var(--font-primary);
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 31px !important;
  font-weight: normal;
}
.landing-page-support h3 {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  margin-bottom: 48px;
}
.landing-page-support h4 {
  color: var(--color-primary);
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-delay: 500ms;
}

.policy-content {
  overflow-y: auto;
  height: calc(100vh - 249px);
}

.modal-content {
  margin: 0 auto;
  width: 588px;
}

@media all and (max-width: 768px) {
  .modal-content {
    width: 100%;
  }
}
.signature-modal-body canvas {
  height: 200px;
}

.profile-change-password {
  clear: both;
}

#medfusion-connect-modal button.close {
  position: absolute;
  right: 0px;
  top: -2px;
  display: block !important;
  outline: none;
  z-index: 1000;
}
#medfusion-connect-modal #createConnectionContent {
  padding-top: 0;
  overflow-y: auto !important;
}
#medfusion-connect-modal #mfButtonHolder {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid #ccc;
}
#medfusion-connect-modal .mf-modal .modal-content .modal-header .header-bottom-border {
  width: 100%;
}
#medfusion-connect-modal .mf-modal .modal-content .modal-header .close {
  margin-top: -15px;
}
#medfusion-connect-modal #mfConnectBack span {
  position: relative;
  bottom: 1px;
}

.auth-sidebar-container ul {
  float: left;
  width: 272px;
  list-style: none;
  padding: 0;
  border-right: 1px solid #eee;
  overflow: auto;
  margin: 0;
  height: 803px;
}
.auth-sidebar-container ul li {
  padding: 24px;
  color: #4a4a4a;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  border-bottom: 1px solid #eee;
}
.auth-sidebar-container ul li:hover, .auth-sidebar-container ul li.active {
  background-color: rgba(245, 136, 32, 0.1);
  font-family: var(--font-secondary);
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  line-height: 24px;
  color: var(--color-secondary);
}
.auth-sidebar-container ul li.active {
  border-right: 4px solid var(--color-secondary);
}

@media all and (max-width: 615px) {
  #medfusion-connect-modal {
    width: 100%;
  }
  #medfusion-connect-modal .modal-content {
    width: 100%;
    padding: 30px 0 !important;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    #medfusion-connect-modal button.close {
      right: 35px;
      top: 30px;
    }
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    #medfusion-connect-modal #mfButtonHolder button.close {
      right: 35px;
      top: 30px;
    }
  }
}
.timespan {
  display: block;
}

.date-dropdown > .show {
  max-height: 210px !important;
  overflow: auto !important;
  width: 95% !important;
}

.shr-container {
  min-height: 500px !important;
}
.shr-container .records-container-loading {
  position: relative;
  left: calc(50% - 90px);
  margin: auto 0;
  width: 150px;
}
.shr-container .records-container-loading picture img {
  width: 100%;
  height: 100%;
}
.shr-container .shr-inner-container {
  min-height: 500px !important;
}
.shr-container .shr-inner-container button.dropdown-item {
  outline: none;
  cursor: pointer;
}
.shr-container .search-icon {
  position: absolute;
  top: 5px;
  left: 10px;
}
.shr-container .input-container {
  top: 0;
}

.record-grid .records-container-loading {
  position: relative;
  left: calc(50% - 90px);
  margin-top: 20px;
}

.records-title .sort-by > div {
  display: inline-block;
}
.records-title .sort-by button {
  position: relative;
}
.records-title .sort-by button.sort-by-btn:after {
  position: absolute;
  right: 9px;
  top: 15px;
  bottom: 12px;
  left: unset;
}
.records-title .sort-by .title-dropdown {
  left: 41% !important;
}
.records-title .sort-by .dropdown-menu.show {
  width: 108px;
  top: -10px !important;
}
.records-title .sort-by label {
  bottom: 0px !important;
}

.records-title .sort-by > div.show {
  position: relative;
}
.records-title .sort-by .dropdown-menu.show {
  left: 0 !important;
  top: -10px !important;
}

@media all and (max-width: 700px) {
  .shr-inner-container .dashboard-item-banner .actions-container.filter {
    float: none !important;
    width: 60%;
  }
  .shr-inner-container .sort-by-btn {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .resources-list .container div {
    display: block;
  }
  .resources-list .container div.col-4 {
    width: 100%;
    text-align: center;
    max-width: unset;
    margin-top: 10px;
  }
  .resources-list .container div.col-4 img {
    float: none !important;
  }
  .resources-list .container div.col-7 {
    width: 100%;
    margin-top: 20px;
    max-width: unset;
  }
  .resources-list .container div.col-1 {
    display: none;
  }
}
.resources-list .container div.col-4 {
  text-align: center;
}

.profile-container {
  width: 100%;
}
.profile-container table td {
  word-break: break-all;
}

picture.loading-logo-container {
  text-align: center;
}
picture.loading-logo-container img {
  width: 250px;
  height: auto;
  max-width: unset;
  top: -100px;
}

.consent-signature {
  width: auto;
  height: calc(100% - 56px);
  max-width: 50vw;
}

@media all and (max-width: 615px) {
  .signer-info {
    float: none !important;
    margin: 20px auto;
    width: calc(100% - 50px);
  }
  .sign-badge-container > img {
    margin-left: 40px !important;
  }
}
.auth-content {
  width: 100%;
  display: inline-block;
  padding: 31px 48px;
  padding-bottom: 0;
  max-height: 720px;
  overflow: auto;
  color: rgba(0, 0, 0, 0.87);
  background: white;
  font-family: var(--font-secondary);
  font-size: 20px;
  line-height: 35px;
}
.auth-content div {
  max-width: 686px;
  margin: 0 auto;
}
.auth-content h5 {
  color: #0d192d;
  font-family: var(--font-secondary);
  font-size: 34px;
  font-weight: bold;
  line-height: 40px;
}
.auth-content h5.underline {
  font-size: 25px;
}
.auth-content button {
  margin: 24px 0;
}
.auth-content button.btn.sign-authorization {
  height: 56px;
  width: 238px;
  border: 2px solid var(--color-secondary);
  border-radius: 28px;
  background-color: var(--color-secondary);
  color: #ffffff;
  font-family: var(--font-secondary);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.6px;
  line-height: 24px;
  text-align: center;
}
.auth-content button.btn.sign-authorization:hover {
  background-color: var(--color-secondary-light);
}
.auth-content button.btn.sign-authorization.click-to-sign {
  width: 294px;
}
.auth-content button.btn.download-pdf {
  height: 60px;
  width: auto;
  padding: 16px 24px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  font-family: var(--font-secondary);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  max-width: 100%;
  white-space: normal;
  height: auto;
  min-height: 60px;
}
.auth-content button.btn.download-pdf:hover {
  background: #eee;
}
.auth-content .signature-container {
  height: 251px;
  width: 686px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
}
.auth-content .signature-container .timestamp-container {
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 13px;
  line-height: 20px;
  height: 56px;
  padding: 16px 18px;
  border-top: 1px solid #e4e4e4;
}

.row.justify-content-center {
  clear: both;
}

.auth-panel-container {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  background: white;
}

.auth-footer-container {
  clear: both;
  border-top: 1px solid #eee;
  padding: 24px 0 24px 183px;
}
.auth-footer-container .bottom .left {
  float: left;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-right: 16px;
}
.auth-footer-container .bottom .left span {
  position: relative;
  bottom: 5px;
}
.auth-footer-container .bottom .left i {
  position: relative;
  margin-right: 16px;
  font-size: 24px;
}
.auth-footer-container .bottom .right > div {
  position: relative;
  left: 10px;
  top: 0px;
  display: inline-block;
  color: #9b9b9b;
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
}
.auth-footer-container .bottom .right > div.contact-us {
  margin-left: -10px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
}
.auth-footer-container .bottom .right > div.or {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
}
.auth-footer-container .bottom .right > div.demo a {
  color: var(--color-secondary) !important;
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.header-banner {
  height: 335px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  position: relative;
  background-color: rgba(0, 0, 0, 0.21);
  padding-inline: 48px;
  margin-bottom: 0;
  background-size: 100%;
  margin-top: 17px;
}
.header-banner .layer {
  background-color: rgba(0, 0, 0, 0.21);
  position: absolute;
  top: 0;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header-banner h2 {
  max-width: 716px;
  color: #ffffff;
  font-family: var(--font-secondary);
  font-size: 48px;
  font-weight: bold;
  line-height: 60px;
  position: relative;
  top: 127px;
  letter-spacing: 0.8px;
}
.header-banner svg {
  position: relative;
  top: 135px;
  color: white;
}

.auth-nav-container {
  height: 70px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.auth-nav-container button {
  appearance: none;
  border: 0;
  height: 24px;
  padding: 0;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-size: 14px;
  text-transform: uppercase;
  margin: 24px 0;
  font-weight: bold;
  letter-spacing: 0.33px;
  line-height: 14px;
  text-align: center;
  outline: none;
  cursor: pointer;
}
.auth-nav-container button svg {
  margin: 0 24px;
}
.auth-nav-container button span {
  background: white;
}
.auth-nav-container button span.back-btn-text {
  float: right;
  padding: 5px 0;
}
.auth-nav-container button span.back-btn-text span {
  position: relative;
  bottom: 7px;
}
.auth-nav-container button span.next-btn-text {
  float: left;
  padding: 5px 0;
}
.auth-nav-container button span.next-btn-text span {
  position: relative;
  bottom: 7px;
}
.auth-nav-container button.hidden {
  visibility: hidden;
}
.auth-nav-container button.visible {
  visibility: visible;
}
.auth-nav-container button.dropdown-toggle {
  height: 40px;
  max-width: fit-content;
  border: 1px solid #cccccc;
  border-radius: 20px;
  background-color: #ffffff;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 8px 30px 8px 16px;
  position: relative;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 14px;
}
.auth-nav-container button.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-bottom: 0;
  border-left: 0.2em solid transparent;
  position: absolute;
  right: 16px;
  color: #373a3c;
  width: -2px;
  top: 19px;
}
.auth-nav-container .dropdown-menu.show {
  height: 216px;
  max-width: fit-content;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 16px;
  overflow: auto;
}
.auth-nav-container .dropdown-menu.show .dropdown-item {
  height: 24px;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 16px !important;
  text-align: left;
  font-weight: bold;
  line-height: 24px;
  max-width: unset;
  margin: 8px 0 !important;
  text-transform: none;
}
.auth-nav-container .dropdown-menu.show .dropdown-item.active {
  background: transparent;
  color: var(--color-secondary);
}
.auth-nav-container .dropdown-menu.show .dropdown-item:active {
  color: var(--color-secondary);
  background: transparent;
}
.auth-nav-container .dropdown-menu.show .dropdown-item.disabled {
  color: #bdd5ea !important;
}

.auth-panel-desktop {
  display: block;
}

.auth-panel-mobile {
  display: none;
}

@media all and (min-width: 760px) and (max-width: 1050px) {
  .header-banner {
    background-size: cover !important;
    background-repeat: repeat-x;
  }
}
@media all and (max-width: 790px) {
  .auth-content div {
    max-height: unset;
  }
  .auth-footer-container .bottom .left {
    margin-bottom: 16px !important;
  }
  .auth-footer-container .bottom .right > div.or {
    display: inline-block;
    width: unset;
    position: relative;
    margin: 0px 5px !important;
  }
  .auth-footer-container .bottom .right > div.demo {
    display: inline-block;
    width: unset;
    position: relative;
  }
  .auth-footer-container {
    padding: 24px 57px;
    text-align: center;
    clear: both;
    border-top: 1px solid #e4e4e4;
    margin-top: 1px;
    font-size: 16px;
  }
  .auth-footer-container div {
    float: none !important;
    margin: 0 !important;
  }
  .consent-container {
    width: 100%;
  }
  .auth-content .signature-container {
    width: 100%;
  }
  .header-banner {
    padding-left: 23px;
    height: 281px;
    background-size: cover !important;
    background-position: 50% 65%;
  }
  .header-banner h2 {
    width: 316px;
    color: #ffffff;
    color: #ffffff;
    font-family: var(--font-secondary);
    font-size: 34px;
    line-height: 40px;
    top: 40px;
    font-weight: normal;
    margin-bottom: 24px;
  }
  .header-banner svg {
    top: 289px;
    color: white;
    position: relative;
  }
  .auth-panel-desktop {
    display: none;
  }
  .auth-panel-mobile {
    display: block;
  }
  .auth-panel-mobile button.dropdown-toggle.mobile {
    color: var(--color-secondary);
    font-size: 16px;
    font-weight: bold;
    font-family: var(--font-secondary);
    font-weight: bold;
    line-height: 24px;
    float: left;
    width: 100%;
    background-color: #ffffff;
    padding: 16px 50px 16px 16px;
    border-bottom: 1px solid #e4e4e4;
    border-radius: none !important;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
  .auth-panel-mobile button.dropdown-toggle::after {
    position: absolute;
    right: 31px;
    bottom: 50%;
    color: #5f697a;
  }
  .auth-panel-mobile div.dropdown-menu.filter-by.show {
    width: calc(100% - 30px) !important;
    margin-top: 0px;
    border-radius: 0;
    width: 96%;
  }
  .auth-panel-mobile button.btn.sign-authorization {
    font-size: 16px;
  }
  .auth-panel-mobile #auth-dropdown {
    max-width: 100% !important;
  }
  .auth-content {
    width: 100%;
    padding: 33px;
  }
  .auth-content h5 {
    color: #0d192d;
    font-family: var(--font-secondary);
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 22px;
    padding: 0 !important;
  }
  .auth-content p,
  .auth-content li,
  .auth-content a {
    font-family: var(--font-secondary);
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 24px;
  }
  .auth-nav-container {
    border-bottom: none;
    border-top: 1px solid #e4e4e4;
    background: white;
    position: relative;
  }
  .auth-nav-container button {
    background: white;
    padding-top: 24px;
    padding-bottom: 47px;
    margin: 0;
    padding-top: 24px;
    height: 70px;
    padding-bottom: 24px;
  }
  .auth-nav-container button.auth-back-btn {
    padding-right: 10px;
    width: 33%;
  }
  .auth-nav-container button.auth-next-btn {
    padding-left: 10px;
    border-left: 1px solid #e4e4e4;
    width: 33%;
  }
  .auth-nav-container button span {
    bottom: 5px;
  }
  .auth-nav-container button span.back-btn-text {
    position: relative;
    width: 75px;
  }
  .auth-nav-container button span.back-btn-text span {
    position: relative;
    bottom: 7px;
  }
  .auth-nav-container button span.next-btn-text {
    position: relative;
    width: 75px;
  }
  .auth-nav-container button span.next-btn-text span {
    position: relative;
    bottom: 7px;
  }
  .auth-nav-container button svg {
    margin-inline: 4px;
  }
  .sign-badge-container {
    margin-left: 0px !important;
  }
}
@media all and (min-width: 768px) and (max-width: 790px) {
  .sign-badge-container {
    margin-left: 70px !important;
  }
}
@media all and (max-width: 890px) {
  .sign-badge-container {
    margin-left: 100px;
  }
  .sign-badge-container img {
    margin-left: 0 !important;
  }
  .sign-badge-container .signer-info {
    float: none;
    margin-left: 10px;
    width: 95%;
  }
  .sign-badge-container .timespan {
    width: 100%;
  }
}
.authorizations-container-loading {
  position: relative;
  min-height: 500px !important;
  left: calc(50% - 90px);
  margin: auto 0;
}
.authorizations-container-loading img.loading-logo {
  min-width: 200px;
  height: 170px;
  margin-top: 60px;
}

.sign-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
}
.sign-container:before {
  content: "";
  border: dotted 1px var(--color-primary);
  border-radius: 30px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
}
.sign-container img {
  height: 100px;
  max-width: 100%;
}
.sign-container div {
  position: absolute;
  left: 8%;
  font-size: 12px;
  font-weight: bolder;
  background: white;
  padding: 0px 10px;
}
.sign-container .signed-by {
  top: -9px;
}
.sign-container .consent-id {
  bottom: -9px;
}

.step-divider {
  border-bottom: rgba(0, 0, 0, 0.05);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

label[for=dxDate] {
  display: block;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.show-invalid-feedback + .invalid-feedback {
  display: block;
}

.sticky-footer-section {
  min-height: 199px;
}

.sticky-footer {
  background-color: #fefefe;
  position: absolute;
  bottom: var(--footer-height);
  left: 0;
  right: 15px;
  overflow-y: hidden;
  box-shadow: 0 15px 10px 20px rgba(0, 0, 0, 0.1);
}
.sticky-footer.collapsed {
  height: 190px;
}
.sticky-footer.expanded {
  height: calc(100vh - 117px);
  overflow-y: scroll;
}
.sticky-footer .expand-button {
  position: absolute;
  top: 15px;
  right: 15px;
  outline: inherit;
  border: none;
  background: none;
  font-weight: bold;
  font-size: small;
  color: var(--color-primary);
}
.sticky-footer .expand-button:hover {
  font-weight: bolder;
  font-size: medium;
  cursor: pointer;
}

@media all and (min-width: 960px) {
  .sticky-footer.expanded {
    height: calc(100vh - 141px);
  }
}
.locales-modal {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.locales-modal .modal-content {
  width: fit-content;
  height: fit-content;
  min-height: 300px;
  padding: 40px !important;
}
.locales-modal .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}
.locales-modal .modal-title {
  font-weight: 400;
  font-size: 34px;
  line-height: 32px;
  text-align: center;
  color: var(--color-primary);
  padding-block: 8px;
  white-space: break-spaces;
}
.locales-modal .locales-list {
  display: flex;
  flex-direction: column;
  padding-block: 8px;
  gap: 8px;
}
.locales-modal .btn.locale-item {
  background-color: var(--color-secondary);
  color: var(--white);
  padding: 12px;
  border-radius: 24px;
}

.card {
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
}
.card__title {
  color: #F0AD4E;
  font-family: "Helvetica Neue";
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
  margin: 0;
}
.card__header {
  border-bottom: 1px solid #F1F1F1;
  margin-bottom: 48px;
  padding-bottom: 16px;
}
.card__footer {
  border-top: 1px solid #F1F1F1;
  margin-top: 48px;
  padding: 47px 52px;
}

.divider {
  color: #F1F1F1;
}

#mfConnectWrapper #mfModalHeader #mfButtonHolder {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
#mfConnectWrapper #mfModalHeader #mfButtonHolder * {
  float: none;
}
#mfConnectWrapper #mfModalHeader #mfButtonHolder #mfConnectBack {
  margin-right: auto;
}

.mf-connect {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #4a4a4a;
  font-size: 16px;
  line-height: 1.25;
}
.mf-connect .mf-cta__primary {
  outline: 0;
  font-size: 16px;
  line-height: 1.25;
  border-radius: 96px;
  height: 48px;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  background: #235ba8;
  border: 1px solid #235ba8;
  color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}
.mf-connect .mf-cta__primary:disabled {
  background: #ddd;
  border: 1px solid #ddd;
  color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.mf-connect .mf-icon {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 32px 32px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  margin-right: 8px;
  text-align: center;
  width: 32px;
}
.mf-connect .mf-icon__medfusion-twirl {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='32px' height='32px' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M21.8968422,12.1159623 C22.1621052,12.9685939 22.3136843,13.8591202 22.3263158,14.7622781 C22.3326315,15.6970149 22.1936843,16.6380675 21.9094737,17.5285939 C21.3347368,19.353857 20.1726316,20.9643833 18.6189473,22.0822781 C18.0568422,22.4864886 17.4694737,22.8085939 16.8568421,23.0675412 C20.6589474,20.1685939 21.4736843,14.673857 18.6568421,10.7706991 C16.2378948,7.41701491 11.9242105,6.35596228 8.24210527,7.81490965 C8.24210527,7.81490965 7.55368421,8.0801728 7.40842106,8.15596228 C7.41473684,8.1433307 6.10105263,4.94754123 6.10105263,4.94754123 C7.03578948,4.60648859 8.04631579,4.41701491 9.02526316,4.29069912 C11.3115789,3.99385701 13.7052632,4.17701491 15.8336843,5.10543596 C17.3052632,5.74964649 18.631579,6.8233307 19.6673685,8.04859386 C20.6589474,9.22964649 21.4357895,10.625436 21.8968422,12.1159623 Z M21.8021053,9.15999985 C24.7452632,10.9726314 26.7031579,14.2189472 26.6905263,17.9326314 C26.6905263,23.6168419 22.08,28.2273682 16.3957895,28.2273682 C10.7115789,28.2273682 6.10105263,23.6231577 6.10105263,17.9326314 C6.10105263,14.6231577 7.66105263,11.6863156 10.08,9.80421038 C8.58947368,11.3515788 7.66736842,13.4610524 7.66736842,15.785263 C7.66736842,20.5473682 11.5263158,24.4063156 16.2884211,24.4063156 C21.0505263,24.4063156 24.9094737,20.5473682 24.9094737,15.785263 C24.9094737,13.1199998 23.6968421,10.7389472 21.8021053,9.15999985 Z' id='path-1' fill='%234a4a4a' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
}
.mf-connect .mf-icon__medfusion-twirl.mf-color__inverse {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg width='32px' height='32px' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M21.8968422,12.1159623 C22.1621052,12.9685939 22.3136843,13.8591202 22.3263158,14.7622781 C22.3326315,15.6970149 22.1936843,16.6380675 21.9094737,17.5285939 C21.3347368,19.353857 20.1726316,20.9643833 18.6189473,22.0822781 C18.0568422,22.4864886 17.4694737,22.8085939 16.8568421,23.0675412 C20.6589474,20.1685939 21.4736843,14.673857 18.6568421,10.7706991 C16.2378948,7.41701491 11.9242105,6.35596228 8.24210527,7.81490965 C8.24210527,7.81490965 7.55368421,8.0801728 7.40842106,8.15596228 C7.41473684,8.1433307 6.10105263,4.94754123 6.10105263,4.94754123 C7.03578948,4.60648859 8.04631579,4.41701491 9.02526316,4.29069912 C11.3115789,3.99385701 13.7052632,4.17701491 15.8336843,5.10543596 C17.3052632,5.74964649 18.631579,6.8233307 19.6673685,8.04859386 C20.6589474,9.22964649 21.4357895,10.625436 21.8968422,12.1159623 Z M21.8021053,9.15999985 C24.7452632,10.9726314 26.7031579,14.2189472 26.6905263,17.9326314 C26.6905263,23.6168419 22.08,28.2273682 16.3957895,28.2273682 C10.7115789,28.2273682 6.10105263,23.6231577 6.10105263,17.9326314 C6.10105263,14.6231577 7.66105263,11.6863156 10.08,9.80421038 C8.58947368,11.3515788 7.66736842,13.4610524 7.66736842,15.785263 C7.66736842,20.5473682 11.5263158,24.4063156 16.2884211,24.4063156 C21.0505263,24.4063156 24.9094737,20.5473682 24.9094737,15.785263 C24.9094737,13.1199998 23.6968421,10.7389472 21.8021053,9.15999985 Z' id='path-1' fill='%23ffffff' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
}
.mf-connect .mf-connect-btn {
  width: 275px;
  padding-left: 64px;
  text-align: left;
  position: relative;
}
.mf-connect .mf-connect-btn .mf-icon {
  position: absolute;
  top: 8px;
  left: 24px;
}

:root {
  --font-secondary: "Overpass";
}

.table {
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 20px;
  width: 100%;
}
.table.table-records {
  width: 98%;
}
.table__header {
  background-color: #f7f7f7;
}
.table__header th {
  border-bottom: 0;
}
.table thead th {
  border-bottom: 1px solid #dee2e6;
}
.table-responsive {
  padding: 0 8px;
}
@media screen and (max-width: 680px) {
  .table--responsive {
    border: 0;
  }
  .table--responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .table--responsive tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
  .table--responsive td {
    display: block;
    text-align: right;
    width: 100% !important;
  }
  .table--responsive td::before {
    content: attr(data-bs-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  .table--responsive td:last-child {
    border-bottom: 0;
  }
}
.table--responsive .ph-item {
  border: 0;
  padding: 0;
  margin: 0;
}
.table--responsive .ph-item div {
  padding: 0;
}
.table--responsive .ph-item .ph-col-8 {
  padding: 0;
}
.table--responsive .ph-item .ph-row {
  margin-bottom: 0;
  padding: 0;
}
.table--responsive .ph-item .ph-row .ph-col-6 {
  margin-bottom: 0;
}
.table.no-records {
  border: 0 !important;
}
.table.no-records td {
  position: absolute;
  left: 38%;
  border: none;
  color: #ccc;
  font-size: 20px;
}

.disabled {
  pointer-events: none;
  color: lightgray !important;
}

.active:not(.animated) {
  font-weight: bold;
}

.table-responsive .profile-table {
  font-family: var(--font-secondary);
}
.table-responsive .profile-table td,
.table-responsive .profile-table th {
  font-weight: 200;
  font-size: 16px;
  height: 39px;
  line-height: 24px;
}

.authorizations-table th {
  border-bottom-width: 2px !important;
  font-family: sans-serif !important;
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;
}

:root {
  --font-secondary: "Overpass";
}

.onboarding .dashboard-container {
  background-color: #fafaf9 !important;
  padding: 48px 16px 168px !important;
}
.onboarding__steps {
  margin: 0;
  padding: 0;
}
.onboarding__step {
  display: flex;
  margin-bottom: 48px;
}
.onboarding__step:last-child {
  margin-bottom: 0;
}
.onboarding .step__img {
  background-color: #FFFFFF;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  height: 178px;
  flex: 0 0 316px;
  margin-right: 48px;
}
.onboarding .step__name {
  color: #f0ad4e;
  font-family: var(--font-secondary);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin: 0 0 16px;
}
.onboarding .step__description {
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
.onboarding__actions {
  display: flex;
  justify-content: flex-end;
}
.onboarding__actions .btn {
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: center;
  outline: none;
  padding: 0;
}
.onboarding__actions .btn-get-started {
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 20px;
  font-weight: 500;
  margin-right: 19px;
}
.onboarding__actions .btn-search {
  background-color: #f0ad4e;
  border: 1px solid #eea236;
  border-radius: 5px;
  color: #FFFFFF;
  font-family: var(--font-secondary);
  font-size: 18px;
  width: 272px;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  left: 44%;
  top: 50%;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #f69343;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}

.dashboard-container {
  min-height: calc(100vh - 185px);
}

@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}
@media all and (max-width: 762px) {
  .onboarding__step {
    display: block;
  }
  .onboarding__actions {
    display: block;
  }
  .onboarding .step__img {
    margin-right: 0;
    margin-bottom: 25px;
  }
  .onboarding .step__info {
    text-align: center;
  }
  .onboarding .card__footer {
    padding: 0;
  }
  .onboarding #mfConnectBtn {
    width: 100%;
  }
}
.patient-list .title {
  float: left;
  color: #F0AD4E;
}
.patient-list .remove {
  text-align: right;
}
.patient-list .remove .removed {
  margin-right: 10px;
  color: #999999;
}
.patient-list a {
  color: #4e92df !important;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
.patient-list .table {
  margin: 1rem 0 !important;
  border-bottom: 1px solid #ccc;
}
.patient-list .table__header th {
  font-weight: bold;
}
.patient-list .filter {
  text-align: justify;
  float: right;
}
.patient-list .filter .search-icon {
  position: relative;
  right: 220px;
  top: 2px;
  font-size: 12px;
}
.patient-list .filter__input {
  width: 230px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ccc;
  font-size: 12px;
  padding: 6px 6px 6px 30px;
}
.patient-list .filter__select {
  display: inline-block;
  font-size: 12px;
  position: relative;
  margin: 10px 0px 10px 0;
}
@media screen and (max-width: 567px) {
  .patient-list .filter__select button {
    margin-right: 0 !important;
  }
}
.patient-list .filter__select select {
  -webkit-appearance: none;
  padding: 5px 40px 5px 7px;
  margin-left: 10px;
  background: white;
}
.patient-list .filter__select i {
  position: absolute;
  font-size: 8px;
  top: 9px;
  right: 15px;
}
.patient-list .filter__invitations {
  font-size: 13px;
  word-wrap: break-word;
  white-space: nowrap;
}

.invitations-list .title {
  color: #F0AD4E;
}
.invitations-list .bold {
  font-weight: bold;
}
.invitations-list .ta-right {
  text-align: right;
}
.invitations-list a {
  color: #4e92df !important;
  cursor: pointer;
  text-decoration: none;
}
.invitations-list a.decline {
  color: black !important;
  margin-right: 10px;
}
.invitations-list .decision {
  color: #999999 !important;
  margin-right: 10px;
}
.invitations-list .link-color {
  color: #4e92df;
}
.invitations-list .back {
  font-weight: normal;
  font-size: 14px;
  display: block;
  margin-bottom: 20px;
}
.invitations-list .back svg {
  width: 20px;
  position: relative;
  top: 8px;
}
.invitations-list .table {
  margin: 0.5rem 0 !important;
  border-bottom: 1px solid #ccc;
}
.invitations-list .table__header th {
  font-weight: bold;
}
.invitations-list .table .provider {
  width: 410px;
}
.invitations-list .user-info {
  width: 100%;
  margin-bottom: 15px;
  font-weight: lighter;
}
.invitations-list .user-info__header {
  font-size: 13px;
  color: #aaa;
}
.invitations-list .user-info__body {
  font-size: 14px;
}
.invitations-list .record-grid a {
  color: white !important;
}

.patients.ph-item {
  border: 0;
  padding: 0;
}
.patients.ph-item .ph-col-12 {
  padding: 0;
}

:root {
  --color-primary: #1b3663;
  --color-secondary: #f78920;
  --font-primary: "Fjalla One";
  --font-secondary: "Overpass";
}

.providers-container {
  margin: 17px;
}

.provider-list {
  min-height: 465px;
}
.provider-list .title {
  float: left;
  color: var(--color-secondary);
  font-family: var(--font-secondary);
  font-size: 34px;
  font-weight: 300;
  line-height: 40px;
}
.provider-list a {
  color: #4e92df !important;
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
}
.provider-list .table {
  margin: 1rem 0 !important;
  border-bottom: 1px solid #ccc;
}
.provider-list .table__header th {
  font-weight: bold;
}
.provider-list .link-color {
  color: #4e92df;
}
.provider-list .add-provider {
  text-align: right;
  margin-bottom: 20px;
  float: right;
}
.provider-list .add-provider.filter {
  margin-top: 10px;
}
.provider-list .add-provider button {
  border: none;
  outline: none;
  color: white;
  background: transparent;
  position: relative;
  font-size: 14px;
  cursor: pointer;
}
.provider-list .add-provider button svg {
  position: absolute;
  left: -20px;
  top: 2px;
}
.provider-list .add-provider i {
  color: #ffffff;
  position: relative;
  right: 35px;
}
.provider-list .add-provider .table {
  margin: 1rem 0 !important;
  border-bottom: 1px solid #ccc;
}
.provider-list .add-provider .table__header th {
  font-weight: bold;
}
.provider-list .add-provider .link-color {
  color: #4e92df;
}
.provider-list .records-container-loading {
  margin: 115px auto;
  width: 150px;
}
.provider-list .records-container-loading img {
  height: auto;
  min-width: 150px;
}

button.add-provider {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
}

@media all and (max-width: 420px) {
  .add-provider {
    display: block;
    clear: both;
    text-align: left !important;
    margin-left: 20px;
  }
}
.providers-table .providers-td-0 {
  width: 16%;
}
.providers-table .providers-td-1 {
  width: 20%;
}
.providers-table .providers-td-2 {
  width: 20%;
}
.providers-table .providers-td-4 {
  width: 15%;
}

@media screen and (max-width: 680px) {
  .providers-table td div {
    margin-left: 35%;
  }
}
:root {
  --font-secondary: "Overpass";
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

a.list-link {
  text-decoration: none;
  color: black;
  height: 100%;
  position: absolute;
  right: 5%;
  top: calc(50% - 7px);
}
a.list-link i.list-arrow {
  bottom: 0;
}

.register-details .table-body .table-row-options .state.range-values {
  margin-bottom: 20px;
  width: auto;
  margin-right: 169px;
  width: 160px;
  height: auto;
  word-break: break-all;
  display: table-cell;
}

.non-unit-container {
  clear: both;
}

.dropdown.show button::after {
  transform: rotate(180deg);
}

.rotated-tooltip {
  top: 30px !important;
  bottom: unset !important;
  z-index: 0 !important;
  box-shadow: #ccc 5px -3px 10px !important;
}
.rotated-tooltip:after {
  left: 55px;
  bottom: 100%;
  top: unset !important;
  transform: rotate(180deg);
}

.dropdown-menu.show {
  width: calc(100% - 0.5rem);
  min-width: unset !important;
}

.sort-by {
  margin-left: 24px;
}
.sort-by button.sort-by-btn:after {
  left: 135px;
}
.sort-by .dropdown-menu.show {
  width: 67%;
  min-width: unset !important;
  transform: translate3d(48px, 39px, 0px) !important;
}

.d-flex p.description {
  width: auto !important;
  max-width: 740px;
  height: auto !important;
}

.modal-content:not(.help, .re-auth) {
  background-color: #fff;
  border-radius: 5px;
  min-height: 300px;
  margin: 0 auto;
  padding: 30px;
}

.responsive-header .date {
  width: 9.5%;
  font-weight: bold;
}
.responsive-header .name {
  width: 33.8% !important;
}
.responsive-header .doctor {
  width: 15.8% !important;
}
.responsive-header .facility {
  width: 25% !important;
}

.responsive-row-show {
  display: none;
}

.link-styles {
  color: #4a90e2;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  position: relative;
  top: 1px;
  cursor: pointer;
}

.no-records {
  width: 100%;
  text-align: center;
  margin-top: 19px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
}

.no-records-cards {
  color: #ccc;
  font-size: 45px;
  margin: auto;
}

.header-filters .dropdown-toggle:after {
  position: absolute;
  left: 125px;
  top: 22px;
}
.header-filters .dropdown-toggle.filter-by-btn:after {
  position: absolute;
  left: auto;
  right: 15px;
}

.dropdown-menu {
  min-width: 8rem !important;
}

.dropdown-item {
  height: 40px;
  font-size: 12px !important;
  padding: 0 10px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.records-grid {
  display: inline-block !important;
}
div.records-grid > div {
  float: left;
  margin: 0 8px;
}

.no-records svg {
  width: 40px;
  margin: 20px auto;
}

.card-labs_and_test_results-title-container {
  max-width: 65%;
}

.record-title-labs_and_test_results {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mr-records {
  margin-right: auto !important;
}

.facilities-btn {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.input-container {
  position: relative;
  top: -5px;
}

.back-btn {
  position: relative;
}
.back-btn span {
  position: relative;
  top: -5px;
  height: 21px;
  width: 32px;
  color: #ffffff;
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 20px;
}
.back-btn svg {
  position: relative;
  top: 2px;
}

.pl-14 {
  padding-left: 18px;
}

.record-filters {
  width: 70%;
  margin-top: 0px;
  padding: 10px 0px;
  margin-right: 7px;
  float: right;
  text-align: right;
}
.record-filters .mr-20 {
  margin-right: 24px;
}
.record-filters .dropdown {
  position: relative;
}
.record-filters i.search-icon {
  font-size: 12px;
  position: absolute;
  left: 4.5%;
  top: -4px;
}
.record-filters .select-icon {
  font-size: 5px;
  position: absolute;
  margin-left: 26%;
  top: 2px;
}

.record-widget-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contacts {
  margin-right: 17px;
}

.listview-icon {
  position: relative;
  left: 8px;
}

.share-with {
  position: relative;
  left: 4px;
  top: 1px;
}

.record-header {
  font-family: var(--font-secondary);
  font-size: 34px;
  font-weight: 300 !important;
  line-height: 40px;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 3px 3px 0 0;
}

.record-title {
  display: inline-block;
  width: auto;
  margin-top: 10px;
  min-width: 220px;
}

.record-filters {
  display: inline-block;
}
.record-filters > div {
  display: inline-block;
}

.backdrop {
  position: fixed;
  z-index: 1030;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
}

.manage-records .actions-container.filter {
  width: auto;
}
.manage-records .actions-container .back {
  float: left;
}
.manage-records .actions-container .btn {
  float: right;
  box-shadow: none;
}
.manage-records .actions-container .btn.share {
  font-size: 13px;
  color: #5194dc;
  font-weight: bold;
  position: relative;
}
.manage-records .actions-container .btn.share svg {
  position: absolute;
  right: 110px;
  width: 18px;
  top: 2px;
}
.manage-records .title {
  clear: both;
}

.info-icon {
  position: relative;
  z-index: 2 !important;
}

.register-details .location-table {
  margin-top: 23px;
}
.register-details .download-container {
  float: right;
  margin-right: 20px;
  color: #4e92df;
  font-size: 14px;
  font-weight: bold;
}
.register-details .download-container label {
  cursor: pointer;
}
.register-details .download-container i {
  margin-right: 5px;
  font-size: 17px;
  top: 3px;
  color: #a6c9ef;
  position: relative;
}
.register-details .add-notes {
  cursor: pointer;
  color: #4e92df;
  font-size: 14px;
  font-weight: bold;
  margin-top: 14px;
}
.register-details .add-notes svg {
  margin-right: 9px;
  width: 15px;
  left: 3px;
  color: #a6c9ef;
  top: 5px;
  position: relative;
}
.register-details .toggle-info {
  margin-left: 15px;
  -webkit-appearance: none;
  border-radius: 5px;
  font-size: 12px;
  color: #918f90;
  width: 25px;
  height: 25px;
  outline: none;
  cursor: pointer;
  background: white;
  position: relative;
  border: 1px solid #ccc;
  bottom: 5px;
  padding: 0;
}
.register-details .toggle-info i {
  width: 11.5px;
  margin: 0 auto;
  float: none;
  display: block;
  position: relative;
  top: 2.5px;
}
.register-details .medication-left {
  width: 35%;
  min-width: 350px;
  display: inline-block;
}
.register-details .table-body {
  list-style: none;
  padding: 0;
}
.register-details .table-body li {
  border-bottom: 1px solid #ddd;
  padding: 12px 25px;
  font-family: var(--font-secondary);
  font-size: 14px;
  clear: both;
}
.register-details .table-body .table-row .table-col {
  display: inline-block;
  margin-right: 21px;
  font-size: 14px;
  min-width: 100px;
  word-wrap: break-word;
}
.register-details .table-body .table-row .table-col.date {
  font-weight: bold;
  font-size: 14px;
  width: 10%;
  min-width: unset;
}
.register-details .table-body .table-row .table-col.name {
  width: 34% !important;
}
.register-details .table-body .table-row .table-col.doctor {
  width: 16% !important;
}
.register-details .table-body .table-row .table-col.facility {
  width: 25% !important;
}
.register-details .table-body .table-row-options {
  margin-top: 25px;
}
.register-details .table-body .table-row-options .state {
  display: block;
  margin-right: 15%;
  position: relative;
  z-index: 1;
  width: 100%;
}
.register-details .table-body .table-row-options .state .count {
  color: #64a0e3;
  font-weight: bold;
  position: relative;
  width: auto;
  text-align: left;
}
.register-details .table-body .table-row-options .state .count span {
  border-bottom: dotted 1px #ccc;
}
.register-details .table-body .table-row-options .state .count.out {
  color: #d75452;
}
.register-details .table-body .table-row-options .state .name {
  margin-top: 7px;
  color: #959595;
  font-size: 12px;
  text-transform: capitalize;
  max-width: 160px;
}
.register-details .table-body .table-row-options .state .state-tooltip {
  display: none;
  position: absolute;
  width: fit-content;
  min-width: 125px;
  bottom: 50px;
  left: -25px;
  background: white;
  border: 1px solid #ccc;
  padding: 15px;
  color: black;
  font-size: 14px;
  border-radius: 5px;
  -webkit-box-shadow: #ccc 1px 5px 10px;
  box-shadow: #ccc 1px 5px 10px;
  z-index: -1;
}
.register-details .table-body .no-records {
  text-align: center;
  font-size: 25px;
  color: #ccc;
}

.edit-modal .modal-content {
  border: none;
  padding: 30px 23px 23px 23px;
  max-width: 588px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.2);
}
.edit-modal .modal-content h2 {
  margin-bottom: 24px;
  font-family: var(--font-secondary);
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}
.edit-modal .modal-content .btn-primary {
  font-weight: 300;
  font-size: 14px;
  padding: 8px 15px;
  font-family: var(--font-secondary);
  letter-spacing: 0.5px;
  width: 68px;
  border: 1px solid var(--color-secondary-light);
  border-radius: 4px;
  background-color: var(--color-secondary);
  color: #ffffff;
  line-height: 20px;
}
.edit-modal .modal-content .btn-primary:active {
  border: 1px solid var(--color-secondary-light);
  background-color: var(--color-secondary-light);
}
.edit-modal .modal-content .btn-primary:focus:active {
  box-shadow: 0 0 0 0.2rem var(--color-secondary-light);
}
.edit-modal .modal-content .btn-default {
  font-size: 14px;
  padding: 9px 16px;
  margin-left: 7px;
  color: #212121;
  height: 38px;
  margin-top: 2px;
  width: 75px;
  line-height: 2px;
}
.edit-modal .modal-content .select-records-container {
  color: #373a3c;
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
}
.edit-modal .modal-content .select-records {
  margin-bottom: 7px;
}
.edit-modal .modal-content .checkbox-container {
  padding: 13px 50px 8px 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 24px;
  padding-bottom: 0;
}
.edit-modal .modal-content .checkbox-container ul {
  margin-left: 8px;
}
.edit-modal .modal-content .checkbox-container input.checkbox {
  display: none;
}
.edit-modal .modal-content .checkbox-container label {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  line-height: 20px;
  margin: 5px;
}
.edit-modal .modal-content .checkbox-container input + label:before {
  line-height: 20px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 2px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 2px;
}
.edit-modal .modal-content .checkbox-container input[type=checkbox]:checked + label:before {
  content: "✓";
  color: #666666;
  text-align: center;
  line-height: 12px;
}
.edit-modal .modal-content .checkbox-container label:hover {
  cursor: pointer;
}
.edit-modal .modal-content .error-msg {
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 26px;
  background: #f2dede;
  color: #a94442;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #eed5d5;
}
.edit-modal .modal-content .email-container label {
  color: #373a3c;
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
}
.edit-modal .modal-content .email-container .email-input {
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  display: block;
  outline: none;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 16px;
  font-size: 16px;
  font-family: var(--font-secondary);
  line-height: 24px;
  margin-bottom: 28px;
}
.edit-modal .modal-content .email-container .email-input::placeholder {
  height: 25px;
  width: 81px;
  color: #999999;
  font-family: var(--font-secondary);
  font-size: 16px;
  line-height: 24px;
}
.edit-modal ul.checkbox {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  list-style: none;
}
.edit-modal ul.checkbox li input {
  margin-right: 0.25em;
}
.edit-modal ul.checkbox li {
  border: 1px transparent solid;
  display: inline-block;
  width: 162px;
  margin-bottom: 8px;
  line-height: 31px;
}
.edit-modal ul.checkbox li:nth-child(odd) label:before {
  left: 0px;
}
.edit-modal ul.checkbox li:nth-child(even) label:before {
  left: -2px;
}
.edit-modal .btn-default {
  border: 1px solid #ccc;
  margin-left: 10px;
}
.edit-modal .btn-default.right {
  margin-left: calc(100% - 340px);
  width: auto;
}

.range-container {
  padding: 9px 13px;
  font-size: 14px;
  margin-bottom: 17px;
}
.range-container * {
  display: inline-block;
}
.range-container .circle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: black;
  margin-right: 8px;
}
.range-container .circle.blue {
  background-color: #64a0e3;
}
.range-container .circle.red {
  background-color: #d75452;
  margin-left: 28px;
}

.table-row-options.hospitalization .description p {
  padding: 5px 0px;
}
.table-row-options.hospitalization .description h2 {
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 30px;
}
.table-row-options .description {
  clear: both;
}
.table-row-options .description p {
  padding: 15px 5px;
}
.table-row-options .prescription-details {
  margin-top: 37px;
}
.table-row-options .prescription-details .left {
  float: left;
  width: 50%;
}
.table-row-options .prescription-details .top,
.table-row-options .prescription-details .bottom {
  display: table;
  border-collapse: separate;
  border-spacing: 5px;
}
.table-row-options .prescription-details .bottom {
  margin-top: 12px;
}
.table-row-options .prescription-details .right {
  width: auto;
  border-left: 1px solid #ccc;
  padding-left: 24px;
  padding-bottom: 35px;
  height: 113px;
  display: inline-block;
  margin-bottom: 30px;
}
.table-row-options .prescription-details .medication-left .info-tab {
  width: 137px;
}
.table-row-options .prescription-details .info-tab {
  display: table-cell;
  padding-right: 40px;
  white-space: pre-line;
  width: auto;
}
.table-row-options .prescription-details .info-tab span {
  text-transform: capitalize;
}
.table-row-options .prescription-details .info-tab label {
  color: #909090;
  display: block;
  font-size: 12px;
  font-weight: 200;
}
.table-row-options .normal-case span {
  text-transform: none !important;
}
.table-row-options .notes-container {
  border: 1px solid #ccc;
  padding: 8px 15px;
  padding-bottom: 15px;
  border-radius: 5px;
}
.table-row-options .notes-container .edit-note-icons {
  float: right;
}
.table-row-options .notes-container .edit-note-icons a {
  color: #918f90;
  padding: 6px 3px;
  cursor: pointer;
}
.table-row-options .notes-container .edit-note-icons a svg {
  width: 15px;
}
.table-row-options .notes-container label {
  font-weight: bold;
  margin-top: 10px;
  color: #949494;
  font-size: 14px;
}
.table-row-options .notes-container p {
  color: black;
  margin: 0;
  font-size: 12px;
}

.imaging-list {
  padding: 0;
  margin-left: 9px;
  margin-top: 8px;
}
.imaging-list .toggle-info {
  position: absolute;
  right: 15px;
  top: 15px;
}
.imaging-list li {
  position: relative;
  list-style: none;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  clear: both;
  padding-bottom: 25px;
  margin-bottom: 18px;
}
.imaging-list li .image-container {
  width: 140px;
  height: 140px;
  position: relative;
  float: left;
}
.imaging-list li .image-container img {
  width: 100%;
  height: 100%;
}
.imaging-list li .info-container {
  display: inline-block;
  margin-left: 13px;
}
.imaging-list li .info-container .date {
  font-size: 12px;
}
.imaging-list li .info-container h2 {
  font-size: 20px;
  margin-top: 13px;
}
.imaging-list li .info-container label {
  display: block;
  font-size: 14px;
  margin-bottom: 0px;
}

.notes-modal {
  min-width: 600px;
}
.notes-modal .modal-content {
  max-width: 600px;
}
.notes-modal label {
  display: block;
}
.notes-modal textarea {
  width: 530px;
  height: 140px;
  margin-bottom: 20px !important;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  font-size: 13px;
  outline: none;
}
.notes-modal h2 {
  font-size: 22px;
  margin-bottom: 25px;
}
.notes-modal h2 span {
  font-weight: 200;
}
.notes-modal .btn-default {
  margin-left: 15px;
  border: 1px solid #ccc;
}

.c-pointer {
  cursor: pointer;
}

.lighter {
  font-weight: 400 !important;
}

.list-arrow {
  position: relative;
  left: calc(100% - 48px);
  bottom: 24px;
  cursor: pointer;
  width: 15px;
}

@media all and (max-width: 1134px) {
  .sort-by {
    margin-left: 7px;
  }
}
@media (max-width: 515px) {
  .dropdown-menu.show {
    width: 75%;
  }
}
@media (max-width: 540px) {
  .record-filters .input-container input {
    width: 148px !important;
  }
  .sort-by {
    margin-left: 0;
  }
  .sort-by button {
    width: 100px !important;
  }
  .sort-by button.sort-by-btn:after {
    left: 130px !important;
  }
}
@media all and (max-width: 788px) {
  .record-filters {
    width: 70%;
    text-align: left;
    float: right;
    margin-left: 20px;
  }
}
@media all and (max-width: 700px) {
  .records-title .sort-by button.sort-by-btn:after {
    left: unset !important;
  }
  .records-title .sort-by .dropdown-menu.show {
    top: 0 !important;
    width: 66% !important;
    transform: translate3d(47px, 40px, 0px) !important;
  }
}
@media all and (max-width: 939px) and (min-width: 763px) {
  .dashboard-container .container {
    max-width: none;
  }
  .dashboard-container .justify-content-between {
    position: relative;
  }
  .dashboard-container .justify-content-between a {
    float: right;
  }
  .dashboard-container .justify-content-between .align-items-start {
    width: 45% !important;
    margin-bottom: 2%;
    margin-right: 2%;
    float: left;
  }
  .dashboard-container .justify-content-between .align-items-start .p-2.w-100.body {
    height: 208px !important;
  }
  .dashboard-container .justify-content-between .align-items-start.responsive {
    position: absolute;
    right: 6%;
    bottom: 280px;
  }
}
@media all and (max-width: 810px) and (min-width: 763px) {
  .dashboard-container .justify-content-between .record-title-inmunizations {
    font-size: 14px !important;
  }
  .dashboard-container .justify-content-between .select-icon {
    font-size: 5px;
    position: absolute;
    margin-left: -5%;
    top: 8px;
  }
}
@media all and (max-width: 940px) {
  .prescription-details .right {
    display: block !important;
    float: none !important;
    position: unset !important;
    border: 0 !important;
    padding-left: 5px !important;
  }
}
@media all and (max-width: 1040px) {
  .record-filters .mr-20 {
    margin-right: 0 !important;
  }
}
@media all and (max-width: 1280px) and (min-width: 940px) {
  .records-header {
    height: auto !important;
    display: block !important;
  }
  .card-labs_and_test_results-title-container {
    max-width: 100%;
  }
  .viewAll {
    left: 33%;
  }
}
@media all and (max-width: 885px) and (min-width: 768px) {
  .records-header {
    height: auto !important;
    display: block !important;
  }
  .card-labs_and_test_results-title-container {
    max-width: 100%;
  }
  .viewAll {
    float: none !important;
    left: 33%;
  }
}
@media all and (max-width: 430px) {
  .records-header {
    height: auto !important;
    display: block !important;
  }
  .card-labs_and_test_results-title-container {
    max-width: 100%;
  }
  .viewAll {
    float: none !important;
    left: 33%;
  }
  .no-records-cards {
    color: #ccc;
    font-size: 20px;
    margin: auto;
  }
}
@media all and (max-width: 1211px) {
  .record-title {
    float: left;
  }
  .record-header.justify-content-between.align-items-center {
    display: block !important;
    height: auto !important;
  }
  .record-header.justify-content-between.align-items-center > .record-filters {
    width: 100% !important;
  }
  .record-filters .doctors-filter-list {
    left: 60% !important;
    top: 45px;
  }
  .record-filters .facilities-filter-list {
    left: 32% !important;
    top: 45px;
  }
  .record-filters .select-icon {
    font-size: 5px;
    position: absolute;
    margin-left: -5%;
    top: 10px;
  }
  .record-filters li {
    width: 148px;
  }
  .mr-records {
    display: block;
    width: 100%;
    height: 60px;
  }
  .mr-records .record-header {
    float: none;
  }
  .justify-content-between:not(.sticky-top) {
    position: relative;
  }
  .record-header {
    display: block !important;
  }
  .register-details .download-container label {
    margin-top: 10px;
  }
  .responsive-row-hide {
    display: none;
  }
  .responsive-row-show {
    position: relative;
    display: block;
  }
  .responsive-row-show span {
    font-weight: bold;
  }
  .toggle-info {
    position: absolute;
    float: none;
    top: 0;
    right: 0;
  }
  .table-col-responsive.buttons * {
    float: none;
    display: inline-block;
  }
  .responsive-header {
    display: none !important;
  }
}
@media all and (max-width: 870px) {
  .records-title {
    display: block !important;
  }
  .records-title .mx-3 {
    display: none;
  }
}
@media all and (max-width: 475px) {
  .actions-container {
    display: block !important;
  }
  .share-record {
    margin-left: 5px !important;
    margin-top: 10px;
  }
  .list-arrow {
    position: relative;
    left: 110%;
    bottom: 24px;
    cursor: pointer;
    width: 15px;
  }
}
.dashboard-container p {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  width: 100% !important;
}
.dashboard-container p.info-container {
  max-height: 115px;
  overflow: auto;
}
.dashboard-container .align-items-center .mb-0.w-80 {
  width: 100px;
}
.dashboard-container .align-items-center .p-2.w-100 {
  width: calc(100% - 35px) !important;
  position: relative;
}
.dashboard-container .list-arrow {
  position: relative;
  left: 100%;
  bottom: 24px;
  cursor: pointer;
  width: 15px;
  font-size: 12px;
}

@media all and (max-width: 768px) {
  .table-row-options .prescription-details .info-tab {
    display: inline-block;
    margin: 5px 0;
  }
}
@media all and (max-width: 762px) {
  .dashboard-container .container {
    max-width: none;
  }
  .dashboard-container .justify-content-between {
    position: relative;
  }
  .dashboard-container .justify-content-between.d-flex.flex-row.p-1 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .dashboard-container .justify-content-between .mr-records {
    display: block;
    width: 100%;
  }
  .dashboard-container .justify-content-between a {
    float: right;
  }
  .dashboard-container .justify-content-between .align-items-start {
    width: 80% !important;
    margin-left: 8%;
    margin-bottom: 15px;
  }
  .dashboard-container .justify-content-between .align-items-start.responsive {
    position: unset;
  }
}
.imaging-placeholder .ph-picture {
  width: 70px;
  height: 70px;
}
.imaging-placeholder .picture-desc {
  width: 100%;
  position: absolute;
  left: 81px;
  top: 100px;
}

.listview-container {
  display: table !important;
}

.listview-header {
  display: table-cell !important;
  position: relative;
}
.listview-header i {
  position: absolute;
  top: 35%;
  left: 15px;
}

.unit-values-container {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;
}
.unit-values-container .labs-details-row {
  display: table-row;
  width: 100%;
  margin-bottom: 12px;
}

@media all and (max-width: 520px) {
  .unit-values-container {
    display: block;
    width: 100%;
  }
  .unit-values-container .labs-details-row {
    display: block;
    width: 100%;
  }
  .register-details .table-body .table-row-options .state.range-values {
    display: block;
  }
  .loading-logo {
    min-width: 150px;
  }
}
.ph-item.ph-cards.noanimate::before {
  animation: none;
}

.shr-inner-container .loading-logo {
  min-width: 150px;
}

@media all and (max-width: 340px) {
  .header-filters .dropdown-toggle.filter-by-btn:after {
    position: absolute;
    top: auto;
    right: 60px;
  }
}
:root {
  --font-secondary: "Overpass";
}

.user-index {
  margin-top: 17px !important;
}
.user-index .table td {
  font-weight: normal;
  width: 200px;
}
.user-index .btn {
  font-size: 12px;
}
.user-index .c-pointer {
  cursor: pointer;
}
.user-index .filter-btn {
  width: 120px;
  height: 30px !important;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 10px;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(166, 166, 166);
  text-align: left;
}
.user-index .filter-btn::after {
  position: absolute;
  left: 85%;
  top: 14px;
}
.user-index .display-name-container {
  float: left;
}
.user-index .sort-icn-container {
  display: block;
  position: relative;
  bottom: 4px;
  left: 5px;
}
.user-index .sort-icn-container svg {
  display: block;
  cursor: pointer;
  margin-bottom: -9px;
  color: #aaa;
}
.user-index .sort-icn-container svg.active {
  color: black;
}
.user-index div.filter-by {
  min-width: 120px !important;
}
.user-index .responsive-graph-container {
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.user-index .responsive-graph-container .responsive-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 8px;
}
.user-index .graph-container {
  margin-top: 12px;
  position: relative;
  right: 7px;
  color: #e1e1e1;
}
.user-index h3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: 0px;
}
.user-index .user-graph-container {
  margin-top: 20px;
  overflow: hidden;
}
.user-index .user-graph-container .container {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 20px 10px 15px 25px;
  position: relative;
}
.user-index .user-graph-container .container h3 {
  letter-spacing: 0px;
  display: inline-block;
}
.user-index .user-graph-container .container select {
  float: right;
  -webkit-appearance: none;
  padding: 5px 40px 5px 7px;
  margin-right: 12px;
  background: white;
  font-size: 12px;
  cursor: pointer;
}
.user-index .user-graph-container .container i {
  position: absolute;
  font-size: 8px;
  top: 30px;
  right: 30px;
}
.user-index .user-graph-container .container.left {
  float: left;
  width: 49%;
  margin-right: 2%;
}
.user-index .user-graph-container .container.right {
  float: right;
  width: 49%;
}
.user-index .user-graph-container .container.right .graph-container {
  margin-top: 12px;
  position: relative;
  right: 7px;
  color: #e1e1e1;
}
.user-index .provider-graph-container {
  clear: both;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 20px 10px 5px 25px;
  margin-top: 12px;
  position: relative;
  margin-top: 15px;
}
.user-index .provider-graph-container .responsive-graph-container {
  padding-bottom: 26%;
}
.user-index .provider-graph-container .connections-info {
  margin-bottom: 20px;
}
.user-index .provider-graph-container .connections-info .connections {
  display: inline-block;
}
.user-index .provider-graph-container .connections-info .connections .number {
  font-size: 35px;
  color: #64a0e3;
  font-weight: 200;
}
.user-index .provider-graph-container .connections-info .connections .this-week {
  font-size: 14px;
}
.user-index .provider-graph-container .connections-info .connections .this-week span {
  color: #ccc;
}
.user-index .provider-graph-container .connections-info .errors {
  display: inline-block;
  margin-left: 25px;
  font-size: 14px;
}
.user-index .provider-graph-container .connections-info .errors .number {
  font-size: 35px;
  color: #d75452;
  font-weight: 200;
}
.user-index .patients-by-location {
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 20px 10px 5px 25px;
  margin-top: 12px;
}
.user-index .patients-by-location .table-header span {
  display: inline-block;
  margin-right: 85px;
}
.user-index .patients-by-location .location-table {
  margin-top: 23px;
}
.user-index .patients-by-location .table-header {
  background: #f7f7f7;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
}
.user-index .patients-by-location .table-body {
  list-style: none;
  padding: 0;
}
.user-index .patients-by-location .table-body li {
  border-bottom: 1px solid #ddd;
  padding: 13px 15px;
}
.user-index .patients-by-location .table-body .table-row .toggle-info {
  float: right;
  -webkit-appearance: none;
  border-radius: 5px;
  font-size: 12px;
  color: #918f90;
  width: 25px;
  height: 25px;
  outline: none;
  cursor: pointer;
  position: relative;
}
.user-index .patients-by-location .table-body .table-row .toggle-info i {
  position: absolute;
  top: 4px;
  right: 6.5px;
}
.user-index .patients-by-location .table-body .table-row .table-col {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
  min-width: 100px;
}
.user-index .patients-by-location .table-body .table-row .table-col.name {
  font-weight: bold;
  margin-right: 42px;
}
.user-index .patients-by-location .table-body .table-row-options {
  margin-top: 25px;
}
.user-index .patients-by-location .table-body .table-row-options .state {
  display: inline-block;
  margin-right: 56px;
}
.user-index .patients-by-location .table-body .table-row-options .state .count {
  color: #64a0e3;
  font-weight: bold;
}
.user-index .patients-by-location .table-body .table-row-options .state .name {
  margin-top: 7px;
  font-size: 12px;
  max-width: 160px;
}
.user-index .info-box {
  width: calc(33.3333333333% - 15px);
  display: inline-block;
  padding: 15px 25px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin-left: 20px;
}
.user-index .info-box:first-of-type {
  margin: 0;
}
.user-index .info-box .info-count {
  color: #4e92df;
  font-size: 35px;
  letter-spacing: 1.5px;
  font-weight: 200;
  margin-bottom: 0px;
}
.user-index .info-box .info-this-week span {
  font-size: 13px;
  margin-right: 10px;
  color: #5fb760;
}
.user-index .info-box .info-this-week span.error {
  color: #e17d7c;
}
.user-index circle {
  cursor: pointer;
}
.user-index .recharts-default-tooltip {
  border-radius: 5px;
}
.user-index .recharts-tooltip-label {
  font-size: 14px;
  color: black;
}
.user-index .recharts-tooltip-item-list {
  font-size: 12px;
  text-transform: capitalize;
}
@media screen and (max-width: 655px) {
  .user-index .info-box {
    width: 100%;
    display: block;
    margin-left: 0;
    text-align: center;
    margin-top: 20px;
  }
  .user-index .user-graph-container .container {
    float: none !important;
    width: 100% !important;
    margin-bottom: 20px;
  }
}
.user-index .success {
  color: #5cb85c;
}
.user-index .error {
  color: #d9534f;
  opacity: 0.5;
}
.user-index .disabled:not(.page-item) {
  cursor: default;
  position: relative;
  background: lightgray;
  pointer-events: all !important;
  color: darkgray !important;
  opacity: 1;
}
.user-index .disabled:not(.page-item) span:not(.tooltiptext):not(.page-link) {
  border-bottom: 1px dotted black;
}
.user-index .tooltip span,
.user-index .tooltip-down-arrow span {
  position: absolute;
  display: inline-block;
  border-radius: 5px;
  visibility: visible;
  -webkit-box-shadow: #ccc 1px 5px 10px;
  box-shadow: #ccc 1px 5px 10px;
  z-index: -1;
  border: 1px solid #ccc;
  padding: 15px;
  right: 0px;
  bottom: 50px;
}
.user-index .tooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  bottom: 42px;
  right: 15px;
  z-index: 2;
}
@media all and (max-width: 680px) {
  .user-index .tooltip:after {
    left: 10.5rem;
  }
}
.user-index .tooltip .tooltiptext,
.user-index .tooltip-down-arrow .tooltiptext {
  visibility: hidden;
  width: 15rem;
  background-color: white;
  color: black;
  border-radius: 6px;
  padding: 5px 5px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
@media all and (max-width: 680px) {
  .user-index .tooltip .tooltiptext,
  .user-index .tooltip-down-arrow .tooltiptext {
    width: 15rem;
    left: 1.5rem;
  }
}
.user-index .tooltip-down-arrow .tooltiptext.switch-tooltiptext-position {
  top: 2.5rem;
  height: fit-content !important;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: 10vh;
}
.user-index .tooltip-down-arrow .tooltiptext.table-header-tooltip.switch-tooltiptext-position {
  top: 2rem;
}
.user-index span.tooltiptext.table-header-tooltip {
  padding: 10px 1px !important;
}
.user-index span.tooltip-down-arrow .tooltiptext.table-header-tooltip.switch-tooltiptext-position {
  padding: 10px 1px !important;
}
.user-index .tooltip-down-arrow:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
  bottom: 0.8rem;
  right: 20px;
  z-index: 2;
}
@media all and (max-width: 680px) {
  .user-index .tooltip-down-arrow:after {
    left: 11.2rem;
  }
}
.user-index .table-header.tooltip {
  top: 0px !important;
}
.user-index .show-tooltip:hover .tooltip span.tooltiptext {
  visibility: visible !important;
}
.user-index .show-tooltip:hover .tooltip-down-arrow {
  visibility: visible !important;
}
.user-index .disabled:hover .tooltiptext,
.user-index .show-tooltip:hover .tooltiptext,
.user-index .show-tooltip:hover .tooltiptext {
  visibility: visible !important;
  opacity: 1;
}
.user-index .disabled:hover .tooltip,
.user-index .show-tooltip:hover .tooltip,
.user-index .show-tooltip:hover .tooltip,
.user-index .show-tooltip:hover .tooltip-down-arrow {
  opacity: 1;
  background: transparent;
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  visibility: visible !important;
}
.user-index .table-header.tooltip .rotated-tooltip {
  top: 35px !important;
  bottom: unset !important;
  z-index: 0 !important;
  box-shadow: #ccc 1px -1px 10px !important;
}
.user-index .table-header.tooltip:after {
  top: 1.7rem;
  transform: rotate(180deg);
}
.user-index .tooltip.shift-right .tooltiptext {
  left: 0px;
  bottom: 2rem;
}
.user-index .tooltip.shift-right .rotated-tooltip {
  top: 0px !important;
  bottom: unset !important;
  z-index: 0 !important;
  box-shadow: #ccc 1px -1px 10px !important;
}
.user-index .tooltip.shift-right:after {
  bottom: 25px !important;
}
.user-index .tooltip.shift-right.shift-bottom:after {
  bottom: 8px !important;
  transform: rotate(180deg);
}

.actions-modal.disabled {
  color: #ccc !important;
  position: relative;
}
.actions-modal.disabled:focus {
  outline: none;
}
.actions-modal .tooltip,
.actions-modal .tooltip-down-arrow {
  position: absolute;
  display: inline-block;
  border-radius: 5px;
  visibility: visible;
  right: 70%;
  top: 40px;
}
.actions-modal .disabled:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.actions-modal .disabled:hover .tooltip {
  opacity: 1;
  background: transparent;
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  visibility: visible;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .actions-modal .modal-content {
      margin-top: 350px !important;
    }
  }
}
.actions-modal .modal-content {
  position: relative;
  top: calc(50% - 50px);
  top: -webkit-calc(50% - 50px);
  top: -mozkit-calc(50% - 50px);
  border: 0;
  transform: translateY(-50%);
  margin: 0px auto !important;
  padding: 28px 24px 24px 24px;
  min-height: 0;
  letter-spacing: 1px;
  height: auto;
  max-width: 588px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.2);
}
.actions-modal .modal-content .disabled {
  cursor: default;
  position: relative;
  pointer-events: all !important;
  color: #ccc !important;
}
.actions-modal .modal-content .disabled span:not(.tooltiptext) {
  border-bottom: 1px dotted black;
}
.actions-modal .modal-content .disabled:focus {
  outline: none;
}
.actions-modal .modal-content h2 {
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 25px;
  margin-top: 0px;
  text-shadow: 0 0 black;
  height: auto;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}
.actions-modal .modal-content .user-name {
  font-weight: 400;
  margin-right: 5px;
  height: 24px;
  width: 262px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
.actions-modal .modal-content .user-type {
  margin-left: -3px;
  letter-spacing: 0.5px;
}
@media all and (max-width: 320px) {
  .actions-modal .modal-content {
    top: 50%;
  }
}
.actions-modal .btn-default {
  border: 1px solid #ccc;
  margin-left: 10px;
}
.actions-modal .btn-default.right {
  margin-left: calc(100% - 340px);
}
.actions-modal .close-and-disable .close-btn {
  margin: 0;
  outline: none;
  height: 38px;
  width: 74px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  padding-top: 9px;
  padding-left: 14px;
}
.actions-modal .close-and-disable .modal-primary-button {
  height: 38px;
  width: 105px;
  border: 1px solid #0275d8;
  border-radius: 4px;
  background-color: #0275d8;
  margin-right: 10px;
}
.actions-modal .close-and-disable .modal-primary-button span {
  height: 21px;
  width: 75px;
  color: #ffffff;
}
.actions-modal .close-and-disable .modal-cancel-button {
  height: 38px;
  width: 74px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #ffffff;
}
.actions-modal .close-and-disable .modal-cancel-button span {
  height: 21px;
  width: 44px;
  color: rgba(0, 0, 0, 0.87);
}
.actions-modal .close-and-disable span {
  font-family: var(--font-secondary);
  font-size: 14px;
  line-height: 20px;
}
.actions-modal .close-and-disable button {
  margin: 0;
  outline: none;
}
.actions-modal .close-and-disable .disabled {
  cursor: default;
  position: relative;
  border-bottom: 1px dotted black;
  pointer-events: all !important;
  color: #ccc !important;
}
.actions-modal .close-and-disable .btn-link {
  font-size: 14px;
  padding: 0;
  margin-top: 9px;
  margin-right: -5px;
  float: right;
  color: black;
  text-decoration: none;
  opacity: 1;
}
.actions-modal .modal-form h2 {
  margin-bottom: 1px;
  padding-right: 24px;
}
.actions-modal .consent-modal-title {
  font-weight: 400;
  margin-right: 5px;
  height: 24px;
  width: 262px;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--font-secondary);
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 24px;
}
@media all and (max-width: 380px) {
  .actions-modal .consent-modal-title {
    width: 80%;
  }
}
.actions-modal .consent-modal-form {
  margin-top: 0px !important;
  letter-spacing: 0px;
  font-size: 18px;
  font-family: var(--font-secondary);
  margin-bottom: 0px;
}
.actions-modal .consent-modal-form > div:first-child {
  height: 46px;
}
.actions-modal .consent-modal-form > div:first-child > .custom-select > div {
  width: calc(100% + 42px);
}
.actions-modal .consent-modal-form > div .form-control {
  letter-spacing: 0px;
  font-size: 18px;
  font-family: var(--font-secondary);
}
.actions-modal .consent-modal-form .css-xp4uvy {
  padding-right: 10px;
}
.actions-modal .close-consent {
  position: absolute;
  top: 28px;
  right: 24px;
}
.actions-modal .buttons-container {
  margin-top: 4.5%;
  margin-bottom: 20px;
}
.actions-modal .buttons-container a {
  padding: 7px 14px 10px 15px;
}
.actions-modal .buttons-container button {
  padding: 8px 14px 10px 15px;
}
.actions-modal .buttons-container a,
.actions-modal .buttons-container button {
  letter-spacing: 0;
  color: black;
  text-decoration: none;
  cursor: pointer;
  background: white;
  display: block;
  -webkit-appearance: none;
  border: 1px solid #ccc;
  width: 100%;
  text-align: left;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 18px;
}
.actions-modal .buttons-container a.disabled,
.actions-modal .buttons-container button.disabled {
  color: #ccc !important;
}
@media all and (max-width: 380px) {
  .actions-modal .buttons-container a,
  .actions-modal .buttons-container button {
    min-height: 71px !important;
  }
}
.actions-modal .buttons-container svg {
  margin-top: 2px;
  float: right;
}

.user-modal .modal-content {
  position: relative;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  max-width: 590px !important;
  margin: 3px auto;
  padding: 28px 24px 24px 24px;
  letter-spacing: 1px;
}
.user-modal .modal-content h2 {
  font-size: 19px;
  margin-bottom: 30px;
  margin-top: 0px;
  text-shadow: 0 0 black;
}
.user-modal .modal-content .buttons-container {
  font-size: 14px;
  margin-bottom: 25px;
}
.user-modal .modal-content .buttons-container input {
  display: block;
  padding: 8px 15px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.user-modal .modal-content .select-container {
  position: relative;
  margin-bottom: 20px;
}
.user-modal .modal-content .select-container > label {
  display: block;
}
.user-modal .modal-content .select-container svg {
  position: absolute;
  left: 100px;
  top: 38px;
}
.user-modal .modal-content select {
  display: inline-block;
  -webkit-appearance: none;
  border: 1px solid #ccc;
  background: white;
  padding: 5px 65px 5px 15px;
}
.user-modal .modal-content .phi-container {
  display: inline-block;
  font-size: 12px;
  margin-left: 26px;
}
.user-modal .modal-content .phi-container input.checkbox {
  display: none;
}
.user-modal .modal-content .phi-container label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  line-height: 20px;
  margin: 5px;
}
.user-modal .modal-content .phi-container label:before {
  line-height: 20px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #666666;
}
.user-modal .modal-content .phi-container input[type=checkbox]:checked + label:before {
  content: "✓";
  color: #666666;
  text-align: center;
  line-height: 16px;
}
.user-modal .modal-content .phi-container label:hover {
  cursor: pointer;
}
.user-modal .modal-content .phi-container label {
  margin-left: 5px;
}
.user-modal .create-or-cancel .btn-primary {
  font-size: 14px;
}
.user-modal .create-or-cancel .btn-default {
  border: 1px solid #ccc;
  margin-left: 10px;
}

.tooltip,
.tooltip-down-arrow {
  z-index: 100 !important;
}

.tooltip-down-arrow {
  visibility: hidden;
}

.tooltip span,
.tooltip-down-arrow span {
  position: absolute;
  display: inline-block;
  border-radius: 5px;
  visibility: visible;
  -webkit-box-shadow: #ccc 1px 5px 10px;
  box-shadow: #ccc 1px 5px 10px;
  z-index: -1;
  border: 1px solid #ccc;
  padding: 15px;
  right: 0px;
  bottom: 50px;
}

.tooltip-down-arrow span {
  -webkit-box-shadow: #ccc 2px -1px 20px 0px !important;
  box-shadow: #ccc 2px -1px 20px 0px !important;
}

.tooltip:after,
.tooltip-down-arrow:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  bottom: 42px;
  right: 15px;
  z-index: 2;
}

.tooltip .tooltiptext,
.tooltip-down-arrow .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-weight: normal !important;
}

.show-tooltip {
  position: relative;
}

.p-2.doctor.show-tooltip {
  height: 36px;
}

span.show-tooltip span.font-weight-normal {
  font-weight: normal;
}

.disabled:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.disabled:hover .tooltip {
  opacity: 1;
  background: transparent;
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  visibility: visible;
}

@media all and (max-width: 516px) {
  .filter {
    text-align: left !important;
    width: 250px;
  }
}
@media all and (max-width: 1266px) {
  .user-index .toggle-info {
    margin-left: 0 !important;
    margin-top: 10px;
  }
}
.user-index .toggle-info {
  margin-left: 15px;
  -webkit-appearance: none;
  border-radius: 5px;
  font-size: 12px;
  color: #918f90;
  width: 25px;
  height: 25px;
  outline: none;
  cursor: pointer;
  background: white;
  position: relative;
  border: 1px solid #ccc;
  bottom: 5px;
  padding: 0;
}
.user-index .toggle-info i {
  width: 11.5px;
  margin: 0 auto;
  float: none;
  display: block;
  position: relative;
  top: 2.5px;
}

.user-index .table-responsive {
  overflow: hidden;
}

.change-password-container {
  width: 100%;
}

.resources-header {
  font-size: 34px;
  font-weight: 300 !important;
  line-height: 40px;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 3px 3px 0 0;
}
.resources-header .record-title {
  min-width: fit-content;
}
.resources-header .resource-filters {
  margin-right: 30px;
  width: fit-content;
}
.resources-header .resource-filters .dropdown-item {
  max-width: 100%;
}
.resources-header .resource-filters .filter-category {
  color: black;
  display: inline-block;
  width: 118px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.resources-header span {
  color: white;
}

.resources.featured .media-body {
  padding-top: 20px;
}
.resources .media-body {
  padding-left: 15px;
}
.resources .media-img {
  width: 180px;
}
.resources .media-img img {
  display: block;
  margin: 0 auto;
  max-width: 180px;
  padding: 10px;
  width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.resources h3 {
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}
.resources h4 {
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
  padding-top: 8.8px;
}
.resources span:first-of-type {
  width: fit-content;
  height: 100%;
  color: #f2f4f7;
  font-family: Roboto;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  background-color: #1b3763;
  border-radius: 15px;
  padding: 3px 10px;
  text-align: center;
  margin-right: 12.5px;
  display: inline-block;
}
.resources span {
  height: 20px;
  width: 170px;
  color: #577399;
  font-family: Roboto;
  font-size: 13px;
  line-height: 20px;
}

.resources.featured .media-img {
  height: 219px;
  width: 333px;
}

.carousel-item a {
  padding-bottom: 40px;
}

.carousel-indicators {
  justify-content: flex-start;
}
.carousel-indicators li {
  left: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-secondary-light);
}
.carousel-indicators .active {
  background-color: var(--color-secondary);
}

@media (max-width: 767px) {
  .resources .media-img {
    width: 100px;
    height: 100px;
    line-height: 97px;
  }
}

